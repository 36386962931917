export default {
  titSection: 'NHNの長年のゲーム開発ノウハウを集約！',
  descSection:
    'NHN GamePlatformは、ゲーム開発に必要な様々な機能を提供します。日本や韓国で有名なゲームにも導入され、トラフィックの多い大規模なゲームでも安定的に稼働しています。',
  customers: [
    { title: 'Rise of Stars', text: 'LightCON' },
    { title: 'Lord of Heroes', text: 'Clover Games' },
    { title: '#Me', text: 'Clover Games' },
    { title: 'GUNS UP! Mobile', text: 'NHN BigFoot' },
    { title: 'Every Farm', text: 'Wemade Connect' },
    { title: 'Counterside', text: 'Studiobside' },
    { title: 'ねこ島物語', text: 'LikeItGames' },
    { title: '太古M', text: 'CITYLABS' },
    { title: 'DRAGON RAJA ORIGIN', text: 'Aprogen Games' },
    { title: 'EOS RED', text: 'BluePotion Games' },
    { title: 'Yo-kai WatchN', text: 'NHN PlayArt' },
    { title: '#Compass', text: 'NHN PlayArt' },
    { title: 'Vestria Story', text: 'PANGSKY' },
    { title: 'LUTIE SAGA', text: 'NSIX' },
    { title: 'LYN: The Lightbringer', text: 'Pulsar Creative' },
    { title: 'Backalley Stage', text: 'NZiN' },
    { title: 'Bald Story', text: 'Teammium' },
    { title: 'Power Rangers: All Stars', text: 'MOVE GAMES' },
    { title: 'シンビアパート:ソウルファイターズ', text: 'MOVE GAMES' },
    { title: 'REDDOT: フロントライン', text: 'ONFACE Games' },
    { title: 'ブラッドカオス:覚醒', text: 'Day1 Entertainment' },
    { title: '蒼天三国', text: 'Day1 Entertainment' },
    { title: '新西遊記', text: 'Day1 Entertainment' },
    { title: '百鬼夜行', text: 'DoubleGames' },
    { title: '三国志 ブラックラベル', text: 'YJM Games' },
    { title: 'スター・ウォーズ: スターファイター・ミッション', text: 'Joymax' },
    { title: 'サードエイジ', text: 'IMI' },
    { title: '竜飛不敗M', text: 'VALOFE' },
    { title: 'Critical Ops: Reloaded', text: 'NHN' },
    { title: 'フィッシュアイランド: 精霊の航路', text: 'NHN' },
    { title: 'クルセーダークエスト', text: 'NHN' },
    { title: 'ミイラの飼い方', text: 'NHN' },
    { title: 'RWBY: Amity Arena', text: 'NHN' },
    { title: 'ごっつ三国 関西戦記', text: 'NHN' },
    { title: 'AFTER LIFE', text: 'NHN' },
    { title: 'the sword of the east', text: 'WithHug' },
    { title: 'Weapon Heroes', text: 'Gamepub' },
    { title: 'Warrior who level up hard', text: 'SuperPlanet' },
    { title: 'Merge Tactics: Kingdom Defense', text: 'LoadComplete' },
    { title: 'Five Stars', text: 'SkyPeople' },
    { title: 'Princess Tale', text: 'Gamepub' },
    { title: 'Final FateTD', text: 'Gamepub' },
    { title: 'Island M', text: 'Gamepub' },
    { title: 'Twin Three Kingdoms', text: 'Gamepub' },
    { title: 'Three Kingdoms - Kingdom Defense', text: 'Gamepub' },
    { title: '熱血江湖M', text: 'ACTFIVE' },
    { title: 'Dungeon & Alchemist', text: 'Act Seven Entertainment' },
    { title: 'Dungeon & Hunter ', text: 'Act Seven Entertainment' },
    { title: 'Sky Warrior', text: 'WithHug' },
    { title: '最強協客', text: 'WithHug' },
    { title: '絶対武林', text: 'WithHug' },
    { title: 'Ilgeomhwaseon', text: 'WithHug' },
    { title: '王三國志', text: 'WithHug' },
    { title: 'Hot blooded battle', text: 'WithHug' },
    { title: 'Sunblade', text: 'WithHug' },
    { title: "God's Chronicles", text: 'WithHug' },
    { title: '三國志血戰', text: 'WithHug' },
    { title: 'Scarlet Borderland', text: 'WithHug' },
    { title: 'Videogame Guardians', text: 'SuperPlanet' },
    { title: 'Evil Hunter Tycoon', text: 'SuperPlanet' },
    { title: 'Sword Master Story', text: 'SuperPlanet' },
    { title: 'YaShin', text: 'Sambon Electronics' },
    { title: 'The warrior became a box', text: 'SuperPlanet' },
    { title: 'Sheep Tycoon', text: 'NO ANSWER studio' },
    { title: 'Come to the convenience store', text: 'NEW.F.O' },
    { title: 'Three Kingdoms Defense', text: 'MOHA Games' },
    { title: 'Princess Maker', text: 'Mgame' },
    { title: 'Idle Zombie', text: 'IOI Games' },
    { title: 'Hyeobmu', text: 'Gosu Gamese' },
    { title: 'All In Three Kingdoms', text: 'CHAPLIN GAME' },
    { title: '鐵血三國', text: 'CHAPLIN GAME' },
    { title: ' Three Kingdoms K', text: 'CHAPLIN GAME' },
    { title: 'Cheer Up, Master.', text: 'mokacorp' },
    { title: 'Legendary voyage', text: 'Gosu Gamese' },
    { title: '劍神', text: 'WithHug' },
    { title: 'Sniper Girls', text: 'Thunder Games' },
    { title: 'Hero Fighters', text: 'R2ON' },
    { title: 'War of kings', text: 'monawa' }
  ],
  icon: [{ name: 'ホームページを見る' }, { name: 'App Store' }, { name: 'Google Play' }, { name: 'ONE store' }]
};
