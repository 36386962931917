export default {
  titLicense: 'END USER LICENSE AGREEMENT', 
  dscLicense: 'PLEASE READ THIS END USER LICENSE AGREEMENT CAREFULLY. <br/><br/><br/><br/>This End User License Agreement (the “EULA”) governs your use of GameAnvil software, associated upgrades, patches, and updates (collectively “Software”) currently provided or which will be provided by NHN Corporation (“NHN”) to you (“User” or “You”). By Using Software, You agree to accept and to be bound by the terms of this EULA at all time. If You do not agree with the terms of this EULA, please discontinue Your Use of Software and uninstall and delete the Software.', 
  lstLicense: [
    { subtit : 'Definition.', dsc : '“Software”, in addition to the definition provided above, shall be construed to include any manuals, instructions, or guidelines provided by NHN with respect to Software, via online, in electronic document format or through any other relevant medium.<br/>“Use of Software” or “Using Software” shall mean the act of storing Software, in whole or in part, in the main memory of a computer or in any other storage device.'}, 
    { subtit : 'Grant of License.', dsc : 'When Using Software, User shall fully comply with the following terms and conditions:', 
      lstsubLicense: [
        { subLicense: '2.1	Copyright Notice. ', dscCircleLicense: 'User shall neither remove nor modify the copyright notice placed on Software.'}, 
        { subLicense: '2.2	No Distribution. ', dscCircleLicense: 'User may not distribute Software, in whole or in part, to any third party.'}, 
        { subLicense: '2.3	No Modification.', dscCircleLicense: 'User may neither edit, delete, update, modify, add, nor otherwise change Software, in whole or in part.'}, 
        { subLicense: '2.4	No Reverse-Engineering, No Decompilation, No Disassembly. User ', dscCircleLicense: 'User may neither reverse-engineer, decompile, disassemble, nor engage in any similar act with respect to Software.'}, 
      ]
    }, 
    { subtit : 'Copyright.', dsc : 'NHN holds full ownership of, and reserves any and all intellectual property rights (including, without limitation, copyright and right to prepare derivative works) in and to, Software and any accessories thereof.'}, 
    { subtit : 'Limitation of Liability.', dsc : 'User shall be responsible and fully liable for any and all damages resulting from or in connection with, the selection, introduction, or Use of Software by the User and, at the Users own expense, shall fully indemnify and hold harmless NHN against any damages incurred or reasonably expected to be incurred by NHN therefrom. NHN does not bear any liability whatsoever with respect to any and all damages arising from or in connection with the use or inability to use Software or any relevant documents (including, without limitation, loss of profit, business interruption, loss of business information, or any other type of damages or losses) regardless of whether NHN has been advised of the possibility of such damages.'}, 
    { subtit : 'Acknowledgment.', dsc : 'User hereby agrees and acknowledges that the User has fully read, understood, and agreed to the terms of this EULA, and User further acknowledges that the terms of this EULA takes precedence over any usage of Software.'}, 
    { subtit : 'Compliance.', dsc : 'User must fully comply with the terms of this EULA, as well as any relevant laws and regulations regarding Use of Software. If User breaches or violates the terms of this EULA or any relevant laws and regulations, the User shall bear full civil and criminal liability therefor.'}, 
    { subtit : 'Inquiries.', dsc : 'Should You have any questions or inquiries regarding Use of Software, please do not hesitate to contact NHN.'}, 
  ]
};
