export default {
  titSection: '파트너',
  descSection: '최고의 게임 플랫폼 서비스 제공을 위해 전문 파트너사와 함께합니다.',
  partnerInfo: {
    tit: '넷텐션 - 프라우드넷',
    dsc:
      '넷텐션에서 개발한 ProudNet은 현재까지 191개의 게임 프로젝트에 도입되었습니다. \n또한 ProudNet 라이브 서버가 전세계 13개 국가에서 구동 중이며, \nNHN Gamebase 와 플랫폼 마케팅과 홍보 협력을 함께합니다.'
  },
  groupContact: {
    ico_call: '연락처',
    ico_mail: '이메일',
    ico_home: 'homepage'
  },
  groupLink: {
    link_contact: 'Home',
    white: 'AWS Proudnet'
  }
};
