import { throttle } from '@/helpers/utils';
import store from '@/store/modules';

const viewSizes = [
  {
    min: 0,
    max: 414,
    mode: 'mobile'
  },
  {
    min: 415,
    max: 768,
    mode: 'tablet'
  },
  {
    min: 769,
    mode: 'desktop'
  }
];

function isMatchedItem(min, max, currentWidth) {
  if (max) {
    return min < currentWidth && max > currentWidth;
  }

  return min < currentWidth;
}

function executeMatchItem() {
  const { innerWidth } = window;
  for (let i = 0, endi = viewSizes.length; i < endi; i += 1) {
    const { min, max, mode } = viewSizes[i];
    if (isMatchedItem(min, max, innerWidth)) {
      store.commit('common/setViewMode', mode);
      break;
    }
  }
}

// immediately
executeMatchItem();
// resize event
window.addEventListener('resize', throttle(executeMatchItem, 50));
