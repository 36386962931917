export default {
  coverTitSection: {
    titSection: '게임플랫폼 다운로드',
    descSection:
      '다운로드는 <b><a href="https://docs.toast.com/ko/Download/#game-gamebase" target="_blank">NHN Cloud > 사용자 가이드 > Downloads</a></b> 에서도 동일하게 제공됩니다',
    lstDotList: [
      '• 각 플랫폼에 맞는 다운로드 설정 가이드와 최신 SDK, Sample App 을 제공합니다.',
      '• Unity는 Setting Tool에서 UnitySDK와 NativeSDK를 모두 다운로드하고 설치 할 수 있습니다.',
      '• Android 는 Gradle 설정으로 SDK 를 프로젝트에 추가할 수 있습니다 (최신 버전으로 자동 적용)',
      '• iOS 는 CocoaPods 설정으로 SDK 를 프로젝트에 추가할 수 있습니다 (최신 버전으로 자동 적용)',
      '• 또한 플랫폼 환경에 맞게 수동 다운로드와 Sample App 을 제공하여 보다 쉽게 적용하고 Test 가능합니다.'
    ]
  },
  top: {
    service: [
      {
        title: 'Unity',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unity',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unity Setting Tool',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSettingTool.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.unity.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ]
      },
      {
        title: 'Unreal',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unreal',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unreal SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-Unreal.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      },
      {
        title: 'Android',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_android',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Gradle Setting Guide',
            href: 'https://docs.toast.com/ko/Game/Gamebase/ko/aos-started/#setting',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.android.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      },
      {
        title: 'iOS',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_apple',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'CocoaPods',
            href: 'https://docs.toast.com/ko/Game/Gamebase/ko/ios-started/#cocoapods-settings',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'iOS SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-iOS.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.ios.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      }
      // {
      //   title: 'Old versions of SDK',
      //   titleStrongClass: 'tit_download',
      //   titleSpanClass: '',
      //   ulClass: 'lst_download',
      //   downloadList: [
      //     {
      //       title: 'Old versions of SDK',
      //       href: 'https://docs.toast.com/ko/Download/#game-gamebase',
      //       hrefClass: 'link_download link_launch',
      //       titleSpanClass: 'ico_game ico_launch'
      //     }
      //   ]
      // }
    ]
  },
  bottom: {
    service: [
      {
        title: 'Smart Downloader',
        downloadList: [
          {
            title: 'Unity SDK',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/Smart-downloader-1.6.8.unitypackage'
          },
          {
            title: 'Jenkins Plugin',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/smartdl-uploader.hpi'
          },
          {
            title: 'Smart Downloader Unity Tool(SUT)',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/SUT/SmartDownloaderUnityTool.zip'
          }
        ]
      },
      {
        title: 'NHN Cloud',
        downloadList: [
          { title: 'PUSH', href: 'https://docs.toast.com/ko/Notification/Push/ko/Overview/' },
          {
            title: 'Log & Crash Search',
            href: 'https://docs.toast.com/ko/Analytics/Log%20&%20Crash%20Search/ko/Overview/'
          },
          { title: 'more NHN Cloud Services', href: 'https://docs.toast.com/ko/Download/#toast-sdk' }
        ]
      }
    ]
  }
};
