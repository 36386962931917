export default {
  titTop: 'Gamebase',
  emphTop: '統合SDKで簡単かつスピーディに適用！',
  descTop: 'ゲームに必要な共通機能を統合SDKの形で提供し、手軽かつ効率的に開発運営できるようサポートするサービスです。',
  sectionService: [
    {
      titSection: '主要機能',
      descSection:
        'GamebaseはGameのローンチと運営までをサポートするモバイルバックエンドサービスです。<br>Gamebase SDKを利用すれば、ログイン/決済/アイテム/KPIなど、ゲーム開発のための共通機能の開発を簡略化することができます。'
    },
    {
      titSection: '強力な分析指標の提供',
      descSection:
        'KPI分析のために有償の分析プラットフォームを使用していませんか？<br /> Gamebaseなら、リアルタイムDAU、PU、ARPUなどの基本指標から、LTV、Retention、連続ログイン率、レベル別指標などの重要な分析指標を提供しています。'
    },
    {
      titSection: 'ビジネス/開発の強み',
      descSection: ''
    },
    {
      titSection: 'グローバル進出に最適化されたゲームソリューション',
      descSection:
        'Gamebaseは、ゲームのグローバルオープンを標準サポートしており、グローバル環境でのゲーム運営のための機能を提供します。',
      desc_map:
        'ゲーム利用者に表示されるPushメッセージなどはすべて多言語対応となります。<br />国別のフィルタリング機能を提供します。<br />運営者は現地標準時間帯(Local timezone)を選択し、簡単に時刻を入力することができます。'
    },
    {
      titSection: '開発期間の短縮およびリソース削減',
      descSection:
        '外部連携させるための数多くのSDK分析や開発はもう不要です！ <br />Gamebase SDKを適用するだけで、手軽に多くの機能を利用することができます。<br />共通機能はGamebaseに任せて、ゲームのクオリティを向上させるための開発に集中してください！',
      desc_map:
        'ゲーム利用者に表示されるPushメッセージなどはすべて多言語対応となります。<br />国別のフィルタリング機能を提供します。<br />運営者は現地標準時間帯(Local timezone)を選択し、簡単に時刻を入力することができます。'
    },
    {
      titSection: '強力なサービスの安定性とセキュリティ',
      descSection:
        '2016年にGamebaseを正式オープンして以来、トラブルなく安定したサービス運営を続けています。 <br /> またNHN全体で最高レベルのSystemセキュリティ認証、および個人情報管理体制で管理されるため、セキュリティイシューおよびハッキングの危険を最小限に抑えて安全なゲーム運営が可能となります。'
    },
    {
      titSection: 'ゲーム技術PMの専門的なコンサルティングおよびお問い合わせ対応',
      descSection:
        'NHNはGamebaseの開発・提供のみではなく、実際に自社のゲームでも利用しています。 <br />お客様はもちろん、自社内を含め様々なフィードバックを反映した品質には自信があります。 <br />豊富な現場経験を基に、お客様からのお問い合わせやリクエストに迅速に対応します。'
    },
    {
      titSection: 'Gamebase Setting Tool',
      descSection:
        'Gamebaseを選ぶ重要な理由のひとつは開発時間の短縮ですよね？ SDKをダウンロードしてコピーして、セットして…<br />Gamebaseは、このような時間を節約するためにUnity MenuにIntegratedされたセッティングツールを提供し、数回クリックでゲーム情報を設定できるようサポートしています。'
    }
  ],
  // service1
  lstItem: [
    {
      emText: 'ログイン/ 認証',
      pText:
        'Google、 Apple ID、 Apple Game Center、 Facebook、 Twitter、 kakao、 NAVER、 LINE、 PAYCO、 Hangame、 Guest、カスタムログインなど'
    },
    {
      emText: '決済/ アイテム',
      pText:
        '様々なストアのアプリ内決済とアイテム管理機能を提供。Google Play、App Store、 ONE Store、Galaxy Store、 Amazon、 HUAWEI、 MyCard、中国ストアなど'
    },
    {
      emText: 'KPI/分析',
      pText:
        'ゲーム事業運営の意思決定に必要な様々な指標を提供し、利用者、売上、ゲームバランスの多角的な分析が可能です。'
    },
    {
      emText: 'メンテナンス/PUSH',
      pText:
        'クライアントのバージョン別、国別チェック機能を提供し、利用者向けのPushサービス(繰り返し送信、予約送信、ターゲティング送信)も簡単に送信できます。'
    },
    {
      emText: 'ゲーム運営ツール',
      pText:
        '10年のノウハウが込められた多様なゲーム運営ツールをご提供します。 ユーザー管理、アプリバージョン管理、不正ユーザー制裁など、ゲーム運営に必要なコア機能を体験してみてください。'
    },
    {
      emText: 'ゲームセキュリティ',
      pText:
        '様々なアビューズから安全にアプリを保護できるモバイルアプリセキュリティソリューションです。ハッキングツールの検知、コード改ざん防止など様々な不正行為からお客様のアプリを安全に保護します。'
    },
    {
      emText: 'ランキング',
      pText:
        '安定的で超高速なランキングサービスです。日/週/月間など、様々なランキングを通じてプレイヤーの競争意欲を促します。'
    },
    {
      emText: 'ダウンロード高速化',
      pText:
        'ゲームを開始するのに必要なリソースを、より速くダウンロードすることができます。 スピーディなダウンロードでユーザー満足度を向上しましょう！'
    },
    {
      emText: 'クーポンサービス',
      pText:
        '利用者に支給するクーポンを生成·管理することができます。 シリアルクーポン、キーワードクーポンなど、様々なマーケティング活動が可能です。'
    },
    {
      emText: 'カスタマーセンター',
      pText: '問い合わせ処理はもちろん、その他カスタマーセンターページからお知らせ、FAQなどの設定を管理できます。'
    }
  ],
  // service2
  lstBigItem: [
    {
      emText: 'リアルタイム指標',
      imgSrc: '/resources/images/pc/img_jpn_indicator1.png',
      pText: '売上、PU、ARPU、ARPPUなどの主要指標に対するリアルタイムダッシュボードを提供'
    },
    {
      emText: '売上指標',
      imgSrc: '/resources/images/pc/img_jpn_indicator2.png',
      pText:
        '決済金額、有料利用者に対する多様な分析機能を提供、アイテム別販売指標、初購入アイテム指標など売上分析指標を提供'
    },
    {
      emText: '利用者指標',
      imgSrc: '/resources/images/pc/img_jpn_indicator3.png',
      pText:
        '主なKPI指標を国別、OS別、ストア別などに正確かつ専門的に分析、利用者の流入、離脱、リテンション、LTVなどの利用者分析のための指標を拡大提供'
    },
    {
      emText: 'バランシング指標',
      imgSrc: '/resources/images/pc/img_jpn_indicator4.png',
      pText:
        'レベル別ユーザーの売上分析指標、レベル別達成時間、初購入アイテムなどゲームバランシングに必要な活用指標を提供'
    }
  ],
  // service3
  groupAdvantage: [
    {
      titAdvantageMirror: 'BUSINESS <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-03.svg',
      lstAdvantage: [
        { spanText: '業界最低料金制' },
        { spanText: 'ゲームに特化した<br />分析指標を無料で提供！' },
        { spanText: 'NHNで利用している<br />ゲームプラットフォーム' },
        { spanText: 'モバイルからPCまで！<br>ハイブリッドプラットフォームを提供！' }
      ]
    },
    {
      titAdvantageMirror: 'DEVELOPMENT <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-04.svg',
      lstAdvantage: [
        { spanText: '開発期間の短縮(共通機能開発人員と工数の最小化) ' },
        { spanText: 'ゲーム技術PMの技術支援' },
        { spanText: '365日安定的な<br />無停止サービス' },
        { spanText: 'サーバ拡張の容易性、<br />迅速性' }
      ]
    }
  ],
  // service4
  map: {
    imgAlt: '世界地図',
    lstGlobal: [
      { spanText: ['USA', 'Hello!', 'UTC-06:00'] },
      { spanText: ['Korea', '안녕!', 'UTC-09:00'] },
      { spanText: ['Japan', 'こんにちは！', 'UTC-09:00'] },
      { spanText: ['China', '你好!', 'UTC-08:00'] }
    ]
  },
  // service5
  lstSpItem: [
    {
      emText: '開発人員',
      spanText: '熟練度によって5MM~10MMの開発人員が必要',
      pText:
        '(内部リサーチ基準) <br >認証開発 / アプリ内決済開発 / ランキング開発 / 通知 / メンテナンス処理 / BAN処理 / 運営ツール開発 / 指標開発 / モニタリング'
    },
    {
      emText: '開発期間',
      spanText: '開発期間の増加要因',
      pText:
        'ログイン認証連携対象の追加 / Android OS、iOSなどOSのアップデート時 / 運営ツールの高度化が必要になるフェーズ / 指標の充実化が必要なフェーズ'
    },
    {
      emText: '費用増',
      spanText: '開発人員投入=費用<br />開発期間管理イシュー=費用<br />サーバーストレージ=費用',
      pText: ''
    }
  ],
  boxSp: 'Gamebase SDKを導入すれば、開発費用を大幅に削減できます！',
  // service6
  lstLicence: [
    { spanText: '情報セキュリティマネジメントシステムISMS認証' },
    { spanText: 'クラウドコンピューティングサービスのセキュリティ認証CSAP認証' },
    { spanText: '情報セキュリティマネジメントシステムISMS認証' },
    { spanText: 'クラウドサービス情報保護マネジメントシステムISO/IEC27017認証' },
    { spanText: 'クラウドサービス個人情報管理体系ISO/IEC27018認証' },
    { spanText: '国際情報セキュリティマネジメントシステムISO/IEC27001認証' },
    { spanText: '情報セキュリティマネジメントシステムISMS認証' }
  ],
  // service7
  tech: {
    titSubSection: '強力な技術支援',
    descSection: '10年以上のゲーム運営経験を基に、安定したサービスをご提供します。',
    lstTech: [
      {
        emText: 'コンサルティング·サービス',
        spanText: 'クラウド導入戦略の策定  マイグレーションのサポート'
      },
      {
        emText: 'プロエンジニア',
        spanText: '顧客企業の専担スタッフ割り当て / 24時間、365日サポートサービス'
      },
      {
        emText: 'セキュリティサービス',
        spanText: 'ウェブセキュリティ検収セキュリティ管制サービスCERTサービス'
      },
      {
        emText: 'モニタリングサービス',
        spanText: 'モニタリングソリューションの提供臨界値設定およびアラーム設定'
      },
      {
        emText: 'DDoS対応サービス',
        spanText: 'リアルタイム監視およびDDoS装置サポート'
      },
      {
        emText: 'IT管理サービス',
        spanText: 'プロエンジニアのIT管理サービス'
      }
    ]
  },
  // service8
  itemConsole: {
    strongText: 'Gamebase Setting Toolを利用して、スピーディーかつ簡単にSDKをインストール可能！',
    imgAlt: 'Gamebase Setting Tool画面',
    aText: 'Gamebase製品相談',
    spanText: 'リンク',
    linkEnd: 'Gamebase製品相談'
  }
};
