import Vue from 'vue';
import VModal from 'vue-js-modal';

Vue.use(VModal, {
  dialog: true,
  dynamicDefaults: {
    draggable: true,
    resizable: true,
    adaptive: true,
    height: 'auto',
    // maxWidth: '504'
  }
});
