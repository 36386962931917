export default {
  titSection: 'パートナー',
  descSection: '最高のゲームプラットフォームサービスを提供するため、専門パートナー企業と協力しております。',
  partnerInfo: {
    tit: 'Nettention - ProudNet',
    dsc:
      'Nettentionが開発したProudNetは、今まで191のゲームプロジェクトに導入されました。 \nまた、ProudNetライブサーバーが、世界13カ国で駆動しており、\nNHN Gamebaseとプラットフォームマーケティングと広報協力を共に行っております。'
  },
  groupContact: {
    ico_call: '連絡先',
    ico_mail: 'eメール ',
    ico_home: 'Webサイト'
  },
  groupLink: {
    link_contact: 'Home',
    white: 'AWS Proudnet'
  }
};
