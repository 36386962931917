export default {
  titSection: '产品咨询',
  descSection:
    '如有游戏平台整体使用咨询、产品委托咨询、各种合作咨询，请留下联系方式。 负责人确认后联系您。',
  contactName: '姓名',
  contactPhone: '联系方式',
  nameTxtAttention: '名字是必填项。',
  nameRegexError: '名字只能用韩文或英文。',
  phoneTxtAttention: '电话号码是必填项。',
  phoneRegexError: '电话号码只能包含 11 位数字，不能包含特殊字符。',
  mailTxtAttention: '电子邮件是必填项。',
  contactMail: '电子邮件',
  contactMailDomainSelf: '直接输入',
  contactCompany: '公司名称',
  contactLike: '咨询内容',
  // descContact:
  //   '收集的个人信息【（必填）姓名和联系方式用于客户咨询，按照相关法律法规存储后删除。 如果您不同意，咨询的使用可能会受到限制。',
  tfCheckbox:
    '<span class="ico_game ico_check"></span>为客户咨询收集 (必填) 姓名, 手机号码, 邮箱地址(可选) 公司名, 咨询内容, <em class="emph_g">根据相关发令保存3年。</em><br class="view_pc"> 您可拒绝，但为了使用您所选的服务，需要同意。',
  txtAttention: '请同意个人信息的收集和使用指南。',
  titContactDone: '产品咨询<br />已正常受理。',
  descContactDone: '我们将尽最大努力尽快回复您的咨询。<br />谢谢。',
  mailSandApiError: '邮件发送失败。'
};






