export default {
  titTop: 'Log & Crash Search',
  emphTop: 'ゲームログとクラッシュを管理·分析する!',
  descTop: 'Log & Crash Searchは、クライアントとサーバーのログを収集し、目的のログを検索、照会するシステムです。',
  titSection: '特徴',
  descSection:
    'モバイルアプリで発生するクラッシュの分析·統計作業を行い、クラッシュ発生原因に関する様々な情報を提供しています。',
  skill: [
    {
      titSubSection: '主要機能',
      lstSkill: [
        {
          pText: 'ゲームサーバーから発生する大量のログによるサービスへの影響を低減することができます。'
        },
        {
          pText: '配布されたすべてのクライアントで発生するエラーを1ヶ所に集めて照会し、分析することができます。'
        },
        {
          pText: '大量のログから必要なログを素早く検索でき、パターン別にログを照会することができます。'
        },
        {
          pText: 'ログとクラッシュを5分単位でモニタリングすることができます。'
        },
        {
          pText: 'サービス別削除、様々なログ タイプ別に照会/分析機能を提供します。'
        },
        {
          pText:
            'エラー、クラッシュダンプ、ウェブ アプリケーションログ、ユーザー定義メッセージ形式などをサポートします。'
        },
        {
          pText:
            'プロジェクト、ホスト、ログのタイプ、ログのレベル別にログ数を見ることができ、ログ検索ページでは時間帯別ログ推移がチャートで確認できます。'
        },
        {
          pText: 'ログ本文でクエリーに一致する単語がハイライトされるので、簡単に確認することができます。'
        }
      ]
    },
    {
      titSubSection: 'サービス対象',
      lstSkill: [
        {
          spanClass: 'brand_target_log',
          pText: 'Apache、Tomcatなどのウェブログが蓄積しすぎている場合'
        },
        {
          spanClass: 'brand_no_server',
          pText: 'サーバーログをサーバーに接続せずに見たい場合'
        },
        {
          spanClass: 'brand_target_collect',
          pText: 'ユーザーに配布したアプリから発生したログを一箇所に集約したい場合'
        },
        {
          spanClass: 'brand_error_log',
          pText: 'サービスで発生した不具合のログの推移を確認したい場合'
        }
      ]
    }
  ],
  aTag: [
    { href: 'https://www.toast.com/jp/service/security/nhn-appguard', aText: 'AppGuardサービス リンク' },
    { href: 'https://www.toast.com/jp/service/analytics/log-crash-search', aText: 'Log & Crash Searchサービス リンク' },
    { href: 'https://www.toast.com/jp/service/compute/instance', aText: 'Instanceサービス リンク' }
  ]
};
