export default {
  titTop: 'Leaderboard',
  emphTop: '게임 연동만으로 간단히 구현!',
  descTop:
    'Leaderboard는 NHN 에서만 제공하는 게임 랭킹 전용 서비스로, 별도의 SDK 연동 없이 콘솔 설정과 게임 연동만으로 간단히 랭킹 서비스를 구현할 수 있습니다.',
  txtService1: '랭킹(점수)등록',
  txtService2: '랭킹 조회',
  txtInfoRealtime: '실시간/대용량 랭킹을 빠르고 안정적으로 지원합니다.',
  txtInfoRankingSort: '다양한 기준의 랭킹 생성이 가능합니다.',
  txtInfoSortManage: '콘솔을 통해 랭킹 주기/랭킹 업데이트 기준/랭킹 정렬 기준을 편리하게 관리 할 수 있습니다.',
  txtInfoTemplate: '템플릿을 통해 손쉽게 랭킹을 생성할 수 있습니다.',
  linkEnd: 'Leaderboard 제품 상담',
  icoArrowForwardW: '바로가기',
  sectionService1: {
    imgAlt: 'Leaderboard Ranking Server'
  }
};
