export default {
  titSection: 'Product Consultation',
  descSection:
    'For general inquiries about our game platform, consultations on product introduction, or partnership inquiries, please leave your contact information. We will get back to you shortly.',
  contactName: 'Name',
  contactPhone: 'Contact',
  nameTxtAttention: 'Name required.',
  nameRegexError: 'Only Korean or English alphabet allowed for Name.',
  phoneTxtAttention: 'Phone number required.',
  phoneRegexError: 'Phone numbers can only be in 11 digits without special characters.',
  mailTxtAttention: 'Email required.',
  contactMail: 'Email',
  contactMailDomainSelf: 'Type in text',
  contactCompany: 'Company',
  contactLike: 'Inquiries',
  descContact:
    'Personal information collected (Name and contact information necessary) is used for customer consultation and will be stored and deleted as demanded by law. <br/> Without agreement, consultation can be restricted.',
  tfCheckbox:
    '<span class="ico_game ico_check"></span>NHN collects required information such as your name, mobile phone number, and email address as well as optional information such as your company name and Inquiries for the purpose of customer support. <em class="emph_g">The information NHN collects is retained for three years in accordance with the relevant laws.</em><br class="view_pc"> You may decline the collection of your information, but doing so will prevent you from using our services.',
  txtAttention: 'Please agree to personal information collection and usage terms and conditions.',
  titContactDone: 'Product consultation inquiry<br />submitted successfully.',
  descContactDone: 'We will do our best to answer your inquiry swiftly and accurately.<br />Thank you.',
  mailSandApiError: 'Mail delivery failed.'
};
