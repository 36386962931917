import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { getCookieCountryCode } from '@/helpers/i18n';
import { FALLBACK_COUNTRY_CODE } from '@/constants';

import en from '@/i18n/resource/en';
import kr from '@/i18n/resource/ko';
import jp from '@/i18n/resource/jp';
import ch from '@/i18n/resource/ch';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: getCookieCountryCode(),
  fallbackLocale: FALLBACK_COUNTRY_CODE,
  messages: {
    en,
    kr, 
    jp, 
    ch
  }
});

export default i18n;
