<script>
export default {
  methods: {
    mixinGenerateCountryLink(path) {
      const countryCode = this.$store.getters['common/countryCode'];

      return `/${countryCode}/${path}`;
    },
    mixinGenerateCountryGuideLink(path) {
      let countryCode = this.$store.getters['common/countryCode'].toLowerCase();
      if (countryCode.indexOf('kr') >= 0) {
        countryCode = 'ko';
      } else if (countryCode.indexOf('jp') >= 0) {
        countryCode = 'ja';
      } else if(countryCode.indexOf('ch') >= 0) {
        countryCode = 'zh'
      } else {
        countryCode = 'en'
      }

      return `https://docs.toast.com/${countryCode}/${path}/${countryCode}/Overview`;
    },
    mixinGenerateCountryGuideLink_lowerCase(path) {
      let countryCode = this.$store.getters['common/countryCode'].toLowerCase();
      if (countryCode.indexOf('kr') >= 0) {
        countryCode = 'ko';
      } else if (countryCode.indexOf('jp') >= 0) {
        countryCode = 'ja';
      } else if(countryCode.indexOf('ch') >= 0) {
        countryCode = 'zh'
      } else {
        countryCode = 'en'
      }

      return `https://docs.toast.com/${countryCode}/${path}/${countryCode}/overview`;
    },
    mixinCountryTitle() {
      var langMap = {
        kr: 'KOR',
        jp: 'JPN',
        en: 'ENG',
      };

      return langMap[this.$store.getters['common/countryCode']] || langMap.kr;
    }
  }
};
</script>
