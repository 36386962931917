export default {
  requiredNamePhone: '姓名和联系方式，电子邮箱是必填项。',
  requiredAgree: '只有同意收集及利用个人信息，才可以进行咨询输入。 请同意后输入。',
  contactTitle: '变更咨询类型',
  contactContent: '确定要维持已完成的咨询内容吗？',
  cancle: '确定要取消咨询/咨询受理吗？',
  regexError: '必填项信息输入有误。请重新确认。',
  demoRequiredInput: '姓名和ID，电子邮件和公司名称是必填项目。',
  demoRequiredAgree:
    '您必须同意收集和使用个人信息才能申请演示项目帐户。 请同意后输入。',
  demoCancleTitle: '取消演示项目帐户申请',
  demoCancleContent: '确定要取消您的演示项目帐户申请吗？'
};
