export default {
  titTop: 'App Guard',
  emphTop: 'Robust mobile security!',
  descTop: 'AppGuard is a mobile app security solution that can protect your apps from various kinds of abuse. ',
  sectionService: [
    {
      titSection: 'Characteristics',
      descSection:
        'Not just for detecting simple cheat tools but also for other robust features including decompile prevention and integrity verification and simple implementation methods for the users’ convenience.'
    }
  ],
  lstAppguard: [
    {
      emText: 'Convenient web console',
      spanText: 'Log analysis console provided<br>Real-time policy setting enabled'
    },
    {
      emText: 'Robust security',
      spanText: 'Decompile prevention, code encryption<br>File integrity verification'
    },
    {
      emText: 'Simple application',
      spanText:
        'Application possible due to integration into development tools<br>Web console, CLI, Unity3D Editor expansion'
    }
  ],
  appguardGuide: {
    titSubSection: 'Features',
    lstStep: [
      {
        emSpanText: 'Grade 1',
        lstStep2: [
          {
            strongText: 'Hacking Tool Detection',
            lstStep3: ['Cheat and Macro Tool Detection/Blockage']
          }
        ]
      },
      {
        emSpanText: 'Grade 2',
        lstStep2: [
          {
            strongText: 'OS Tampering Prevention',
            lstStep3: ['OS Rooting Environment Detection/Blockage', 'Emulator Environment Detection/Blockage']
          },
          {
            strongText: 'Code Tampering Protection',
            spanText: '(Integrity Check)',
            lstStep3: [
              'Encryption API (Tampering App Detection)',
              'Signature Tampering Detection/Blockage',
              'Manifest Tampering Detection/Blockage',
              'Main Module and Java Code (DEX) Tampering Detection/Blockage'
            ]
          },
          {
            strongText: 'Static Analysis Defense',
            lstStep3: [
              'Java Code (DEX) encryption',
              'game executable file encryption (cocos2d-x, Unity mono, Unreal Engine)'
            ]
          }
        ]
      },
      {
        emSpanText: 'Grade 3',
        lstStep2: [
          {
            strongText: 'Built-in Self-Protection',
            lstStep3: ['Protection of important AppGuard codes (Code obfuscation)']
          },
          {
            strongText: 'Dynamic Analysis Defense',
            lstStep3: [
              'Unity iL2CPP code analysis defense',
              'AppGuard code virtualization',
              '.so dynamic library protection'
            ]
          },
          {
            strongText: 'Behavior-based Macro Detection',
            lstStep3: ['Machine learning-based macro user detection through in-game touch point coordinate analysis']
          }
        ]
      }
    ]
  },
  appguardFlow: {
    titSubSection: 'AppGuard Use Flow',
    txtAppguardFlow1: 'Send Detection Log',
    txtAppguardFlow2: 'Send Detection Log',
    txtAppguardFlow3: 'Search Detection Log',
    descSection:
      "Detection bypass is difficult due to composite action of pattern detection and behavior-based detection using heuristics or cheat tools' behaviors.",
    lstStep: [
      {
        emSpanText: 'Protection',
        lstStep2: [
          {
            strongText: 'Repackaging protection <br class="noneview_m"> Protection against code hacking',
            lstStep3: [
              'Supports decompile prevention',
              'Supports game code encryption (Unity 3D mono, Unity 3D il2cpp, Cocos2d-x) and Java code encryption (DEX)'
            ]
          }
        ]
      },
      {
        emSpanText: 'Detection',
        lstStep2: [
          {
            strongText: 'Patterns',
            lstStep3: ['Detection of cheat tools, macro tools, and speed tool (files, processes, memory patterns)']
          },
          {
            strongText: 'Heuristics',
            lstStep3: ['Detection of emulator, debugging, etc.']
          },
          {
            strongText: 'Behavior-based',
            lstStep3: ['Detection of speed hacks, emulation, debugging, etc.']
          },
          {
            strongText: 'File Integrity',
            lstStep3: [
              'Integrity verification of built-up binary files <br> (detection of tampering for *.dex, *.so, *.dll, and resource files)'
            ]
          }
        ]
      },
      {
        emSpanText: 'Response',
        lstStep2: [
          {
            strongText: 'Restriction',
            lstStep3: [
              'Logs are sent upon abuse detection and restrictions are enforced based on log content and user id',
              'Automatic app blockage upon abuse detection (Client)'
            ]
          }
        ]
      }
    ]
  },
  aTag: [
    { href: 'https://docs.toast.com/en/Security/NHN%20AppGuard/en/Overview/', aText: 'Go to AppGuard Service' },
    {
      href: 'https://docs.toast.com/en/Analytics/Log%20&%20Crash%20Search/en/Overview/',
      aText: 'Go to Log & Crash Search Service'
    },
    { href: 'https://docs.toast.com/en/Compute/Instance/en/overview/', aText: 'Go to Instance Service' }
  ]
};
