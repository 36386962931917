export default {
  titSection: '製品相談',
  descSection:
    'ゲームプラットフォーム全般のご利用に関するお問い合わせ、製品導入のご相談、各種提携に関するお問い合わせがございましたら、連絡先をご記入ください。 担当者が確認後、ご連絡いたします。',
  contactName: '名前',
  contactPhone: '電話番号',
  nameTxtAttention: '名前は必須入力項目です。',
  nameRegexError: '名前にはハングルと英字のみ入力可能です。',
  phoneTxtAttention: '電話番号は必須入力項目です。',
  phoneRegexError: '電話番号は特殊文字なし、11桁までの数字のみ入力可能です。',
  mailTxtAttention: 'メールは必須入力項目です。',
  contactMail: 'Eメール',
  contactMailDomainSelf: '直接入力',
  contactCompany: '社名',
  contactLike: 'お問い合わせ内容',
  descContact:
    '収集する個人情報[(必須)名前と連絡先は顧客相談のために利用され、関連法令に従って保管後、削除されます。 同意しない場合、相談利用の一部または全てが制限される場合があります。',
  tfCheckbox:
    '<span class="ico_game ico_check"></span>お客様相談のために（必須）名前、携帯番号、電子メール(任意)会社名、お問い合わせ内容を収集し、<em class="emph_g">関連法令に基づいて3年間保管されます。</em><br class="view_pc">同意を拒否することもできますが、選択されたサービスの提供を受けるためには同意が必要です。',
  txtAttention: '個人情報の収集および利用に関する案内に同意してください。',
  titContactDone: '製品相談のお問い合わせを<br />受け付けました。',
  descContactDone: 'お問い合わせありがとうございます。後日担当者から回答いたします。',
  mailSandApiError: 'メール送信に失敗しました。'
};
