export default {
  emphTop:
    '专用于游戏开发的 Gamebase 的 <br class="view_pc" />主要功能, 运营和内容分析等 <br class="view_pc" />多种指标敬请体验。',
  linkShortcut: '转到 演示项目 <span class="ico_game ico_shortcut"></span>',
  sectionServicetit: '演示项目账号申请',
  sectionServiceDesc:
    '请留下姓名、邮箱地址、公司名称、ID，在确认之后将提供Gamebase演示版本的项目账户。',
  contactName: '姓名',
  contactMail: '邮箱',
  mailTxtAttention: '我们将向您发送一封更改您密码的电子邮件。',
  contactCompany: '公司名',
  agree1: '（必填）满14周岁以上。',
  agree2:
    '<span class="ico_game ico_check"></span>（必填）Gamebase 演示账户申请收集姓名、邮箱地址、公司名称和 ID，并在收集后保留 30 天。</em><br class="view_pc" /> 您可以拒绝，但如果您不同意,服务使用可能会受到限制。',
  // done
  titDemoDone: 'Gamebase 演示申请<br />已被成功接收。',
  descDemoDone:
    '在输入的邮箱地址 (hong_gildong@nhn.com)<br />' +
    '变更密码后 <em class=""emph_primary"">Console</em> 即可登陆。',
  mailSandApiError: '邮件发送失败',
  alreadyJoinError: '已注册的ID'
};
 