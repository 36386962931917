export default {
  titSection: '只有未体验过的客户，没有只使用一次的客户。',
  descSection:
    "Gamebase通过提供最好的游戏平台，成功推出国内外优秀的游戏，'英雄之王（Clover Games ），林：The Light Bringer（Pulsa Creative），三国志 Black Label（YJM Games ），Friends Pop（Pixel Cube ） 、Pokopang、妖怪手表等高流量的游戏服务正在稳定运营中。",
  customers: [
    { title: 'Rise of Stars', text: 'LightCON' },
    { title: '英雄之王', text: 'Clover Games' },
    { title: '#Me', text: 'Clover Games' },
    { title: 'GUNS UP! Mobile', text: 'NHN BigFoot' },
    { title: 'Every Farm', text: 'Wemade Connect' },
    { title: 'Counterside', text: 'Studiobside' },
    { title: 'The Secret of Cat Island', text: 'LikeItGames' },
    { title: '太古M', text: 'CITYLABS' },
    { title: 'dragon raja origin', text: 'Aprogen Games' },
    { title: 'EOS RED', text: 'BluePotion Games' },
    { title: 'Yo-kai WatchN', text: 'NHN PlayArt' },
    { title: '#Compass', text: 'NHN PlayArt' },
    { title: '贝斯特里亚战记', text: 'Fang Sky' },
    { title: 'LUTIE SAGA', text: 'NSIX' },
    { title: 'LYN : The Lightbringer', text: 'Pulsar Creative' },
    { title: 'Backalley Stage', text: 'NZiN' },
    { title: 'Bald Story', text: 'Teammium' },
    { title: 'Power Rangers: All Stars', text: 'MOVE GAMES' },
    { title: '新碧公寓：魂斗士', text: 'MOVE GAMES' },
    { title: '红点行动', text: 'ONFACE Games' },
    { title: '血之混沌：觉醒', text: 'Haru Entertainment' },
    { title: '苍天三国', text: 'Haru Entertainment' },
    { title: '新西游记', text: 'Haru Entertainment' },
    { title: '百鬼夜行', text: 'DoubleGames' },
    { title: '三国志 Black Label', text: 'YJM Games' },
    { title: '星球大战：星际战机任务', text: 'Joymax' },
    { title: '第三纪元', text: 'IMI' },
    { title: '龙飞不败M', text: 'VALOFE' },
    { title: '关键行动：重装上阵', text: 'NHN' },
    { title: '欢乐钓鱼度假岛：精灵航路', text: 'NHN' },
    { title: '克鲁赛德战记', text: 'NHN' },
    { title: '木乃伊饲养方法', text: 'NHN' },
    { title: 'RWBY: Amity Arena', text: 'NHN' },
    { title: '欢乐魏蜀吴', text: 'NHN' },
    { title: 'AFTER LIFE', text: 'NHN' },
    { title: 'the sword of the east', text: 'WithHug' },
    { title: 'Weapon Heroes', text: 'Gamepub' },
    { title: 'Warrior who level up hard', text: 'SuperPlanet' },
    { title: 'Merge Tactics: Kingdom Defense', text: 'LoadComplete' },
    { title: 'Five Stars', text: 'SkyPeople' },
    { title: 'Princess Tale', text: 'Gamepub' },
    { title: 'Final FateTD', text: 'Gamepub' },
    { title: 'Island M', text: 'Gamepub' },
    { title: 'Twin Three Kingdoms', text: 'Gamepub' },
    { title: 'Three Kingdoms - Kingdom Defense', text: 'Gamepub' },
    { title: '熱血江湖M : 武林爭覇', text: 'ACTFIVE' },
    { title: 'Dungeon & Alchemist', text: 'Act Seven Entertainment' },
    { title: 'Dungeon & Hunter ', text: 'Act Seven Entertainment' },
    { title: '天空戰士', text: 'WithHug' },
    { title: '最強協客', text: 'WithHug' },
    { title: '絶対武林', text: 'WithHug' },
    { title: 'Ilgeomhwaseon', text: 'WithHug' },
    { title: '王三國志', text: 'WithHug' },
    { title: 'Hot blooded battle', text: 'WithHug' },
    { title: 'Sunblade', text: 'WithHug' },
    { title: "God's Chronicles", text: 'WithHug' },
    { title: '三國志血戰', text: 'WithHug' },
    { title: 'Scarlet Borderland', text: 'WithHug' },
    { title: 'Videogame Guardians', text: 'SuperPlanet' },
    { title: 'Evil Hunter Tycoon', text: 'SuperPlanet' },
    { title: 'Sword Master Story', text: 'SuperPlanet' },
    { title: 'YaShin', text: 'Sambon Electronics' },
    { title: 'The warrior became a box', text: 'SuperPlanet' },
    { title: 'Sheep Tycoon', text: 'NO ANSWER studio' },
    { title: 'Come to the convenience store', text: 'NEW.F.O' },
    { title: 'Three Kingdoms Defense', text: 'MOHA Games' },
    { title: 'Princess Maker', text: 'Mgame' },
    { title: 'Idle Zombie', text: 'IOI Games' },
    { title: 'Hyeobmu', text: 'Gosu Gamese' },
    { title: 'All In Three Kingdoms', text: 'CHAPLIN GAME' },
    { title: '鐵血三國', text: 'CHAPLIN GAME' },
    { title: ' Three Kingdoms K', text: 'CHAPLIN GAME' },
    { title: 'Cheer Up, Master.', text: 'mokacorp' },
    { title: 'Legendary voyage', text: 'Gosu Gamese' },
    { title: '劍神', text: 'WithHug' },
    { title: 'Sniper Girls', text: 'Thunder Games' },
    { title: 'Hero Fighters', text: 'R2ON' },
    { title: 'War of kings', text: 'monawa' }
  ],
  icon: [{ name: '查看主页' }, { name: 'AppStore' }, { name: '谷歌商店' }, { name: 'ONE store' }]
};
