import * as CONTACT_REPOSITORIES from '@/store/repositories/contact';

export default {
  // 요청
  // eslint-disable-next-line no-unused-vars
  addContact: async ({ commit }, payload) => {
    const res = await CONTACT_REPOSITORIES.addContact(payload);

    const { header } = res;
    if (header.isSuccessful) {
      return res.result.content;
    }

    return null;
  }
};
