export default {
  titTop: 'Gamebase',
  emphTop: '통합 SDK로 쉽고 빠른 적용!',
  descTop:
    '게임에서 공통으로 필요한 기능들을 통합 SDK로 제공하여<br>손쉽고 효율적으로 개발/운영이 가능하도록 돕는 서비스입니다.',
  sectionService: [
    {
      titSection: '주요기능',
      descSection:
        'Gamebase는 Game 런칭과 운영을 위한 Game 특화 플랫폼입니다.<br>Gamebase SDK만 적용하면, 로그인/결제/아이템/지표 등 게임 개발을 위한 핵심 기능을 손쉽게 개발할 수 있습니다.'
    },
    {
      titSection: '강력한 분석 지표 제공',
      descSection:
        '사업성 분석을 위해 별도 비용을 지불하고 분석플랫폼을 사용 하셨나요?<br /> Gamebase는 실시간 DAU, PU, ARPU 등의 기본 지표부터, LTV, Retention, 레벨 지표, 서버 현황 등 중요한 분석 지표를 제공합니다.'
    },
    {
      titSection: '사업/개발 측면의 강점',
      descSection: ''
    },
    {
      titSection: '글로벌 진출에 최적화된 게임 솔루션',
      descSection:
        'Gamebase는 기본적으로 게임의 글로벌 오픈을 지원하고 있으며, 글로벌 환경에서의 게임 운영을 위해 아래 기능을 제공합니다.',
      desc_map:
        '게임 이용자에게 표시되는 푸시, 점검 등은 모두 다국어 처리가 가능합니다.<br />국가 필터링 기능을 제공합니다.<br />운영자의 현지 표준 시간대(Local timezone)를 선택하여 손쉽게 시간 입력이 가능합니다.'
    },
    {
      titSection: '개발기간 단축 및 리소스 절감',
      descSection:
        '게임 오픈을 위해 수많은 SDK 분석 및 개발은 이제 그만! <br />Gamebase SDK 적용만으로 손쉽게 많은 기능을 구현할 수 있습니다.<br />공통 기능은 Gamebase 에 맡기시고, 개발 리소스는 게임 퀄리티 향상에 집중해보세요!',
      desc_map:
        '게임 이용자에게 표시되는 푸시, 점검 등은 모두 다국어 처리가 가능합니다.<br />국가 필터링 기능을 제공합니다.<br />운영자의 현지 표준 시간대(Local timezone)를 선택하여 손쉽게 시간 입력이 가능합니다.'
    },
    {
      titSection: '강력한 서비스 안정성 및 보안',
      descSection:
        'Gamebase를 2016년 정식 오픈한 이후로 큰 장애없이 안정적인 서비스를 이어가고 있습니다. <br />또한, NHN 전체적으로 System 보안 인증 및 개인정보 관리체계가 최고의 수준으로 관리되므로, 보안 및 해킹 위험에서 벗어나 안전한 게임 운영이 가능합니다.'
    },
    {
      titSection: '게임기술PM의 전문적인 컨설팅 및 문의대응',
      descSection:
        'NHN은 Gamebase를 개발하고, 실제 서비스에서 사용하는 게임 회사입니다. 외부 고객사 뿐만 아니라, <br />자사 게임도 Gamebase를 이용하고 있어 품질은 자신있습니다. <br />풍부한 현장 경험을 바탕으로 개발사의 문의 및 요청에 빠르게 대응합니다.'
    },
    {
      titSection: 'Gamebase Setting Tool',
      descSection:
        'Gamebase를 사용하는 중요한 이유, 바로 시간 싸움이죠? SDK를 다운받아 복사하고, 셋팅하고...<br />Gamebase는 이런 시간까지도 아낄 수 있도록 Unity Menu에 Integrated된 셋팅툴을 제공하여, 클릭 몇 번으로 게임 정보를 셋팅할 수 있도록 지원합니다.'
    }
  ],
  // service1
  lstItem: [
    {
      emText: '로그인 / 인증',
      pText:
        'Google, Apple ID, Apple Game Center, Facebook , Twitter, kakao, NAVER, LINE, PAYCO, Hangame, Guest, 자체회원 로그인 인증 기능을 제공합니다.'
    },
    {
      emText: '결제 / 아이템',
      pText:
        'Google Play, App Store, ONE Store, Galaxy Store, Amazon, HUAWEI, MyCard 등 다양한 스토어의 인앱 결제와 아이템 관리 기능을 제공합니다.'
    },
    {
      emText: '지표 / 통계',
      pText:
        '게임 사업/운영 의사결정에 꼭 필요한 다양한 view의 지표를 제공하며, 이용자, 매출, 밸런싱의 다각적 분석이 가능합니다.'
    },
    {
      emText: '점검 / PUSH',
      pText:
        '클라이언트 버전별, 국가별 점검 기능을 제공하며, 이용자 대상 Push 서비스(반복 발송, 예약발송, 타겟팅 발송)도 간편하게 발송할 수 있습니다.'
    },
    {
      emText: '게임 운영 툴',
      pText:
        'NHN 10년 노하우가 담긴 다양한 게임 운영툴을 제공합니다. 이용자 관리, 앱 버전 관리, 불량 이용자 제재 등 게임 운영에 꼭 필요한 핵심 기능을 경험하세요.'
    },
    {
      emText: '게임 보안',
      pText:
        '각종 어뷰징으로부터 안전하게 앱을 보호할 수 있는 모바일 앱 보안 솔루션으로, 해킹툴 탐지, 코드 위변조 방어 등 위험 상황에서 안전하게 운영하세요.'
    },
    {
      emText: '리더보드',
      pText:
        '일간/주간/월간 등 다양한 랭킹 서비스를 통해 게임 유저 간 다양한 재미 요소를 제공하고 안정적인 운영이 가능합니다.'
    },
    {
      emText: '다운로드 가속화',
      pText:
        '게임 설치 및 실행에 필요한 리소스를 보다 빠르고 안정적으로 다운로드할 수 있습니다. 빠른 다운로드로 이용자의 만족도를 높여보세요!'
    },
    {
      emText: '쿠폰서비스',
      pText:
        '이용자에게 지급할 쿠폰을 생성하고 관리할 수 있습니다. 시리얼 쿠폰, 키워드 쿠폰 등 다양한 마케팅 활동이 가능합니다.'
    },
    {
      emText: '고객센터',
      pText:
        '고객문의를 처리할 수 있으며 그 외 고객센터 페이지를 통해 제공할 수 있는 공지사항, FAQ 등의 설정을 관리할 수 있습니다.'
    }
  ],
  // service2
  lstBigItem: [
    {
      emText: '실시간 지표',
      imgSrc: '/resources/images/pc/img_indicator1.png',
      pText:
        '실시간 동접 현황 그래프 제공 (1분 단위 실시간) 매출, PU, ARPU, ARPPU 등 주요 지표에 대한 실시간 대시보드 제공'
    },
    {
      emText: '매출지표',
      imgSrc: '/resources/images/pc/img_indicator2.png',
      pText:
        '결제 금액, 유료 이용자에 대한 다양한 분석 기능 제공 아이템별 판매 지표, 첫 구매 아이템 지표 등 매출 분석 지표 제공'
    },
    {
      emText: '이용자 지표',
      imgSrc: '/resources/images/pc/img_indicator3.png',
      pText:
        '주요 KPI 지표를 국가별, OS별, 스토어별 등으로 정확하고 전문적으로 분석 이용자 유입, 이탈, 리텐션, LTV 등 이용자 분석을 위한 지표 확대 제공'
    },
    {
      emText: '밸런싱 지표',
      imgSrc: '/resources/images/pc/img_indicator4.png',
      pText:
        '게임 레벨별 사용자 및 매출 분석 지표 제공 레벨별 달성 시간, 첫구매 아이템 등게임 밸런싱에 필요한 활용 지표 제공'
    }
  ],
  // service3
  groupAdvantage: [
    {
      titAdvantageMirror: 'BUSINESS <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-03.svg',
      lstAdvantage: [
        { spanText: '업계 최저 요금제' },
        { spanText: '게임 특화된<br />분석 지표 무료 제공!' },
        { spanText: 'NHN 실사용<br />게임 플랫폼' },
        { spanText: '모바일을 PC에서!<br />하이브리드 플랫폼 제공!' }
      ]
    },
    {
      titAdvantageMirror: 'DEVELOPMENT <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-04.svg',
      lstAdvantage: [
        { spanText: '개발 기간 단축(공통 기능 개발 인력 및 공수 최소화) ' },
        { spanText: '게임기술PM의 기술지원' },
        { spanText: '365일 안정적인 <br />무정지 서비스' },
        { spanText: '서버 확장 용이성,<br />신속성' }
      ]
    }
  ],
  // service4
  map: {
    imgAlt: '세계지도',
    lstGlobal: [
      { spanText: ['USA', 'Hello!', 'UTC-06:00'] },
      { spanText: ['Korea', '안녕!', 'UTC-09:00'] },
      { spanText: ['Japan', 'こんにちは！', 'UTC-09:00'] },
      { spanText: ['China', '你好!', 'UTC-08:00'] }
    ]
  },
  // service5
  lstSpItem: [
    {
      emText: '개발 인력',
      spanText: '숙련도에 따라 5MM ~ 10MM 의 개발인력 필요',
      pText:
        '(내부 리서치 기준)<br />인증개발 / 결제 개발 / 랭킹개발 / 알림 / 정기정검 / 불량이용자 / 운영툴개발 / 지표 개발 / 모니터링'
    },
    {
      emText: '개발 기간',
      spanText: '개발 기간 증가 요인',
      pText:
        '로그인 인증 업체가 많아질수록 / AOS, iOS 등 OS가 업 데이트될 수록 / 정교한 운영툴이 필요할수록 / 깊이 있는 지표가 필요할수록'
    },
    {
      emText: '비용 증가',
      spanText: '개발 인력 투입 = 비용<br />개발 기간/관리 이슈 = 비용<br />서버/스토리지 = 비용',
      pText: ''
    }
  ],
  boxSp: 'Gamebase SDK를 도입하면 개발비용 대폭 절감 가능!',
  // service6
  lstLicence: [
    { spanText: '정보보호 관리체계 ISMS 인증' },
    { spanText: '클라우드 컴퓨팅 서비스 보안 인증 CSAP 인증' },
    { spanText: '정보보호 관리체계 ISMS 인증' },
    { spanText: '클라우드 서비스 정보보호 관리체계 ISO/IEC 27017 인증' },
    { spanText: '클라우드 서비스 개인정보 관리체계 ISO/IEC 27018 인증' },
    { spanText: '국제 정보보호 관리체계 ISO/IEC 27001 인증' },
    { spanText: '정보보호 관리체계 ISMS 인증' }
  ],
  // service7
  tech: {
    titSubSection: '강력한 기술 지원',
    descSection: '10년 이상 한게임, 벅스, PAYCO 서비스를 통해 축적된 IT 운영 경험으로 안정된 서비스를 제공합니다.',
    lstTech: [
      {
        emText: '컨설팅 서비스',
        spanText: '클라우드 도입 전략 수립 / 마이그레이션 지원'
      },
      {
        emText: '전문 엔지니어',
        spanText: '고객사 전담 인력 배정 / 24시간, 365일 지원 서비스'
      },
      {
        emText: '보안 서비스',
        spanText: '웹 보안 검수 보안 관제 서비스 CERT 서비스'
      },
      {
        emText: '모니터링 서비스',
        spanText: '모니터링 솔루션 제공 / 임계치 설정 및 알람 설정'
      },
      {
        emText: 'DDos 지원 서비스',
        spanText: '실시간 감시 및 DDos 장비 지원'
      },
      {
        emText: 'IT 관리 서비스',
        spanText: '전문 엔지니어의 IT 관리 서비스'
      }
    ]
  },
  // service8
  itemConsole: {
    strongText: 'Gamebase Setting Tool을 이용하여, 빠르고 쉽게 SDK를 설치해보세요!',
    imgAlt: 'Gamebase Setting Tool 화면',
    aText: 'Gamebase 제품 상담',
    spanText: '바로가기',
    linkEnd: 'Gamebase 제품 상담'
  }
};
