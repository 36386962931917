export default {
  requiredNamePhone: '이름과 연락처, 이메일은 필수 항목입니다.',
  requiredAgree: '개인정보 수집 및 활용에 동의하셔야 문의 입력이 가능합니다. 동의 후 입력해주세요.',
  contactTitle: '문의 유형 변경',
  contactContent: '작성한 문의 내용을 유지하시겠습니까?',
  cancle: '상담/문의 접수를 취소하시겠습니까?',
  regexError: '필수 항목으로 들어가는 정보가 잘못 되었습니다. 다시 확인해주세요.',
  demoRequiredInput: '이름과 ID, 이메일 회사명은 필수 항목입니다.',
  demoRequiredAgree:
    '개인정보 수집 및 활용에 동의하셔야 데모 프로젝트 계정 신청 입력이 가능합니다. 동의 후 입력해주세요.',
  demoCancleTitle: '데모 프로젝트 계정 신청 취소',
  demoCancleContent: '데모 프로젝트 계정 신청을 취소하시겠습니까?'
};
