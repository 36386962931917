export default {
  titTop: 'NHN Cloud Service',
  emphTop: 'You may use the following NHN Cloud services in tandem',
  sectionService: [
    {
      titSection: 'AppGuard for robust mobile security',
      descSection:
        'AppGuard is a mobile app security solution that can protect your apps from various kinds of abuse. <br> Not just for detecting simple cheat tools but also for other robust features including decompile prevention and integrity verification and simple implementation methods for the users’ convenience.'
    },
    {
      titSection: 'Log & Crash Search to manage and analyze game logs and crashes',
      descSection:
        "Log & Crash Search collects the clients' and servers' logs to search and view the logs you want. <br> It analyzes crashes on mobile apps and runs statistics on them to provide various information on the cause thereof."
    },
    {
      titSection: 'Build cloud infrastructure to suit the customers’ environment with Instance',
      descSection:
        'The integrated cloud service combines massive service experience and open source technology. <br> Cloud environment is provided to suit the customer environment, supporting them with reliable global service.'
    }
  ],
  lstAppguard: [
    {
      emText: 'Convenient web console',
      spanText: 'Log analysis console provided<br>Real-time policy setting enabled'
    },
    {
      emText: 'Robust security',
      spanText: 'Decompile prevention, code encryption<br>File integrity verification'
    },
    {
      emText: 'Simple application',
      spanText:
        'Application possible due to integration into development tools<br>Web console, CLI, Unity3D Editor expansion'
    }
  ],
  appguardGuide: {
    titSubSection: 'AppGuard use Flow',
    lstStep: [
      {
        emSpanText: 'Grade 1',
        lstStep2: [
          {
            strongText: 'Hacking Tool Detection',
            lstStep3: ['Cheat and Macro Tool Detection/Blockage']
          }
        ]
      },
      {
        emSpanText: 'Grade 2',
        lstStep2: [
          {
            strongText: 'OS Tampering Prevention',
            lstStep3: ['OS Rooting Environment Detection/Blockage', 'Emulator Environment Detection/Blockage']
          },
          {
            strongText: 'Code Tampering Protection',
            spanText: '(Integrity Check)',
            lstStep3: [
              'Encryption API (Tampering App Detection)',
              'Signature Tampering Detection/Blockage',
              'Manifest Tampering Detection/Blockage',
              'Main Module and Java Code (DEX) Tampering Detection/Blockage'
            ]
          },
          {
            strongText: 'Static Analysis Defense',
            lstStep3: [
              'Java Code (DEX) encryption',
              'game executable file encryption (cocos2d-x, Unity mono, Unreal Engine)'
            ]
          }
        ]
      },
      {
        emSpanText: 'Grade 3',
        lstStep2: [
          {
            strongText: 'Built-in Self-Protection',
            lstStep3: ['Protection of important AppGuard codes (Code obfuscation)']
          },
          {
            strongText: 'Dynamic Analysis Defense',
            lstStep3: [
              'Unity iL2CPP code analysis defense',
              'AppGuard code virtualization',
              '.so dynamic library protection'
            ]
          },
          {
            strongText: 'Behavior-based Macro Detection',
            lstStep3: ['Machine learning-based macro user detection through in-game touch point coordinate analysis']
          }
        ]
      }
    ]
  },
  appguardFlow: {
    titSubSection: 'AppGuard Use Flow',
    txtAppguardFlow1: 'Send Detection Log',
    txtAppguardFlow2: 'Send Detection Log',
    txtAppguardFlow3: 'Search Detection Log',
    descSection:
      "Detection bypass is difficult due to composite action of pattern detection and behavior-based detection using heuristics or cheat tools' behaviors.",
    lstStep: [
      {
        emSpanText: 'Protection',
        lstStep2: [
          {
            strongText: 'Repackaging protection <br class="noneview_m"> Protection against code hacking',
            lstStep3: [
              'Supports decompile prevention',
              'Supports game code encryption (Unity 3D mono, Unity 3D il2cpp, Cocos2d-x) and Java code encryption (DEX)'
            ]
          }
        ]
      },
      {
        emSpanText: 'Detection',
        lstStep2: [
          {
            strongText: 'Patterns',
            lstStep3: ['Detection of cheat tools, macro tools, and speed tool (files, processes, memory patterns)']
          },
          {
            strongText: 'Heuristics',
            lstStep3: ['Detection of emulator, debugging, etc.']
          },
          {
            strongText: 'Behavior-based',
            lstStep3: ['Detection of speed hacks, emulation, debugging, etc.']
          },
          {
            strongText: 'File Integrity',
            lstStep3: [
              'Integrity verification of built-up binary files <br> (detection of tampering for *.dex, *.so, *.dll, and resource files)'
            ]
          }
        ]
      },
      {
        emSpanText: 'Response',
        lstStep2: [
          {
            strongText: 'Restriction',
            lstStep3: [
              'Logs are sent upon abuse detection and restrictions are enforced based on log content and user id',
              'Automatic app blockage upon abuse detection (Client)'
            ]
          }
        ]
      }
    ]
  },
  skill: [
    {
      titSubSection: 'Key features',
      lstSkill: [
        {
          pText: 'Remove service influence scores due to massive amount of logs generated in game servers.'
        },
        {
          pText: 'View and analyze errors from all distributed clients in one place.'
        },
        {
          pText: 'Search logs from the mass of logs and views them by patterns.'
        },
        {
          pText: 'Monitor logs and crashes every 5 minutes'
        },
        {
          pText: 'Deletion of each service and viewing/analysis functions for different log types are provided.'
        },
        {
          pText: 'Supports errors, crash dumps, web application logs, user-defined message formats, etc.'
        },
        {
          pText:
            'Shows the number of logs for different projects, hosts, log types, log level and charts out log changes for different time periods on the log search page'
        },
        {
          pText: 'Makes it easier to check the logs by emphasizing the matching query words on the log'
        }
      ]
    },
    {
      titSubSection: 'Service target',
      lstSkill: [
        {
          spanClass: 'brand_target_log',
          pText: 'When too much web logs pile on Apache, Tomcat, etc.'
        },
        {
          spanClass: 'brand_no_server',
          pText: 'When you need to view the server log without accessing the server'
        },
        {
          spanClass: 'brand_target_collect',
          pText: 'When logs from apps distributed to the users should be viewed together'
        },
        {
          spanClass: 'brand_error_log',
          pText: 'When the changes in the error logs from the service must be tracked'
        }
      ]
    }
  ],
  feature: {
    titSubSection: 'Characteristics',
    lstFeature: [
      {
        strongText: 'Flexible infrastructure',
        pText: 'Flexible, OpenStack-based cloud infrastructure'
      },
      {
        strongText: 'Platform with variety',
        pText: 'Platform equipped with useful features including notification, analytics, and security'
      },
      {
        strongText: 'Global support',
        pText: 'Support through the game platform and global network'
      },
      {
        strongText: 'Hybrid cloud',
        pText: 'Environment to utilize both physical and virtual infrastructure simultaneously'
      },
      {
        strongText: 'Professional human resources',
        pText: 'Reliable tech support based on first-party service management'
      },
      {
        strongText: 'Reasonable prices',
        pText: 'Pay-per-use billing, minimized initial cost, 10-30% cheaper than other game platform services'
      },
      {
        strongText: 'Up-to-date cloud center',
        pText: 'High-density urban cloud center'
      }
    ]
  },
  platform: {
    titPlatform: 'Platform',
    lstPlatform: [
      { liText: 'Contents' },
      { liText: 'Analytics' },
      { liText: 'Game' },
      { liText: 'Security' },
      { liText: 'Notification' },
      { liText: 'Common' }
    ]
  },
  infra: {
    titInfra: 'Infrastructure',
    lstInfra: [
      { liText: 'instance' },
      { liText: 'Server Image (Glance)' },
      { liText: 'Load Balance' },
      { liText: 'Public IP' },
      { liText: 'Object Storage (Swift)' },
      { liText: 'Identity (Keystone)' },
      { liText: 'Technology & Monitoring (Cellometers)' },
      { liText: 'Dashboard (Horizon)' }
    ]
  },
  aTag: [
    { href: 'https://docs.toast.com/en/Security/NHN%20AppGuard/en/Overview/', aText: 'Go to AppGuard Service' },
    {
      href: 'https://docs.toast.com/en/Analytics/Log%20&%20Crash%20Search/en/Overview/',
      aText: 'Go to Log & Crash Search Service'
    },
    { href: 'https://docs.toast.com/en/Compute/Instance/en/overview/', aText: 'Go to Instance Service' }
  ]
};
