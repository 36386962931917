import { changeLocale } from '@/helpers/i18n';

export default {
  setCountryCode(state, langCode) {
    state.countryCode = langCode;
    changeLocale(langCode);
  },
  setViewMode(state, viewMode) {
    state.viewMode = viewMode;
  }
};
