export default {
  lstLegal: [
    { title: 'Company Overview', href: 'https://www.nhn.com/ko/index.nhn' },
    { title: 'Terms of Use', href: 'https://www.toast.com/kr/terms/terms-service' },
    { title: 'Privacy Policy', href: 'https://www.toast.com/kr/terms/privacy-policy' },
    { title: 'Product Consultation', href: '' }
  ],
  familySite: 'Family Site',
  more: 'more',
  familySiteSub: [
    { title: 'NHN Cloud', href: 'https://www.toast.com/kr' },
    { title: 'TOAST G', href: 'https://gov.toast.com/' },
    { title: 'TOAST CAM', href: 'http://cam.toast.com' },
    { title: 'TOAST CAM Biz', href: 'http://bizcam.toast.com' },
    { title: 'TOAST bill', href: 'https://bill.toast.com' },
    { title: 'Dooray!', href: 'http://dooray.com' },
    { title: 'PAYCO', href: 'http://www.payco.com' },
    { title: 'PAYCO 개발자센터', href: 'https://developers.payco.com' },
    { title: 'Hangame', href: 'http://www.hangame.com' },
    { title: 'NHN Corp.', href: 'https://www.nhn.com' }
  ],
  footerInfo: [
    'Company Name: NHN Cloud Corp. CEO: Paik Domin, Kim DongHoon',
    'Address: NHN Play Museum, 16 Daewangpangyo-ro 645beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, 13487, Korea<br>Customer Center : 1588-7967 Email : support@nhn.com Business Registration Number: 424-88-02352 Online Sales Business Registration Number: 2013- GyeonggiSeongnam-1067 ' +
      'Check Business Information'
  ],
  txtCopyright: 'ⓒ NHN Cloud Corp. All rights reserved.'
};
