import { lazyLayoutLoad, lazyViewLoad } from '@/helpers/router';

export default {
  path: '/:countryCode/download',
  component: lazyLayoutLoad('DownloadLayout'),
  redirect: '/:countryCode/download/main',
  children: [
    {
      path: 'main',
      component: lazyViewLoad('download/Main'),
      meta: { contentClass: 'cont_download', seo: 'seo.download.main' }
    }
  ]
};
