export default {
  titMain: 'Experience the real GamePlatform',
  emphMain1: 'NHN',
  emphMain2: 'Gamebase',
  utilBtn: {
    lightmode: 'Light Mode',
    darkmode: 'Dark Mode'
  },
  lstCategory: [
    {
      strongText: 'Gamebase',
      pText:
        'The top-notch game platform that provides all common functions including login, transaction, push, notice, maintenance, etc. through integrated SDK',
      spanText: 'Shortcut'
    },
    {
      strongText: 'Smart Downloader',
      pText: 'Quick and absolutely successful download of game resources including executable files and images',
      spanText: 'Shortcut'
    },
    {
      strongText: 'Leaderboard',
      pText: 'Real-time ranking service reliably operated and uninterrupted by massive ranking traffic',
      spanText: 'Shortcut'
    },
    {
      strongText: 'GameAnvil',
      pText: '멀티 대전 게임 서버의 개발 및 서버 운영까지 가능한 통합 솔루션',
      spanText: 'Shortcut'
    }
  ],
  titNotice: 'Notice',
  titNews: 'News'
};
