import * as GAMEBASE_DEMO_REPOSITORIES from '@/store/repositories/gamebaseDemo';

export default {
  // 요청
  // eslint-disable-next-line no-unused-vars
  addDemo: async ({ commit }, payload) => {
    const res = await GAMEBASE_DEMO_REPOSITORIES.addDemo(payload);
    console.log(res);

    const { header } = res;
    if (header.isSuccessful) {
      console.log(header.isSuccessful);

      return res.result.content;
    }

    return null;
  }
};
