export default {
  titTop: 'NHN Cloud Service',
  emphTop: '一緒にご利用いただけるNHN Cloudサービスをご紹介します。',
  sectionService: [
    {
      titSection: '強力なチート対策ツール、AppGuard',
      descSection:
        'AppGuardは、様々な不正行為から安全にアプリを保護できる、モバイルアプリ・セキュリティソリューションです。 <br> 単純なチートツール検知機能だけでなく、デコンパイル防止や改ざん防止などの強力な機能が含まれており、簡単な適用方法で利用することができます。'
    },
    {
      titSection: 'ゲームログとクラッシュを管理·分析する、Log & Crash Search',
      descSection:
        'Log & Crash Searchは、クライアントとサーバーのログを収集し、目的のログを検索、照会するシステムです。 <br>モバイルアプリで発生するクラッシュの分析·統計作業を行い、クラッシュ発生原因に関する様々な情報を提供しています。'
    },
    {
      titSection: '顧客環境に合わせたクラウドインフラの構築、インスタンス',
      descSection:
        '大規模サービス経験とオープンソース技術が融合した統合クラウドサービスです。 <br>お客様の環境に合わせたクラウド環境を提供し、安定したグローバルサービスの運営を支援しています。'
    }
  ],
  lstAppguard: [
    {
      emText: '便利なウェブコンソール',
      spanText: 'ログ分析コンソールを提供<br>リアルタイムポリシー設定可能'
    },
    {
      emText: '強力な保安',
      spanText: 'デコンパイル防止、コード暗号化<br>ファイル完全性検証'
    },
    {
      emText: '手軽な適用',
      spanText: '開発ツールに統合して適用可能<br>ウェブコンソール、CLI、Unity3Dエディタ拡張'
    }
  ],
  appguardGuide: {
    titSubSection: 'AppGuard 利用 Flow',
    lstStep: [
      {
        emSpanText: 'Grade 1',
        lstStep2: [
          {
            strongText: 'ハッキングツールの検知',
            lstStep3: ['チートおよびマクロツール検知/遮断']
          }
        ]
      },
      {
        emSpanText: 'Grade 2',
        lstStep2: [
          {
            strongText: 'OS改ざん防止',
            lstStep3: ['ルート化/脱獄環境検知/遮断', 'エミュレータ環境検知/遮断']
          },
          {
            strongText: 'コード改ざん防御',
            spanText: '(整合性チェック)',
            lstStep3: [
              '暗号化API(改ざんアプリ検知)',
              '署名改ざん検知/遮断',
              'マニフェスト改ざん検知/遮断',
              '主要モジュールおよびジャワコード(DEX)改ざん検知/遮断'
            ]
          },
          {
            strongText: '静的分析防御',
            lstStep3: ['Javaコード(DEX)暗号化', 'ゲーム実行ファイル暗号化(cocos2d-x,Unitimono,UnrealEngine)']
          }
        ]
      },
      {
        emSpanText: 'Grade 3',
        lstStep2: [
          { strongText: '自己保護', lstStep3: ['AppGuard内の重要コード保護(コードの難読化)'] },
          {
            strongText: '動的分析防御',
            lstStep3: ['Unitiy iL2CPP コード分析防御', 'AppGuard コード仮想化', '.so 動的ライブラリ保護']
          },
          {
            strongText: '行為ベースマクロ検知',
            lstStep3: ['ゲーム内タッチ座標分析による機械学習基盤マクロユーザー検出']
          }
        ]
      }
    ]
  },
  appguardFlow: {
    titSubSection: 'AppGuard 利用 Flow',
    txtAppguardFlow1: '検知ログ転送',
    txtAppguardFlow2: '検知ログ転送',
    txtAppguardFlow3: '検知ログ検索',
    descSection:
      'パターンによる検知、ヒューリスティックまたはチートツールの動作方式による行動ベースの検知を組み合わせて作動するため、検知の迂回は困難です。',
    lstStep: [
      {
        emSpanText: '防止',
        lstStep2: [
          {
            strongText: 'リパッケージング防御<br class="noneview_m">コード操作防御',
            lstStep3: [
              'デコンパイル防止対応',
              'ゲームコード暗号化(Unity 3D mono, Unity 3D il2cpp, Cocos 2d-x)およびJavaコード暗号化(DEX)対応'
            ]
          }
        ]
      },
      {
        emSpanText: '検知',
        lstStep2: [
          {
            strongText: 'パターン',
            lstStep3: ['チートツール、マクロツール、スピード操作ツールなど検知（ファイル、プロセス、メモリパターン）']
          },
          {
            strongText: 'ヒューリスティック',
            lstStep3: ['エミュレータ、デバッグなどを検知']
          },
          {
            strongText: '行動ベース',
            lstStep3: ['スピードハック、エミュレータ、デバッグなどの検知']
          },
          {
            strongText: 'ファイル完全性',
            lstStep3: [
              'ビルドされたバイナリファイルの完全性検証<br>(*.dex, *.so, *.dll,リソースファイルに対する改ざん検知)'
            ]
          }
        ]
      },
      {
        emSpanText: '対応',
        lstStep2: [
          {
            strongText: '題材',
            lstStep3: [
              'Abuse検知時ログ転送検知ログの内容とユーザーIDによる制裁',
              'Abuse検知時アプリ実行自動遮断(クライアント)'
            ]
          }
        ]
      }
    ]
  },
  skill: [
    {
      titSubSection: '主要機能',
      lstSkill: [
        {
          pText: 'ゲームサーバーから発生する大量のログによるサービスへの影響を低減することができます。'
        },
        {
          pText: '配布されたすべてのクライアントで発生するエラーを1ヶ所に集めて照会し、分析することができます。'
        },
        {
          pText: '大量のログから必要なログを素早く検索でき、パターン別にログを照会することができます。'
        },
        {
          pText: 'ログとクラッシュを5分単位でモニタリングすることができます。'
        },
        {
          pText: 'サービス別削除、様々なログ タイプ別に照会/分析機能を提供します。'
        },
        {
          pText:
            'エラー、クラッシュダンプ、ウェブ アプリケーションログ、ユーザー定義メッセージ形式などをサポートします。'
        },
        {
          pText:
            'プロジェクト、ホスト、ログのタイプ、ログのレベル別にログ数を見ることができ、ログ検索ページでは時間帯別ログ推移がチャートで確認できます。'
        },
        {
          pText: 'ログ本文でクエリーに一致する単語がハイライトされるので、簡単に確認することができます。'
        }
      ]
    },
    {
      titSubSection: 'サービス対象',
      lstSkill: [
        {
          spanClass: 'brand_target_log',
          pText: 'Apache、Tomcatなどのウェブログが蓄積しすぎている場合'
        },
        {
          spanClass: 'brand_no_server',
          pText: 'サーバーログをサーバーに接続せずに見たい場合'
        },
        {
          spanClass: 'brand_target_collect',
          pText: 'ユーザーに配布したアプリから発生したログを一箇所に集約したい場合'
        },
        {
          spanClass: 'brand_error_log',
          pText: 'サービスで発生した不具合のログの推移を確認したい場合'
        }
      ]
    }
  ],
  feature: {
    titSubSection: '特徴',
    lstFeature: [
      {
        strongText: '柔軟なインフラ',
        pText: 'OpenStack基盤の柔軟なクラウドインフラを提供'
      },
      {
        strongText: '多様なプラットフォーム',
        pText: 'Notifications、Analytics、Securityなどの有用な機能で構成されたプラットフォームを提供'
      },
      {
        strongText: 'グローバルサポート',
        pText: 'ゲームプラットフォームおよびグローバルネットワークを通じたサポート'
      },
      {
        strongText: 'ハイブリッド クラウド',
        pText: '物理インフラと仮想インフラを同時に活用できる環境を提供'
      },
      {
        strongText: '専門スタッフ',
        pText: '自社サービス運営の経験を基に、技術支援'
      },
      {
        strongText: '合理的な費用',
        pText: '使った分だけの費用、初期費用負担の最小化、他社と比べ10~30%割安'
      },
      {
        strongText: '最新のクラウドセンター',
        pText: '高集積都心型クラウドセンター'
      }
    ]
  },
  platform: {
    titPlatform: 'Platform',
    lstPlatform: [
      { liText: 'Contents' },
      { liText: 'Analytics' },
      { liText: 'Game' },
      { liText: 'Security' },
      { liText: 'Notification' },
      { liText: 'Common' }
    ]
  },
  infra: {
    titInfra: 'Infrastructure',
    lstInfra: [
      { liText: 'instance' },
      { liText: 'Server Image (Glance)' },
      { liText: 'Load Balance' },
      { liText: 'Public IP' },
      { liText: 'Object Storage (Swift)' },
      { liText: 'Identity (Keystone)' },
      { liText: 'Technology & Monitoring (Cellometers)' },
      { liText: 'Dashboard (Horizon)' }
    ]
  },
  aTag: [
    { href: 'https://www.toast.com/jp/service/security/nhn-appguard', aText: 'AppGuardサービス リンク' },
    { href: 'https://www.toast.com/jp/service/analytics/log-crash-search', aText: 'Log & Crash Searchサービス リンク' },
    { href: 'https://www.toast.com/jp/service/compute/instance', aText: 'Instanceサービス リンク' }
  ]
};
