import { initModelData, exportData } from '@/helpers/store';

export default class TodoRecord {
  constructor(data) {
    initModelData(this._data, data);
  }

  _data = this.getDefaultData();

  getDefaultData() {
    return {
      id: -1, // 아이디
      title: '', // 제목
      timestamp: '', // 시간
      done: false // 완료 여부
    };
  }

  get id() {
    return this._data.id;
  }

  get title() {
    return this._data.title;
  }

  set title(title) {
    this._data.title = title;
  }

  get timestamp() {
    return this._data.timestamp;
  }

  get done() {
    return this._data.done;
  }

  set done(done) {
    this._data.done = done;
  }

  exportData(omitKeys = []) {
    return exportData(this._data, omitKeys);
  }
}
