import { lazyLayoutLoad, lazyViewLoad } from '@/helpers/router';

export default {
  path: '/:countryCode/contact',
  component: lazyLayoutLoad('ContactLayout'),
  redirect: '/:countryCode/contact/main',
  children: [
    {
      path: 'main',
      component: lazyViewLoad('contact/Main'),
      meta: { contentClass: 'cont_contact', seo: 'seo.contact.main' }
    },
    {
      path: 'done',
      component: lazyViewLoad('contact/Done'),
      meta: { contentClass: 'cont_contact', seo: 'seo.contact.done' }
    }
  ]
};
