import api from '@/helpers/api';
import { createUrl } from '@/helpers/store';

export function addDemo(data) {
  console.log(data)
  
  return api({
    method: 'post',
    url: createUrl('demo/join'),
    data
  });
}
