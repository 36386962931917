<template>
  <div>
    <common-header />
    <div class="todo">
      <button type="button" @click="handleExportData">export data</button>
      <form @submit.prevent="handleSubmit">
        <fieldset>
          <input v-model="todoForm.title" type="text" />
          <button type="submit">add</button>
        </fieldset>
      </form>
      <button type="button" @click="fetchList()">목록 다시 가져오기</button>
      <button type="button" @click="handleDeleteAll()">모두 제거</button>
      <ul>
        <li v-for="{ id, title, done } in todoList" :key="id">
          {{ title }}
          <input type="text" :value="title" @keypress.enter="handleChangeTitle($event, id)" />
          <button type="button" @click="handleToggleDone(id, !done)">{{ done ? '완료' : '미완료' }}</button>
          <button type="button" @click="handleDeleteItem(id)">삭제</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CommonHeader from '@/components/CommonHeader.vue';

export default {
  name: 'TodoListPage',
  components: {
    CommonHeader
  },
  data() {
    return {
      todoForm: {
        title: ''
      },
      todos: [],
      exportTodoList: []
    };
  },
  computed: {
    ...mapGetters('example', ['todoList'])
  },
  created() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      this.$store.dispatch('example/fetchExampleList');
    },
    handleExportData() {
      const exportList = this.todoList.map(todo => {
        return todo.exportData(['timestamp']);
      });
      console.log(exportList);
    },
    handleSubmit() {
      console.log('handlesubmit');
      const { title } = this.todoForm;
      this.$store.dispatch('example/addExampleItem', { title });
    },
    handleChangeTitle($event, id) {
      const { value: title } = $event.target;
      this.$store.dispatch('example/updateExampleItem', {
        id,
        payload: {
          id,
          title
        }
      });
    },
    handleToggleDone(id, done) {
      this.$store.dispatch('example/updateExampleItem', {
        id,
        payload: {
          id,
          done
        }
      });
    },
    handleDeleteItem(id) {
      this.$store.dispatch('example/deleteExampleItem', id);
    },
    handleDeleteAll() {
      const ids = this.todoList.map(todo => todo.id);
      this.$store.dispatch('example/deleteExampleList', { ids });
    }
  }
};
</script>
<style lang="scss" scoped>
.todo {
  padding: 40px;
  form,
  ul {
    margin: 20px 0;
  }
  input {
    height: 34px;
  }
  button {
    margin: 0 4px;
    padding: 5px 15px;
    font-size: 20px;
    vertical-align: middle;
    border: 1px solid #111;
    &:hover {
      color: #fff;
      background: #111;
    }
  }
}
</style>
