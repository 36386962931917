export default {
  titTop: 'Smart Downloader',
  emphTop: 'Manage resources and downloads all at once!',
  descTop:
    'Provides fast download speed and high success rate by providing game resources in a compressed multi-threaded format so that content installation can be made fast and stable in global network environments',
  sectionDiagram: {
    imgAlt: 'Smart Downloader Diagram',
    txtService1: 'File compression and metadata generation',
    txtService2: 'Download index collection',
    txtSubService: '(Success rate for each country, download speed, cause of failure, etc.)',
    txtService3: 'High success rate! <br />Rapid download!'
  },
  sectionService: [
    {
      titSection: 'Even large files are no problem!',
      descSection:
        'Provides fast download speed and high success rate by providing game resources in a compressed <br />multi-threaded format so that content installation can be made fast and stable in global network environments.'
    },
    {
      titSection: 'Smart Downloader Unity Tool',
      descSection:
        'Smart Downloader Unity Tool (SUT) makes it possible to update and distribute resources on Unity.<br />We provide a tool for easy management of resources and uploads on Unity.',
      titSubSection: 'Quick and easy game resource deployment!'
    }
  ],
  // service1
  lstItem: [
    {
      pText: 'Games can be installed and updated fast even in slow network environments.'
    },
    {
      pText: 'Only the renewed resources can be updated after the initial installation.'
    },
    {
      pText: 'Controlling updates with metafile edits makes resource update possible without game code changes.'
    },
    {
      pText:
        'Files for distribution can be uploaded to CDN in one step on the console, so CDN can be linked conveniently at low costs.'
    },
    {
      pText:
        'Real-time download indexes help you understand service stability for different countries and respond to installation and update issues.'
    }
  ],
  linkEnd: 'Smart Downloader Product Consultation',
  icoArrowForwardW: 'Shortcut'
};
