export default {
  titSection: 'Once you use it, you can never stop using it again!',
  descSection:
    'Through its top-notch game platform, Gamebase successfully launched well-known game titles in and out of Korea and is reliably serving many high-traffic games such as Lord of Heroes (Clover Games), Lyn: The Lightbringer (Pulsar Creative), Three Kingdoms Black Label (YJM Games), Friends Pop (Pixelcube), Pokopang!, Yo-Kai Watch, etc.',
  customers: [
    { title: 'Rise of Stars', text: 'LightCON' },
    { title: 'Lord of Heroes', text: 'Clover Games' },
    { title: '#Me', text: 'Clover Games' },
    { title: 'GUNS UP! Mobile', text: 'NHN BigFoot' },
    { title: 'Every Farm', text: 'Wemade Connect' },
    { title: 'Counterside', text: 'Studiobside' },
    { title: 'The Secret of Cat Island', text: 'LikeItGames' },
    { title: 'TaegoM', text: 'CITYLABS' },
    { title: 'DRAGON RAJA ORIGIN', text: 'Aprogen Games' },
    { title: 'EOS RED', text: 'BluePotion Games' },
    { title: 'Yo-kai WatchN', text: 'NHN PlayArt' },
    { title: '#Compass', text: 'NHN PlayArt' },
    { title: 'Vestria Story', text: 'PANGSKY' },
    { title: 'LUTIE SAGA', text: 'NSIX' },
    { title: 'Lyn: The Lightbringer', text: 'Pulsar Creative' },
    { title: 'Backalley Stage', text: 'NZiN' },
    { title: 'Bald Story', text: 'Teammium' },
    { title: 'Power Rangers: All Stars', text: 'MOVE GAMES' },
    { title: 'Mysterious Apartment Soul Fighters', text: 'MOVE GAMES' },
    { title: 'Red Dot: Front Line', text: 'ONFACE Games' },
    { title: 'Blood Chaos', text: 'Day1ent' },
    { title: 'Changcheon Samguk', text: 'Day1ent' },
    { title: 'New Journey to the West', text: 'Day1ent' },
    { title: 'Night of Demons', text: 'DoubleGames' },
    { title: 'Three Kingdoms Black Label', text: 'YJM Games' },
    { title: 'Star Wars: Starfighter Missions', text: 'Joymax' },
    { title: 'The Third Age', text: 'IMI' },
    { title: 'Yongbi the Invincible M', text: 'VALOFE' },
    { title: 'Critical Ops: Reloaded', text: 'NHN' },
    { title: 'FishIsland: Fishing Paradise', text: 'NHN' },
    { title: 'Crusaders Quest', text: 'NHN' },
    { title: 'How to Keep a Mummy', text: 'NHN' },
    { title: 'RWBY: Amity Arena', text: 'NHN' },
    { title: 'Kingdom Story: Brave Legion', text: 'NHN' },
    { title: 'AFTER LIFE', text: 'NHN' },
    { title: 'the sword of the east', text: 'WithHug' },
    { title: 'Weapon Heroes', text: 'Gamepub' },
    { title: 'Warrior who level up hard', text: 'SuperPlanet' },
    { title: 'Merge Tactics: Kingdom Defense', text: 'LoadComplete' },
    { title: 'Five Stars', text: 'SkyPeople' },
    { title: 'Princess Tale', text: 'Gamepub' },
    { title: 'Final FateTD', text: 'Gamepub' },
    { title: 'Island M', text: 'Gamepub' },
    { title: 'Twin Three Kingdoms', text: 'Gamepub' },
    { title: 'Three Kingdoms - Kingdom Defense', text: 'Gamepub' },
    { title: 'Yul-Hyul Kangho M: Ruler of the Land', text: 'ACTFIVE' },
    { title: 'Dungeon & Alchemist', text: 'Act Seven Entertainment' },
    { title: 'Dungeon & Hunter ', text: 'Act Seven Entertainment' },
    { title: 'Sky Warrior', text: 'WithHug' },
    { title: 'Strongest chivalrous fighter', text: 'WithHug' },
    { title: 'JeoldaeMurim', text: 'WithHug' },
    { title: 'Ilgeomhwaseon', text: 'WithHug' },
    { title: 'King three kingdoms', text: 'WithHug' },
    { title: 'Hot blooded battle', text: 'WithHug' },
    { title: 'Sunblade', text: 'WithHug' },
    { title: "God's Chronicles", text: 'WithHug' },
    { title: 'Three Kingdoms Blood', text: 'WithHug' },
    { title: 'Scarlet Borderland', text: 'WithHug' },
    { title: 'Videogame Guardians', text: 'SuperPlanet' },
    { title: 'Evil Hunter Tycoon', text: 'SuperPlanet' },
    { title: 'Sword Master Story', text: 'SuperPlanet' },
    { title: 'YaShin', text: 'Sambon Electronics' },
    { title: 'The warrior became a box', text: 'SuperPlanet' },
    { title: 'Sheep Tycoon', text: 'NO ANSWER studio' },
    { title: 'Come to the convenience store', text: 'NEW.F.O' },
    { title: 'Three Kingdoms Defense', text: 'MOHA Games' },
    { title: 'Princess Maker', text: 'Mgame' },
    { title: 'Idle Zombie', text: 'IOI Games' },
    { title: 'Hyeobmu', text: 'Gosu Gamese' },
    { title: 'All In Three Kingdoms', text: 'CHAPLIN GAME' },
    { title: 'Iron & Blood Three Kingdoms', text: 'CHAPLIN GAME' },
    { title: ' Three Kingdoms K', text: 'CHAPLIN GAME' },
    { title: 'Cheer Up, Master.', text: 'mokacorp' },
    { title: 'Legendary voyage', text: 'Gosu Gamese' },
    { title: 'God of sword', text: 'WithHug' },
    { title: 'Sniper Girls', text: 'Thunder Games' },
    { title: 'Hero Fighters', text: 'R2ON' },
    { title: 'War of kings', text: 'monawa' }
  ],
  icon: [{ name: 'Go to Website' }, { name: 'App Store' }, { name: 'Google Play' }, { name: 'ONE store' }]
};
