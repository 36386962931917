export default {
  titTop: 'Instance',
  emphTop: '适合客户环境的云基础设施建设!',
  descTop: '是融合大规模服务经验和开源技术的综合云服务。',
  titSection: '特征',
  descSection: '提供给客户更加适合的云环境，支持稳定的全球服务。',
  feature: {
    lstFeature: [
      {
        strongText: '灵活的基础设施',
        pText: '提供基于OpenStack的灵活云基础设施'
      },
      {
        strongText: '各种平台',
        pText: '提供由Notifications、Analytics、Security等有用功能组成的平台'
      },
      {
        strongText: '全球支持',
        pText: '通过游戏平台和全球网络提供支持'
      },
      {
        strongText: '混合云',
        pText: '同时提供利用物理基础设施和虚拟基础设施的环境.'
      },
      {
        strongText: '专业人员',
        pText: '以本公司服务运营经验为基础，提供稳定的技术支持'
      },
      {
        strongText: '合理的费用',
        pText: '使用多少支付多少费用，初期费用负担最小化，比其他公司便宜10-30%'
      },
      {
        strongText: '最新型云中心',
        pText: '高密度城市云中心'
      }
    ]
  },
  platform: {
    titPlatform: 'Platform',
    lstPlatform: [
      { liText: 'Contents' },
      { liText: 'Analytics' },
      { liText: 'Game' },
      { liText: 'Security' },
      { liText: 'Notification' },
      { liText: 'Common' }
    ]
  },
  infra: {
    titInfra: 'Infrastructure',
    lstInfra: [
      { liText: 'instance' },
      { liText: 'Server Image (Glance)' },
      { liText: 'Load Balance' },
      { liText: 'Public IP' },
      { liText: 'Object Storage (Swift)' },
      { liText: 'Identity (Keystone)' },
      { liText: 'Technology & Monitoring (Cellometers)' },
      { liText: 'Dashboard (Horizon)' }
    ]
  },
  service2: {
    titSection: 'Credibility authenticated by international standards',
    descSection:
      'Since the official launch of Gamebase in 2016, the service has never been stopped, and it is a zero downtime service. <br>' +
      '(Excluding service failure caused by external IDP issues such as Facebook authentication failure, etc) <br>' +
      'The entire NHN Cloud service is certified by international security standards and strictly manages personal information, which enables game services to operate securely without risk of hacking.'
  },
  ul1LstSecurityCertify: [
    {
      emphG: 'ISO/IEC 27001 Certified',
      infoDsc: 'International standard certified for management system for protection of information'
    },
    {
      emphG: 'ISO/IEC 29100 Certified',
      infoDsc: 'International standard certified for personal information framework'
    },
    {
      emphG: 'ISO/IEC 27017/27018 Certified',
      infoDsc:
        'Protection of cloud service information/ personal information International standard certified for management system'
    }
  ],
  ul2LstSecurityCertify: [
    {
      emphG: 'PIMS Certified',
      infoDsc:
        'Protection of personal information to the highest level of Korea Publicly-acknowledged management system'
    },
    {
      emphG: 'ISMS Certified',
      infoDsc: 'Authentication for the maintenance of management system for continued protection of information'
    },
    {
      emphG: 'CSAP Certified / Cloud Service Security Certified',
      infoDsc:
        'Evaluation, Certification for the level of information protection to provide safe cloud service to public institutions '
    }
  ],
  aTag: [
    { href: 'https://www.toast.com/kr/service/security/nhn-appguard', aText: '转到AppGuard服务' },
    {
      href: 'https://www.toast.com/kr/service/analytics/log-crash-search',
      aText: '转到Log & Crash Search服务'
    },
    { href: 'https://www.toast.com/kr/service/compute/instance', aText: '转到Instance服务' }
  ]
};
