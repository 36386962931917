export default {
  titSection: '경험하지 못한 고객은 있어도, 한번만 쓰는 고객은 없습니다!',
  descSection:
    'Gamebase는 최고의 게임 플랫폼 제공으로 국내외 유수의 게임을 성공적으로 런칭하였으며, <br>' +
    '로드오브히어로즈(클로버게임즈), 린: 더 라이트브링어 (펄사 크리에이티브), 삼국지 블랙라벨 (YJM게임즈),<br>' +
    '프렌즈팝(픽셀큐브), 포코팡, 요괴워치 등 트래픽이 많은 게임 서비스도 안정적으로 운영 중에 있습니다.',
  customers: [
    { title: 'Rise of Stars', text: '라이트컨' },
    { title: 'Lord of Heroes', text: 'Clover Games' },
    { title: '잇츠미', text: 'Clover Games' },
    { title: '건즈업! 모바일', text: 'NHN BigFoot' },
    { title: '에브리팜', text: 'Wemade Connect' },
    { title: '카운터사이드', text: 'Studiobside' },
    { title: '고양이 섬의 비밀', text: '라이크잇게임즈' },
    { title: '태고M', text: '시티랩스' },
    { title: '드래곤라자 오리진', text: '에이프로젠게임즈' },
    { title: '에오스 레드', text: '블루포션게임즈' },
    { title: 'Yo-kai WatchN', text: 'NHN PlayArt' },
    { title: '#Compass', text: 'NHN PlayArt' },
    { title: '베스트리아 전기', text: '팡스카이' },
    { title: '루티에 사가', text: '엔식스' },
    { title: 'LYN : 더 라이트브링어', text: '펄사 크리에이티브' },
    { title: 'Backalley Stage', text: '엔진' },
    { title: '대머리 키우기', text: '팀미음' },
    { title: '파워레인저: 올스타즈', text: 'MOVE GAMES' },
    { title: '신비아파트: 소울파이터즈', text: 'MOVE GAMES' },
    { title: '레드닷', text: 'ONFACE Games' },
    { title: '블러드 카오스 : 각성', text: '하루엔터테인먼트' },
    { title: '창천삼국', text: '하루엔터테인먼트' },
    { title: '신서유기', text: '하루엔터테인먼트' },
    { title: '백귀야행', text: 'DoubleGames' },
    { title: '삼국지 블랙라벨', text: 'YJM Games' },
    { title: '스타워즈:스타파이터 미션', text: 'Joymax' },
    { title: '서드에이지', text: '(주)아이엠아이' },
    { title: '용비불패M', text: '밸로프' },
    { title: '크리티컬 옵스 : 리로디드', text: 'NHN' },
    { title: '피쉬 아일랜드 정령의 항로', text: 'NHN' },
    { title: '크루세이더 퀘스트', text: 'NHN' },
    { title: '미이라사육법', text: 'NHN' },
    { title: 'RWBY: Amity Arena', text: 'NHN' },
    { title: '킹덤 스토리', text: 'NHN' },
    { title: 'AFTER LIFE', text: 'NHN' },
    { title: '동방의칼', text: '위드허그' },
    { title: '대장장이 용병단', text: '게임펍' },
    { title: '각성: 열렙전사', text: 'SuperPlanet' },
    { title: '머지 택틱스 : 킹덤 디펜스', text: '로드컴플릿' },
    { title: '파이브 스타즈', text: '스카이피플' },
    { title: '프린세스테일', text: '게임펍' },
    { title: '파이널페이트TD', text: '게임펍' },
    { title: '아일랜드M', text: '게임펍' },
    { title: '쌍삼국지', text: '게임펍 ' },
    { title: '삼국지킹덤디펜스', text: '게임펍' },
    { title: '열혈강호M : 무림쟁패', text: '액트파이브' },
    { title: '연금술사 키우기', text: 'Act Seven Entertainment ' },
    { title: '사냥꾼 키우기', text: 'Act Seven Entertainment ' },
    { title: '하늘무사', text: '위드허그' },
    { title: '최강협객', text: '위드허그' },
    { title: '절대무림', text: '위드허그' },
    { title: '일검화선', text: '위드허그' },
    { title: '왕삼국지', text: '위드허그' },
    { title: '열혈군영전', text: '위드허그' },
    { title: '썬블레이드', text: '위드허그' },
    { title: '신의연대기', text: '위드허그' },
    { title: '삼국지혈전', text: '위드허그' },
    { title: '비홍지경', text: '위드허그' },
    { title: '전자오락수호대', text: 'SuperPlanet' },
    { title: '이블헌터타이쿤', text: 'SuperPlanet' },
    { title: '소드마스터스토리', text: 'SuperPlanet' },
    { title: '야신 : 신을 삼킨 자', text: '삼본전자 주식회사' },
    { title: '용사는상자가되었다', text: 'SuperPlanet' },
    { title: '양떼목장 키우기', text: '노앤써 스튜디오' },
    { title: '와라편의점 ', text: '뉴에프오' },
    { title: '삼국지디펜스', text: '모하게임즈 ' },
    { title: '프린세스메이커', text: '엠게임' },
    { title: '방치 좀비 ', text: 'IOI Service' },
    { title: '협무', text: '고수게임즈' },
    { title: '올인삼국', text: '채플린게임' },
    { title: '철혈삼국 : 세계대전', text: '채플린게임' },
    { title: '삼국지K', text: '채플린게임' },
    { title: '힘내세요 마스터', text: '모카' },
    { title: '대항해의 전설', text: '고수게임즈' },
    { title: '검신', text: '위드허그' },
    { title: 'Sniper Girls', text: '썬더게임즈' },
    { title: '히어로 파이터즈', text: '알투온' },
    { title: '왕들의전쟁(던전의신)', text: '모나와' }
  ],
  icon: [{ name: '홈페이지 바로가기' }, { name: '앱스토어' }, { name: '구글 스토어' }, { name: '원스토어' }]
};
