// 모델 데이터 초기화
export const initModelData = (data, initData) => {
    Object.keys(initData).forEach(key => {
        if (key in data) {
            data[key] = initData[key];
        }
    });
};

// 모델 raw 데이터 반환
export const exportData = (data, omitKeys) => {
    const clone = JSON.parse(JSON.stringify(data));
    const result = {};
    Object.keys(clone)
        .filter(key => {
            return !omitKeys.includes(key);
        })
        .forEach(key => {
            result[key] = data[key];
        });

    return result;
};

// url 생성
export const createUrl = url => {
    return `${API_DOMAIN}/api/${url}`;
};