export default {
  titTop: 'Log & Crash Search',
  emphTop: '管理和分析游戏Log和闪退的!',
  titSection: '特征',
  descTop: 'Log & Crash Search是一个通过从客户端和服务器收集日志来搜索和查询所需日志的系统。 ',
  descSection: '通过分析 Mobile APP 发生的闪退并统计操作，为闪退原因提供更加多样化的信息。',
  skill: [
    {
      titSubSection: '主要功能',
      lstSkill: [
        {
          pText: '可消除游戏服务器上大量日志对服务所造成的影响。'
        },
        {
          pText: '可将所有客户端发生的错误汇总到一起进行查询和分析。'
        },
        {
          pText: '可在大量日志中快速搜索所需要的日志，也可以按模式查询日志 。'
        },
        {
          pText: 'Log和Crash可以5分钟为单位进行监测。'
        },
        {
          pText: '提供按服务删除和按各种日志类型搜索/分析的功能。'
        },
        {
          pText: '支持错误以及闪退Log抓包,Web Application 日志,自定义消息格式。'
        },
        {
          pText: '可按项目,主机,日志类型,日志等级查看日志数量,在日志搜索页面上可查看各时间段的日志趋势。'
        },
        {
          pText: '可以轻松查看在日志正文中突出显示匹配的单词， '
        }
      ]
    },
    {
      titSubSection: '服务对象',
      lstSkill: [
        {
          spanClass: 'brand_target_log',
          pText: 'Apache、Tomcat等网络日志堆积较多时'
        },
        {
          spanClass: 'brand_no_server',
          pText: '在不连接服务器的情况下查看服务器日志时'
        },
        {
          spanClass: 'brand_target_collect',
          pText: '将客户端发生的错误汇总在一起时'
        },
        {
          spanClass: 'brand_error_log',
          pText: '确认服务发生错误日志的趋势时'
        }
      ]
    }
  ],
  aTag: [
    { href: 'https://www.toast.com/kr/service/security/nhn-appguard', aText: '转到AppGuard服务' },
    {
      href: 'https://www.toast.com/kr/service/analytics/log-crash-search',
      aText: '转到Log & Crash Search服务'
    },
    { href: 'https://www.toast.com/kr/service/compute/instance', aText: '转到Instance服务' }
  ]
};
