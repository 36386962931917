export default {
  sectionTop: {
    titTop: 'Pricing',
    emphTop: '실용적인 서비스 구성과 합리적인 요금제를 제공합니다.'
  },
  sectionService1: {
    titSection: 'Gamebase 요금',
    coverPrice: {
      descSection:
        'Gamebase는 최고 수준의 게임 플랫폼을 요금제 차등없이 제공합니다.<br>' +
        '게임 지표, 푸시, 고객 센터, 쿠폰, 환불어뷰징, 랭킹 등 게임 운영에 꼭 필요한 기능을 마음껏 이용해보세요!<br>' +
        '누적 DAU가 30,000 이하인 게임은 6개월 동안 무료 제공되니, 부담없는 가격에 이용해보세요!'
    },
    tblPrice: {
      caption: 'DAU 누적에 따른 Gamebase 요금'
    },
    thead: {
      tr: {
        th: ['과금 구분', '과금 구간', '과금 기준', '요금']
      }
    },
    tbody: {
      tr1: {
        th: ['DAU 누적', '0~200,000 이하', '월 정액 과금', '1,000,000원/월']
      },
      tr2: {
        th: ['200,000 초과', 'DAU당', 'DAU 당 5원']
      }
    },
    lstDot: {
      li: [
        '• 2023년 9월 1일 이후 프로젝트를 신규 생성하여 Gamebase를 이용하는 고객은 신규 요금제로만 서비스 이용이 가능합니다.',
        '• 2023년 9월 1일 이후 프로젝트를 신규 생성하여 Gamebase를 이용하는 고객에 한해 월 누적 DAU 30,000 이하는 6개월 동안 무료 이용이 가능합니다.',
      ]
    },
    lstDot2: {
      li: [
        '- 신규 생성한 프로젝트의 월 누적 DAU가 30,000을 초과하는 즉시 무료 이용 대상에서 제외되며, 해당 월부터 과금됩니다.<br>(DAU가 다시 30,000 이하가 되는 경우에도 무료 이용 대상에서 제외)',
        '- 무료 이용 기간은 신규 프로젝트 생성 일로부터 6개월 경과되는 달까지 적용됩니다.',
        '- 예시) 2023년 9월 12일에 신규로 활성화한 프로젝트는 2024년 2월까지 무료 이용이 가능합니다. 만약 2023년 11월에 월 누적 DAU가 30,000을 초과했다면 무료 이용은 종료되고, 2023년 11월부터 과금됩니다.',
      ]
    },
    lstDot3: {
      li: [
        '• 과금 기준은 이용 월의 DAU(Daily Active User)를 합산한 값입니다(참고: DAU 평균 = DAU누적/30일).',
        '• Gamebase 서비스 요금에는 AppGuard 서비스를 제외한 Push, IAP, Leaderboard 서비스의 이용 요금이 포함돼 있습니다.',
        '• 게임 출시전까지 기술지원 및 기술 교육이 무료로 지원되며, gameplatform@nhn.com 로 연락주시면 상담 가능합니다.'
      ]
    },
    tblPrice2: {
      caption: '기능에 따른 제공 범위와 요금',
      thead: {
        tr: {
          th: ['지원 구분', '기능', '제공범위', '요금']
        }
      },
      tbody: {
        tr1: {
          td: ['Gamebase 제공 기능 표', '(차등없이 제공)', 'Push(글로벌 포함)', '무제한', '무료']
        },
        tr2: {
          td: ['IAP/결제', '무제한', '무료']
        },
        tr3: {
          td: ['Leaderboard', '무제한', '무료']
        },
        tr4: {
          td: ['회원/인증', '무제한', '무료']
        },
        tr5: {
          td: ['쿠폰', '무제한', '무료']
        },
        tr6: {
          td: ['고객센터(FAQ, 약관, 메일)', '무제한', '무료']
        },
        tr7: {
          td: ['지표/통계', '무제한', '무료']
        },
        tr8: {
          td: ['모바일 앱', '무제한', '무료']
        },
        tr9: {
          td: ['기술지원', 'Gamebase 연동을 위한 기술 지원 및 컨설팅', '무제한', '무료']
        }
      }
    }
  },
  coverSimulator: {
    titSubSection: '요금 계산기 : 예상 요금을 미리 시뮬레이션 하실 수 있습니다.',
    groupSimulator1: {
      titSimulator: '누적 DAU',
      txtTf: 'DAU',
      btnSearch: '조회',
      btnRefresh: '초기화'
    },
    groupSimulator2: {
      titSimulator: '예상 요금',
      txtTf: '원/월',
      txtVat: 'VAT 별도'
    },
    lstDot: {
      li: [
        '• 2023년 9월 1일 이후 프로젝트를 신규 생성하여 Gamebase를 이용하는 고객은 신규 요금제로만 서비스 이용이 가능합니다.',
        '• 2023년 9월 1일 이후 프로젝트를 신규 생성하여 Gamebase를 이용하는 고객에 한해 월 누적 DAU 30,000 이하는 6개월 동안 무료 이용이 가능합니다.',
      ]
    },
    lstDot2: {
      li: [
        '- 신규 생성한 프로젝트의 월 누적 DAU가 30,000을 초과하는 즉시 무료 이용 대상에서 제외되며, 해당 월부터 과금됩니다.<br>(DAU가 다시 30,000 이하가 되는 경우에도 무료 이용 대상에서 제외)',
        '- 무료 이용 기간은 신규 프로젝트 생성 일로부터 6개월 경과되는 달까지 적용됩니다.',
        '- 예시) 2023년 9월 12일에 신규로 활성화한 프로젝트는 2024년 2월까지 무료 이용이 가능합니다. 만약 2023년 11월에 월 누적 DAU가 30,000을 초과했다면 무료 이용은 종료되고, 2023년 11월부터 과금됩니다.',
      ]
    },
    lstDot3: {
      li: [
        '• 과금 기준은 이용 월의 DAU(Daily Active User)를 합산한 값입니다(참고: DAU 평균 = DAU누적/30일).',
        '• Gamebase 서비스 요금에는 AppGuard 서비스를 제외한 Push, IAP, Leaderboard 서비스의 이용 요금이 포함돼 있습니다.',
        '• 게임 출시전까지 기술지원 및 기술 교육이 무료로 지원되며, gameplatform@nhn.com 로 연락주시면 상담 가능합니다.'
      ]
    },
  },
  coverSla: {
    titSubSection: 'SLA : Service Level Agreement',
    descSection:
      'Gamebase 서비스의 경우 회사의 책임 있는 사유로 Gamebase의 인증 기능을 이용하지 못하는 경우 손해배상 기준은 ' +
      '아래 표와 같습니다.',
    tblSla: {
      caption: 'Gamebase 서비스의 손해배상 기준',
      thead: {
        tr: {
          th: ['월 가용성', '손해배상금']
        }
      },
      tbody: {
        tr1: {
          th: ['99% 이상 ~ 99.9% 미만', '3개월 월 평균 사용 금액의 10%에 해당하는 금액']
        },
        tr2: {
          th: ['95% 이상 99% 미만', '3개월 월 평균 사용 금액의 25%에 해당하는 금액']
        },
        tr3: {
          th: ['95.0% 미만', '3개월 월 평균 사용 금액의 50%에 해당하는 금액']
        }
      }
    },
    lstDot: {
      li: [
        '※ 서비스 수준에 대한 측정 시간에 아래의 경우는 해당하지 않습니다.',
        '• 서비스 사용자와 서비스 제공자가 사전 협의한 Gamebase 가동 중단 시간',
        '• 시스템 소프트웨어 업데이트 시간, Gamebase 변경 작업이 계획된 시간 등 Gamebase 유지 보수를 위해 필요한 시간',
        '• 서비스 제공자가 통제할 수 없는 상황에 발생한 장애 시간',
        '• 서비스 사용자가 서비스 제공자에게 사전에 정보 시스템 변경 등 조치 계획을 통보하지 않고 발생한 장애 시간',
        '• OAuth 인증을 제공하는 외부 서비스 장애 등 서비스 제공자의 소관 업무 및 역할 범위 외에서 발생한 장애 시간',
        '• 장애의 소관 영역이 서비스 제공자인지 서비스 사용자인지 불명확한 장애 시간',
        '• 자연재해 및 이에 준한 국가비상사태로 인한 장애 시간 등'
      ]
    }
  },
  sectionService2: {
    titSection: 'Leaderboard 요금',
    thead: {
      tr: {
        th: ['과금 구분', '과금 구간', '과금 기준', '요금']
      }
    },
    tbody: {
      tr1: {
        td: ['API 호출', '100,000호출 이하', '무료 제공', '0원']
      },
      tr2: {
        td: ['10,000,000호출 이하', '1호출당', '0.1원']
      },
      tr3: {
        td: ['10,000,000호출 초과', '1호출당', '0.01원']
      }
    },
    lstDot: {
      li: [
        '• Gamebase 에서 활성화 한 경우, 이용요금은 무료 처리합니다.',
        '• Leaderdboard 만 단독으로 이용할 경우, 위 금액이 별도 부과됩니다.',
        '• API 호출 수는 월별 누적 사용량을 기준으로 이용 요금을 산정합니다.'
      ]
    }
  }
};
