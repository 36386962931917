import api from '@/helpers/api';
import { createUrl } from '@/helpers/store';

// 목록 요청
export function fetchExampleList(params) {
  return api({
    method: 'get',
    url: createUrl('example'),
    params
  });
}

// 등록
export function addExampleItem(data) {
  return api({
    method: 'post',
    url: createUrl('example'),
    data
  });
}

// 개별 삭제
export function deleteExampleItem(id) {
  return api({
    method: 'delete',
    url: createUrl(`example/${id}`)
  });
}

// 여러 아이템 삭제
export function deleteExampleList(data) {
  return api({
    method: 'delete',
    url: createUrl('example'),
    data
  });
}

// 업데이트
export function updateExampleItem(id, data) {
  return api({
    method: 'put',
    url: createUrl(`example/${id}`),
    data
  });
}
