import api from '@/helpers/api';
import { createUrl } from '@/helpers/store';

// 단건 요청
export function addContact(data) {
    return api({
        method: 'post',
        url: createUrl('ticket/'),
        data
    });
}