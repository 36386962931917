import header from './header';
import footer from './footer';
import menu from './menu';
import seo from './seo';
import code from './code';
import word from './word';
import button from './button';
import modal from './modal';
import message from './message';
import download from './view/download';
import customerStories from './view/customerStories';
import leaderboard from '././view/leaderboard';
import nhnCloud from './view/nhnCloud';
import gameanvil from './view/gameanvil';
import gamebase from './view/gamebase';
import notice from './view/notice';
import press from './view/press';
import smartDownloader from './view/smartDownloader';
import home from './view/home';
import newsLetter from './view/newsLetter';
import pricing from './view/pricing';
import contact from './view/contact';
import error from './view/error';
import gamebaseDemo from './view/gamebaseDemo';
import license from './view/license';
import partner from './view/partner';
import appGuard from './view/appGuard';
import logNCrash from './view/logNCrash';
import instance from './view/instance';

export default {
  seo,
  header,
  footer,
  menu,
  code,
  word,
  button,
  modal,
  message,
  view: {
    download,
    customerStories,
    leaderboard,
    nhnCloud,
    gameanvil,
    gamebase,
    notice,
    press,
    smartDownloader,
    home,
    newsLetter,
    contact,
    pricing,
    error,
    gamebaseDemo,
    license,
    partner,
    appGuard,
    logNCrash,
    instance
  }
};
