export default {
  titSection: '合作伙伴',
  descSection: '为提供最好的游戏平台服务，与专业的合作伙伴合作。',
  partnerInfo: {
    tit: 'Nettention - ProudNet',
    dsc:
      'Nettention开发的ProudNet，截至目前服务于191个游戏项目。\n并且在全球13个国家运营ProudNet服务器，\n与NHN Gamebase在平台营销和宣传方面进行合作。'
  },
  groupContact: {
    ico_call: '联系方式',
    ico_mail: '电子邮件',
    ico_home: '网站'
  },
  groupLink: {
    link_contact: 'Home',
    white: 'AWS Proudnet'
  }
};
