export default {
  sectionTop: {
    titTop: 'Pricing',
    emphTop: 'Gamebase provides practical services at reasonable prices.'
  },
  sectionService1: {
    titSection: 'Gamebase fee',
    coverPrice: {
      descSection:
        'Gamebase provides customers with one of the best game platforms without tiered pricing.<br>' +
        'Utilize all the game management functions such as game index, push notification, customer center, coupon, refund abuse, ranking, etc.!<br>' +
        'Games with a cumulative DAU of 30,000 or less are provided free for 6 months, so try them at an affordable price!'
    },
    tblPrice: {
      caption: 'Gamebase fee according to accumulated DAU'
    },
    thead: {
      tr: {
        th: ['Pricing group', 'Pricing band', 'Pricing criteria', 'Fee']
      }
    },
    tbody: {
      tr1: {
        th: ['Cumulative DAU<br>(daily active user)','200,000 or less', 'Monthly paid subscription', '1,000,000 KRW/month']
      },
      tr2: {
        th: ['More than 200,000', 'Per DAU', '5 KRW']
      }
    },
    lstDot: {
      li: [
        '• Customers who create a new project after September 1, 2023, and use Gamebase can use the service only with the new pricing plan.',
        '• Only customers who create a new project after September 1, 2023, and use Gamebase can use it for free for 6 months with monthly cumulative DAU of 30,000 or less.'
      ]
    },
    lstDot2: {
      li: [
        '- As soon as the monthly cumulative DAU of a newly created project exceeds 30,000, you will be excluded from the free use and will be charged from that month.<br>(even if the DAU falls below 30,000 again, you are still excluded from the free use)',
        '- The free use period applies for 6 months from the month including the new project creation date.',
        '- Example) Newly activated projects on September 12, 2023, are available for free until February 2024. If the monthly cumulative DAU exceeds 30,000 in November 2023, the free use will end, and you will be charged from November 2023.'
      ]
    },
    lstDot3: {
      li: [
        '• Billing is the sum of the DAU of the month of use (Note: DAU average = cumulative DAU / 30 days).',
        '• The Gamebase service fee includes the service fees for Push, IAP, and Leaderboard except for the NHN AppGuard service.',
        '• Technical support and technical training are provided free of charge until the game is released, and  if you contact with gameplatform@nhn.com.'
      ]
    },
    tblPrice2: {
      caption: 'Provided functions and fee per feature',
      thead: {
        tr: {
          th: ['support group', 'feature', 'provision range', 'fee']
        }
      },
      tbody: {
        tr1: {
          td: ['Gamebase feature chart', '(provided without tiers)', 'Push (including global)', 'Unlimited', 'Free']
        },
        tr2: {
          td: ['IAP/Payment', 'Unlimited', 'Free']
        },
        tr3: {
          td: ['Leaderboard', 'Unlimited', 'Free']
        },
        tr4: {
          td: ['Member/Verification', 'Unlimited', 'Free']
        },
        tr5: {
          td: ['Coupon', 'Unlimited', 'Free']
        },
        tr6: {
          td: ['Center (FAQ, Terms and Conditions, Email)', 'Unlimited', 'Free']
        },
        tr7: {
          td: ['Indexes/statistics', 'Unlimited', 'Free']
        },
        tr8: {
          td: ['Mobile app', 'Unlimited', 'Free']
        },
        tr9: {
          td: ['Technical support', 'Technical support and consulting for Gamebase integration', 'Unlimited', 'Free']
        }
      }
    }
  },
  coverSimulator: {
    titSubSection: 'Fee calculator: Run a simulation for the expected amount of fee in advance.',
    groupSimulator1: {
      titSimulator: 'Estimated DAU (Monthly accumulation)',
      txtTf: 'DAU',
      btnSearch: 'View',
      btnRefresh: 'Reset'
    },
    groupSimulator2: {
      titSimulator: 'Estimated fee (Monthly)',
      txtTf: 'KRW',
      txtVat: 'VAT excluded'
    },
    lstDot: {
      li: [
        '• Customers who create a new project after September 1, 2023, and use Gamebase can use the service only with the new pricing plan.',
        '• Only customers who create a new project after September 1, 2023, and use Gamebase can use it for free for 6 months with monthly cumulative DAU of 30,000 or less.'
      ]
    },
    lstDot2: {
      li: [
        '- As soon as the monthly cumulative DAU of a newly created project exceeds 30,000, you will be excluded from the free use and will be charged from that month.<br>(even if the DAU falls below 30,000 again, you are still excluded from the free use)',
        '- The free use period applies for 6 months from the month including the new project creation date.',
        '- Example) Newly activated projects on September 12, 2023, are available for free until February 2024. If the monthly cumulative DAU exceeds 30,000 in November 2023, the free use will end, and you will be charged from November 2023.'
      ]
    },
    lstDot3: {
      li: [
        '• Billing is the sum of the DAU of the month of use (Note: DAU average = cumulative DAU / 30 days).',
        '• The Gamebase service fee includes the service fees for Push, IAP, and Leaderboard except for the NHN AppGuard service.',
        '• Technical support and technical training are provided free of charge until the game is released, and  if you contact with gameplatform@nhn.com.'
      ]
    },
  },
  coverSla: {
    titSubSection: 'SLA: Service Level Agreement',
    descSection:
      'If Gamebase’s verification feature is unavailable due to a cause attributable to NHN, the indemnity criteria is' +
      'as follows:',
    tblSla: {
      caption: 'Indemnity criteria for Gamebase service',
      thead: {
        tr: {
          th: ['Monthly availability', 'Indemnity']
        }
      },
      tbody: {
        tr1: {
          th: ['99% - less than 99.9%', '10% of the average monthly amount spent over 3 months']
        },
        tr2: {
          th: ['95% - less than 99%', '25% of the average monthly amount spent over 3 months']
        },
        tr3: {
          th: ['Less than 95.0%', '50% of the average monthly amount spent over 3 months']
        }
      }
    },
    lstDot: {
      li: [
        '※ Service level is not measured in the following cases:',
        '• Gamebase downtime agreed in advance between the service user and provider',
        '• Time necessary for Gamebase maintenance including system software updates, scheduled Gamebase changes, etc.',
        "• Occurrence of failure in situations out of the service provider's control",
        "• Occurrence of failure due to the service user's failure to notify the provider of plans such as information system changes in advance",
        "• Occurrence of failure which is outside the service provider's scope of business and role such as failure of external OAuth verification service",
        '• Occurrence of failure of which it is unclear whether the cause lies on the service provider or user',
        '• Occurrence of failure due to natural disasters and corresponding national emergencies, etc.'
      ]
    }
  },
  sectionService2: {
    titSection: 'Leaderboard fee',
    thead: {
      tr: {
        th: ['Pricing group', 'Pricing band', 'Pricing criteria', 'Fee']
      }
    },
    tbody: {
      tr1: {
        td: ['API call', '100,000 calls or less', 'Free', 'KRW 0']
      },
      tr2: {
        td: ['10,000,000 calls or less', 'Per call', 'KRW 0.1']
      },
      tr3: {
        td: ['More than 10,000,000', 'Per call', 'KRW 0.01']
      }
    },
    lstDot: {
      li: [
        '• If enabled in Gamebase, no fee will be charged.',
        '• When using the leaderboard alone, the amount above will be charged separately.',
        '• The usage fee for the number of API calls is calculated based on the monthly accumulated usage.'
      ]
    }
  }
};
