import { lazyLayoutLoad, lazyViewLoad } from '@/helpers/router';

export default {
  path: '/:countryCode/noticeNews',
  component: lazyLayoutLoad('NoticeNewsLayout'),
  redirect: '/:countryCode/noticeNews/notice',
  children: [
    {
      path: 'notice',
      component: lazyViewLoad('noticeNews/notice/List'),
      meta: { contentClass: 'cont_notice', seo: 'seo.noticeNews.notice' }
    },
    {
      path: 'notice/:noticeId',
      component: lazyViewLoad('noticeNews/notice/View'),
      meta: { contentClass: 'cont_notice', seo: 'seo.noticeNews.notice' }
    },
    {
      path: 'newsLetter',
      component: lazyViewLoad('noticeNews/newsLetter/List'),
      meta: { contentClass: 'cont_newsletter', seo: 'seo.noticeNews.newsLetter' }
    },
    {
      path: 'newsLetter/:id',
      component: lazyViewLoad('noticeNews/newsLetter/View'),
      meta: { contentClass: 'cont_newsletter', seo: 'seo.noticeNews.newsLetter' }
    },
    {
      path: 'press',
      component: lazyViewLoad('noticeNews/press/List'),
      meta: { contentClass: 'cont_notice', seo: 'seo.noticeNews.press' }
    },
    {
      path: 'press/:noticeId',
      component: lazyViewLoad('noticeNews/press/View'),
      meta: { contentClass: 'cont_notice', seo: 'seo.noticeNews.press' }
    },
    {
      path: 'promotion',
      component: lazyViewLoad('noticeNews/Promotion'),
      meta: { contentClass: 'cont_promo', seo: 'seo.noticeNews.promotion' }
    }
  ]
};
