export default {
  // 공통 메타 데이터
  baseMetaData: {
    keywords: 'NHN GamePlatform'
  },
  // 홈
  home: {
    title: 'NHN GamePlatform',
    description: 'Home english!!!!!!! descritpion',
    keywords:
      'Game, TOAST Game, Gamebase, TOAST Gamebase, Game Platform, Game Platforms, Gaming Platform, Quthentication, DAU, IAP, Game Indicator, Game Analytics, Game operation tool, Game Push, Oauth, Android authenticaction, iOS authentication, Appstore in app purchase, Google play in app purchase, mobile platform, mobile game platorm, mobile game development,NHN Game,NHN Game Platform, AWS gamebase'
  },
  // 서비스 카테고리
  service: {
    // Gamebase
    gamebase: {
      title: 'Gamebase',
      description: 'Game platform provided by NHN. Gamebase, Leaderboard,Smart Downloader, Launching',
      keywords:
        'Game, TOAST Game, Gamebase, TOAST Gamebase, Game Platform, Game Platforms, Gaming Platform, Quthentication, DAU, IAP, Game Indicator, Game Analytics, Game operation tool, Game Push, Oauth, Android authenticaction, iOS authentication, Appstore in app purchase, Google play in app purchase, mobile platform, mobile game platorm, mobile game development,NHN Game,NHN Game Platform, AWS gamebase'
    },
    gamebaseDemo: {
      main: {
        title: 'NHN GamePlatform',
        description: 'gamebaseDemo main',
        keywords: 'gamebaseDemo main'
      },
      done: {
        title: 'NHN GamePlatform',
        description: 'gamebaseDemo done',
        keywords: 'gamebaseDemo done'
      }
    },
    // Smart downloader
    smartDownloader: {
      title: 'Smart Downloader',
      description: 'Game platform provided by NHN. Gamebase, Leaderboard,Smart Downloader, Launching',
      keywords:
        'Smart Downloader, TOAST Smart Downloader, Game Downloads, Resource Downloads, Multi threaded Downloads, CDN, Smart Dowloader CDN, Download Acceleration, PAtch Acceleration, Mobile Global Distribution'
    },
    // Leader board
    leaderboard: {
      title: 'Leaderboard',
      description: 'Game platform provided by NHN. Gamebase, Leaderboard,Smart Downloader, Launching',
      keywords:
        'Leaderboard, TOAST Leaderboard, Game ranking, ranking service, Factor, Daily Ranking, Weekly Ranking, Monthly Ranking, Total Ranking, Redis Ranking, Redis Cluster'
    },
    // GameAnvil
    gameAnvil: {
      title: 'NHN GamePlatform',
      description: 'gameAnvil 한국어 descritpion',
      keywords: 'gameAnvil 한국어 keywords'
    },
    // NHN Cloud
    nhnCloud: {
      title: 'NHN GamePlatform',
      description: 'nhnCloud 한국어 descritpion',
      keywords: 'nhnCloud 한국어 keywords'
    },
    // appGuard
    appGuard: {
      title: 'NHN GamePlatform',
      description: 'appGuard 한국어 descritpion',
      keywords: 'appGuard 한국어 keywords'
    },
    // logNCrash
    logNCrash: {
      title: 'NHN GamePlatform',
      description: 'logNCrash 한국어 descritpion',
      keywords: 'logNCrash 한국어 keywords'
    },
    // instance
    instance: {
      title: 'NHN GamePlatform',
      description: 'instance 한국어 descritpion',
      keywords: 'instance 한국어 keywords'
    },
    // Pricing
    pricing: {
      title: 'NHN GamePlatform',
      description: 'pricing 한국어 descritpion',
      keywords: 'pricing 한국어 keywords'
    }
  },
  // 파트너
  partner: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'partner 한국어 descritpion',
      keywords: 'partner 한국어 keywords'
    }
  },
  // 고객 사례 카테고리
  customerStories: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'customerStories 한국어 descritpion',
      keywords: 'customerStories 한국어 keywords'
    }
  },
  // 다운로드 카테고리
  download: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'download 한국어 descritpion',
      keywords: 'download 한국어 keywords'
    }
  },
  // 공지소식 카테고리
  noticeNews: {
    // 공지사항
    notice: {
      title: 'NHN GamePlatform',
      description: 'noticeNews 한국어 descritpion',
      keywords: 'noticeNews 한국어 keywords'
    },
    // 뉴스레터 모음
    newsLetter: {
      title: 'NHN GamePlatform',
      description: 'newsLetter 한국어 descritpion',
      keywords: 'newsLetter 한국어 keywords'
    },
    // 보도자료
    press: {
      title: 'NHN GamePlatform',
      description: 'press 한국어 descritpion',
      keywords: 'press 한국어 keywords'
    },
    // 프로모션
    promotion: {
      title: 'NHN GamePlatform',
      description: 'promotion 한국어 descritpion',
      keywords: 'promotion 한국어 keywords'
    }
  },
  contact: {
    main: {
      title: 'NHN GamePlatform',
      description: 'contect 한국어 descritpion',
      keywords: 'contact 한국어 keywords'
    },
    done: {
      title: 'NHN GamePlatform',
      description: 'contact done',
      keywords: 'contact done'
    }
  }
};
