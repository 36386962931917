export default {
  titTop: 'Leaderboard',
  emphTop: 'ゲームを連動させるだけで簡単に実現！',
  descTop:
    'LeaderboardはNHNが独自に提供するゲームランキング専用サービスで、SDK連動を必要とせず、コンソール設定とゲーム連動だけで簡単にランキングサービスを実現することができます。',
  txtService1: 'ランキング登録',
  txtService2: 'ランキング照会',
  txtInfoRealtime: 'リアルタイム大容量ランキングを迅速かつ安定的にサポートします。',
  txtInfoRankingSort: '様々な基準のランキング作成が可能です。',
  txtInfoSortManage:
    'コンソールを通じて、ランキング周期/ランキングアップデート基準/ランキングソート基準 を手軽に管理することができます。',
  txtInfoTemplate: 'テンプレートで手軽にランキングを作成できます。',
  linkEnd: 'Leaderboard製品相談',
  icoArrowForwardW: 'リンク',
  sectionService1: {
    imgAlt: 'Leaderboard Ranking Server'
  }
};
