export default {
  coverTitSection: {
    titSection: 'Download Game Platform',
    descSection:
      'You can also download the same contents at <b><a href="https://docs.toast.com/ko/Download/#game-gamebase" target="_blank">NHN Cloud > User Guide > Downloads.',
    lstDotList: [
      '• Appropriate download setting guides, up-to-date SDK, and sample apps are provided for each platform.',
      '• For Unity, UnitySDK and NativeSDK are both available for download and installation at Setting Tool.',
      '• For Android, SDK can be added to projects with Gradle setting. (The latest version applied automatically)',
      '• For iOS, SDK can be added to projects with CocoaPods setting. (The latest version applied automatically)',
      '• Moreover, you can also use manual downloads and sample apps for easy applications and tests to suit the platform environments.'
    ]
  },
  top: {
    service: [
      {
        title: 'Unity',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unity',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unity Setting Tool',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSettingTool.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.unity.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ]
      },
      {
        title: 'Unreal',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unreal',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unreal SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-Unreal.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      },
      {
        title: 'Android',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_android',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Gradle Setting Guide',
            href: 'https://docs.toast.com/en/Game/Gamebase/en/aos-started/#setting',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.android.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      },
      {
        title: 'iOS',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_apple',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'CocoaPods',
            href: 'https://docs.toast.com/en/Game/Gamebase/en/ios-started/',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'iOS SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-iOS.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.ios.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      }
      // {
      //   title: 'Old versions of SDK',
      //   titleStrongClass: 'tit_download',
      //   titleSpanClass: '',
      //   ulClass: 'lst_download',
      //   downloadList: [
      //     {
      //       title: 'Old versions of SDK',
      //       href: 'https://docs.toast.com/ko/Download/#game-gamebase',
      //       hrefClass: 'link_download link_launch',
      //       titleSpanClass: 'ico_game ico_launch'
      //     }
      //   ]
      // }
    ]
  },
  bottom: {
    service: [
      {
        title: 'Smart Downloader',
        downloadList: [
          {
            title: 'Unity SDK',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/Smart-downloader-1.6.8.unitypackage'
          },
          {
            title: 'Jenkins Plugin',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/smartdl-uploader.hpi'
          },
          {
            title: 'Smart Downloader Unity Tool(SUT)',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/SUT/SmartDownloaderUnityTool.zip'
          }
        ]
      },
      {
        title: 'NHN Cloud',
        downloadList: [
          { title: 'PUSH', href: 'https://docs.toast.com/en/Notification/Push/en/Overview/' },
          {
            title: 'Log & Crash Search',
            href: 'https://docs.toast.com/en/Analytics/Log%20&%20Crash%20Search/en/Overview/'
          },
          { title: 'more NHN Cloud Services', href: 'https://docs.toast.com/en/Download/#toast-sdk' }
        ]
      }
    ]
  }
};
