import { lazyLayoutLoad, lazyViewLoad } from '@/helpers/router';

export default {
  path: '/:countryCode/service',
  component: lazyLayoutLoad('ServiceLayout'),
  redirect: '/:countryCode/service/gamebase',
  children: [
    {
      path: 'gamebase',
      component: lazyViewLoad('service/Gamebase'),
      meta: { contentClass: 'cont_gamebase', seo: 'seo.service.gamebase' }
    },
    {
      path: 'gamebaseDemo/main',
      component: lazyViewLoad('service/GamebaseDemo/Main'),
      meta: { contentClass: 'cont_gamebase_demo', seo: 'seo.service.gamebaseDemo.main' }
    },
    {
      name: 'gamebaseDemo/done',
      path: 'gamebaseDemo/done',
      component: lazyViewLoad('service/GamebaseDemo/Done'),
      meta: { contentClass: 'cont_gamebase_demo', seo: 'seo.service.gamebaseDemo.done' },
      props: true
    },
    {
      path: 'smartDownloader',
      component: lazyViewLoad('service/SmartDownloader'),
      meta: { contentClass: 'cont_smart', seo: 'seo.service.smartDownloader' }
    },
    {
      path: 'leaderboard',
      component: lazyViewLoad('service/Leaderboard'),
      meta: { contentClass: 'cont_leader', seo: 'seo.service.leaderboard' }
    },
    {
      path: 'gameAnvil',
      component: lazyViewLoad('service/GameAnvil'),
      meta: { contentClass: 'cont_gameanvil', seo: 'seo.service.gameAnvil' }
    },
    {
      path: 'nhnCloud',
      component: lazyViewLoad('service/NHNCloud'),
      meta: { contentClass: 'cont_toast', seo: 'seo.service.nhnCloud' }
    },
    {
      path: 'appGuard',
      component: lazyViewLoad('service/AppGuard'),
      meta: { contentClass: 'cont_app_guard', seo: 'seo.service.appGuard' }
    },
    {
      path: 'logNCrash',
      component: lazyViewLoad('service/LogNCrash'),
      meta: { contentClass: 'cont_log_crash', seo: 'seo.service.logNCrash' }
    },
    {
      path: 'instance',
      component: lazyViewLoad('service/Instance'),
      meta: { contentClass: 'cont_instance', seo: 'seo.service.instance' }
    },
    {
      path: 'pricing',
      component: lazyViewLoad('service/Pricing'),
      meta: { contentClass: 'cont_pricing', seo: 'seo.service.pricing' }
    }
  ]
};
