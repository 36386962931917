import { lazyLayoutLoad, lazyViewLoad } from '@/helpers/router';

export default {
  path: '/:countryCode/customerStories',
  component: lazyLayoutLoad('CustomerStoriesLayout'),
  redirect: '/:countryCode/customerStories/main',
  children: [
    {
      path: 'main',
      component: lazyViewLoad('customerStories/Main'),
      meta: { contentClass: 'cont_customer', seo: 'seo.customerStories.main' }
    }
  ]
};
