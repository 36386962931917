import * as EXAMPLE_REPOSITORIES from '@/store/repositories/example';

export default {
  // 목록 가져오기
  fetchExampleList: async ({ commit }, payload) => {
    const res = await EXAMPLE_REPOSITORIES.fetchExampleList(payload);

    const { header, data } = res;
    if (header.isSuccessful) {
      commit('setExampleList', data);

      return res;
    }

    return null;
  },
  // 단일 아이템 추가
  addExampleItem: async ({ commit }, payload) => {
    const res = await EXAMPLE_REPOSITORIES.addExampleItem(payload);
    const { header } = res;

    if (header.isSuccessful) {
      res.data = {
        id: Date.now(),
        title: payload.title,
        done: false,
        timestamp: Date.now()
      };
      commit('addExampleItem', res.data);

      return res;
    }

    return null;
  },
  // 아이템 수정
  updateExampleItem: async ({ commit }, params) => {
    const { id, payload } = params;
    const res = await EXAMPLE_REPOSITORIES.updateExampleItem(id, payload);
    const { header } = res;

    if (header.isSuccessful) {
      res.data = {
        ...payload
      };
      commit('updateExampleItem', res.data);
    }

    return null;
  },
  // 단일 아이템 삭제
  deleteExampleItem: async ({ commit }, id) => {
    const res = await EXAMPLE_REPOSITORIES.deleteExampleItem(id);
    const { header } = res;

    if (header.isSuccessful) {
      commit('deleteExampleItem', id);

      return res;
    }

    return null;
  },
  // 여러 아이템 삭제
  deleteExampleList: async ({ commit }, payload) => {
    const res = await EXAMPLE_REPOSITORIES.deleteExampleList(payload);
    const { header } = res;

    if (header.isSuccessful) {
      // 예시를 위한 삭제 후 목록은 컴포넌트 레벨에서 요청하여 동기화 시킨다.
      commit('deleteExampleList', payload.ids);

      return res;
    }

    return null;
  }
};
