import * as PRESS_REPOSITORIES from '@/store/repositories/press';

export default {
  // 목록 가져오기
  // eslint-disable-next-line no-unused-vars
  fetchPressList: async ({ commit }, payload) => {
    const res = await PRESS_REPOSITORIES.fetchPressList(payload);

    const { header } = res;
    if (header.isSuccessful) {
      return res;
    }

    return null;
  },

  // 단건 가져오기
  // eslint-disable-next-line no-unused-vars
  fetchPressOne: async ({ commit }, payload) => {
    const res = await PRESS_REPOSITORIES.fetchPressOne(payload);

    const { header } = res;
    if (header.isSuccessful) {
      return res.result.content;
    }

    return null;
  }
};
