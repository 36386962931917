export default {
  // 공통 메타 데이터
  baseMetaData: {
    keywords: 'NHN GamePlatform'
  },
  // 홈
  // <!-- 2020-12-29 타이틀 문구 수정 -->
  home: {
    title: 'NHN GamePlatform',
    description: 'NHN에서 제공하는 게임 플랫폼 Gamebase, Leaderboard,Smart Downloader, Launching',
    keywords:
      '게임, 토스트 게임, 게임베이스, 토스트 게임베이스, 게임플랫폼, 게임플랫폼 로그인 인증, 게임 지표, 게임 분석, 게임 운영툴, 게임 푸시, 외부 인증, 안드로이드 인증, iOS 인증, iOS 인앱 결제, Android 인앱결제, 모바일 플랫폼, 모바일 게임 플랫폼, 모바일 게임 개발,NHN게임,NHN 게임플랫폼, AWS 게임베이스, AWS gamebase'
  },
  // 서비스 카테고리
  service: {
    // Gamebase
    gamebase: {
      title: '게임베이스',
      description: 'NHN에서 제공하는 게임 플랫폼 Gamebase, Leaderboard,Smart Downloader, Launching',
      keywords:
        '게임, 토스트 게임, 게임베이스, 토스트 게임베이스, 게임플랫폼, 게임플랫폼 로그인 인증, 게임 지표, 게임 분석, 게임 운영툴, 게임 푸시, 외부 인증, 안드로이드 인증, iOS 인증, iOS 인앱 결제, Android 인앱결제, 모바일 플랫폼, 모바일 게임 플랫폼, 모바일 게임 개발,NHN게임,NHN 게임플랫폼, AWS 게임베이스, AWS gamebase'
    },
    gamebaseDemo: {
      main: {
        title: 'NHN GamePlatform',
        description: 'gamebaseDemo main',
        keywords: 'gamebaseDemo main'
      },
      done: {
        title: 'NHN GamePlatform',
        description: 'gamebaseDemo done',
        keywords: 'gamebaseDemo done'
      }
    },
    // Smart downloader
    smartDownloader: {
      title: '스마트다운로더',
      description: 'NHN에서 제공하는 게임 플랫폼 Gamebase, Leaderboard,Smart Downloader, Launching',
      keywords:
        '스마트 다운로더, 토스트 스마트 다운로더, 게임 다운도르, 리소스 다운로드, 멀티 스레드 다운로드, CDN, 스마트 다운로더 CDN, 다운로드 가속화, 패치 가속화, 모방리 글로벌 배포'
    },
    // Leader board
    leaderboard: {
      title: '리더보드',
      description: 'NHN에서 제공하는 게임 플랫폼 Gamebase, Leaderboard,Smart Downloader, Launching',
      keywords:
        '리더보드, 토스트 리더보드, 게임 랭킹, 랭킹서비스, 팩터, 일간 랭킹, 주간 랭킹, 전체 랭킹, Redis 랭킹, Redis 클러스터'
    },
    // GameAnvil
    gameAnvil: {
      title: 'NHN GamePlatform',
      description: 'gameAnvil 한국어 descritpion',
      keywords: 'gameAnvil 한국어 keywords'
    },
    // NHN Cloud
    nhnCloud: {
      title: 'NHN GamePlatform',
      description: 'nhnCloud 한국어 descritpion',
      keywords: 'nhnCloud 한국어 keywords'
    },
    // appGuard
    appGuard: {
      title: 'NHN GamePlatform',
      description: 'appGuard 한국어 descritpion',
      keywords: 'appGuard 한국어 keywords'
    },
    // logNCrash
    logNCrash: {
      title: 'NHN GamePlatform',
      description: 'logNCrash 한국어 descritpion',
      keywords: 'logNCrash 한국어 keywords'
    },
    // instance
    instance: {
      title: 'NHN GamePlatform',
      description: 'instance 한국어 descritpion',
      keywords: 'instance 한국어 keywords'
    },
    // Pricing
    pricing: {
      title: 'NHN GamePlatform',
      description: 'pricing 한국어 descritpion',
      keywords: 'pricing 한국어 keywords'
    }
  },
  // 파트너
  partner: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'partner 한국어 descritpion',
      keywords: 'partner 한국어 keywords'
    }
  },
  // 고객 사례 카테고리
  customerStories: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'customerStories 한국어 descritpion',
      keywords: 'customerStories 한국어 keywords'
    }
  },
  // 다운로드 카테고리
  download: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'download 한국어 descritpion',
      keywords: 'download 한국어 keywords'
    }
  },
  // 공지소식 카테고리
  noticeNews: {
    // 공지사항
    notice: {
      title: 'NHN GamePlatform',
      description: 'noticeNews 한국어 descritpion',
      keywords: 'noticeNews 한국어 keywords'
    },
    // 뉴스레터 모음
    newsLetter: {
      title: 'NHN GamePlatform',
      description: 'newsLetter 한국어 descritpion',
      keywords: 'newsLetter 한국어 keywords'
    },
    // 보도자료
    press: {
      title: 'NHN GamePlatform',
      description: 'press 한국어 descritpion',
      keywords: 'press 한국어 keywords'
    },
    // 프로모션
    promotion: {
      title: 'NHN GamePlatform',
      description: 'promotion 한국어 descritpion',
      keywords: 'promotion 한국어 keywords'
    }
  },
  contact: {
    main: {
      title: 'NHN GamePlatform',
      description: 'contact 한국어 descritpion',
      keywords: 'contact 한국어 keywords'
    },
    done: {
      title: 'NHN GamePlatform',
      description: 'contact done',
      keywords: 'contact done'
    }
  }
};
