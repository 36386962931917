import api from '@/helpers/api';
import { createUrl } from '@/helpers/store';

// 목록 요청
export function fetchPressList(params) {
  return api({
    method: 'get',
    url: createUrl(`notice`),
    params
  });
}

// 단건 요청
export function fetchPressOne(params) {
  return api({
    method: 'get',
    url: createUrl(`notice/${params}`)
  });
}
