export default {
  titTop: 'Leaderboard',
  emphTop: '仅通过游戏联动简单实现！',
  descTop:
    'Leaderboard是仅由NHN提供的游戏排名专用服务， 无需单独接入SDK，仅通过控制台设置和游戏联动即可简单实现排名服务',
  txtService1: '生成排名(分数)',
  txtService2: '排名查询',
  txtInfoRealtime: '快速稳定地支持实时/大容量排名',
  txtInfoRankingSort: '可以生成各种规则的排名',
  txtInfoSortManage: '通过控制台可方便管理排名周期/排名更新规则/排名排序规则',
  txtInfoTemplate: '通过模板可以轻松生成排名',
  linkEnd: 'Leaderboard产品咨询',
  icoArrowForwardW: '快捷方式',
  sectionService1: {
    imgAlt: 'Leaderboard Ranking Server'
  }
};
