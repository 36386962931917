export default {
  emphTop: 'ゲーム開発に特化したGamebaseの主な機能、運営とコンテンツ分析を行うための多角的な指標などを体験できます。',
  linkShortcut: 'デモプロジェクト <span class="ico_game ico_shortcut"></span>',
  sectionServicetit: 'デモプロジェクトアカウント申請',
  sectionServiceDesc:
    '名前、電子メールアドレス、会社名、IDをお伝えください。確認後、Gamebaseデモバージョンのプロジェクトアカウントを提供します。',
  contactName: '名前',
  contactMail: 'メール',
  mailTxtAttention: 'パスワードを変更するためのメールを送信します。',
  contactCompany: '会社名',
  agree1: '(必須)満14歳以上です。',
  agree2:
    '<span class="ico_game ico_check"></span>(必須) Gamebaseデモアカウントを申請するために名前、メールアドレス、会社名、IDを収集し、<em class="emph_g">収集日から30日間保管されます。</em><br class="view_pc" /> 同意を拒否することもできますが、同意しない場合はサービスの利用が制限されることがあります。',
  // done
  titDemoDone: 'Gamebaseデモ申請が<br />正常に完了しました。',
  descDemoDone:
    '入力したメール (hong_gildong@nhn.com)から<br />' +
    '「パスワード変更後 <em class="emph_primary">Console</em> にログインできます。」',
  mailSandApiError: 'メールの送信に失敗しました。',
  alreadyJoinError: 'すでに加入済のIDです。'
};
