export default {
  requiredNamePhone: 'Name, contact information, and email required.',
  requiredAgree:
    'You must agree to the personal information collection and usage to write an inquiry. Please agree to continue.',
  contactTitle: 'Change Inquiry Type',
  contactContent: 'Keep the entered inquiry?',
  cancle: 'Cancel the consultation/inquiry submission?',
  regexError: 'Invalid information in the required field. Please check again.',
  demoRequiredInput: 'Name, ID, email, and company name are required.',
  demoRequiredAgree:
    'You must agree to the personal information collection and usage to be able to apply for a demo project account. Please agree before applying for it.',
  demoCancleTitle: 'Cancel demo project account application',
  demoCancleContent: 'Do you want to cancel your demo project account application?'
};
