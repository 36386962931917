export default {
  titMain: "真正打造'游戏玩家'的游戏平台",
  emphMain1: 'NHN',
  emphMain2: '游戏平台',
  utilBtn: {
    lightmode: 'Light Mode',
    darkmode: 'Dark Mode'
  },
  lstCategory: [
    {
      strongText: 'Gamebase',
      pText: '集成SDK，提供登录、支付、推送、通知、维护等通用功能的NO.1游戏平台',
      spanText: '快捷方式'
    },
    {
      strongText: 'Smart Downloader',
      pText: '提供可执行文件、图片等游戏资源的快速下载和压倒性的成功率',
      spanText: '快捷方式'
    },
    {
      strongText: 'Leaderboard',
      pText: '实时排名服务，即使在大规模排名流量下也能稳定运营',
      spanText: '快捷方式'
    },
    {
      strongText: 'GameAnvil',
      pText: '멀티 대전 게임 서버의 개발 및 서버 운영까지 가능한 통합 솔루션',
      spanText: '快捷方式'
    }
  ],
  titNotice: 'Notice',
  titNews: 'News'
};
