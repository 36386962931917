export default {
  titTop: 'App Guard',
  emphTop: '강력한 모바일 보안!',
  descTop: 'AppGuard는 각종 어뷰징으로부터 안전하게 앱을 보호할<br class="view_m"> 수 있는<br class="view_pc">모바일 앱 보안 솔루션입니다.',
  sectionService: [
    {
      titSection: '특징',
      descSection:
        '단순한 치팅 툴 탐지 기능 뿐 아니라, 디컴파일 방지와 무결성 검증 등의 강력한 기능을 포함하고 있으며, 사용자 편의를 위한 간편한 적용 방법을 제공합니다.'
    }
  ],
  lstAppguard: [
    {
      emText: '편리한 웹콘솔',
      spanText: '로그 분석 콘솔 제공<br>실시간 정책 설정 가능'
    },
    {
      emText: '강력한 보안',
      spanText: '디컴파일 방지, 코드 암호화<br>파일 무결성 검증'
    },
    {
      emText: '간편한 적용',
      spanText: '개발 도구에 통합하여 적용 가능<br>웹콘솔, CLI, Unity3D 에디터 확장'
    }
  ],
  appguardGuide: {
    titSubSection: '기능',
    lstStep: [
      {
        emSpanText: 'Grade 1',
        lstStep2: [
          {
            strongText: '해킹 툴 탐지',
            lstStep3: ['치팅 및 매크로 툴 탐지/차단']
          }
        ]
      },
      {
        emSpanText: 'Grade 2',
        lstStep2: [
          {
            strongText: 'OS 위변조 방지',
            lstStep3: ['OS루팅 환경 탐지/차단', '에뮬레이터 환경 탐지/차단']
          },
          {
            strongText: '코드 위변조 방어',
            spanText: '(무결성 체크)',
            lstStep3: [
              '암호화 API (변조앱 탐지)',
              '서명 변조 탐지/차단',
              '매니페스트 변조 탐지/차단',
              '주요 모듈 및 자바 코드 (DEX) 변조 탐지/차단'
            ]
          },
          {
            strongText: '정적 분석 방어',
            lstStep3: ['자바 코드 (DEX) 암호화', '게임 실행 파일 암호화(cocos2d-x, Uniti mono, Unreal Engine)']
          }
        ]
      },
      {
        emSpanText: 'Grade 3',
        lstStep2: [
          { strongText: '자체 보호', lstStep3: ['AppGuard 내 중요 코드 보호(코드 난독화)'] },
          {
            strongText: '동적 분석 방어',
            lstStep3: ['Unitiy iL2CPP 코드 분석 방어', 'AppGuard 코드 가상화', '.so 동적 라이브러리 보호']
          },
          {
            strongText: '행위 기반 매크로 탐지',
            lstStep3: ['게임 내 터치 좌표 분석을 통한 기계 학습 기반 매크로 사용자 검출']
          }
        ]
      }
    ]
  },
  appguardFlow: {
    titSubSection: 'AppGuard 사용 Flow',
    txtAppguardFlow1: '탐지 로그 전송',
    txtAppguardFlow2: '탐지 로그 전송',
    txtAppguardFlow3: '탐지 로그 검색',
    descSection:
      '패턴에 의한 탐지와 휴리스틱 혹은 치팅 툴의 동작 방식에 의한 행위 기반 탐지가 복합적으로 동작하여 탐지 우회가 어렵습니다.',
    lstStep: [
      {
        emSpanText: '방 어',
        lstStep2: [
          {
            strongText: '리패키징 방어 <br class="noneview_m"> 코드 조작 방어',
            lstStep3: [
              '디컴파일 방지 지원',
              '게임 코드 암호화 (Unity 3D mono, Unity 3D il2cpp, Cocos2d-x) 및 Java 코드 암호화 (DEX) 지원 '
            ]
          }
        ]
      },
      {
        emSpanText: '탐 지',
        lstStep2: [
          {
            strongText: '패턴',
            lstStep3: ['치팅 툴, 매크로 툴, 스피드 조작 툴 등 탐지 (파일, 프로세스, 메모리 패턴)']
          },
          {
            strongText: '휴리스틱',
            lstStep3: ['에뮬레이터, 디버깅 등 탐지']
          },
          {
            strongText: '행위 기반',
            lstStep3: ['스피드 조작, 에뮬레이터, 디버깅 등 탐지']
          },
          {
            strongText: '파일 무결성',
            lstStep3: [
              '빌드 된 바이너리 파일의 무결성 검증 <br> (*.dex , *.so , *.dll , 리소스 파일에 대한 위변조 감지)'
            ]
          }
        ]
      },
      {
        emSpanText: '대 응',
        lstStep2: [
          {
            strongText: '제재',
            lstStep3: [
              '어뷰징 탐지 시 로그 전송 탐지 로그의 내용과 유저 아이디로 제재',
              '어뷰징 탐지 시 앱 실행 자동 차단 (클라이언트)'
            ]
          }
        ]
      }
    ]
  },
  aTag: [
    { href: 'https://www.toast.com/kr/service/security/nhn-appguard', aText: 'AppGuard 서비스 바로가기' },
    {
      href: 'https://www.toast.com/kr/service/analytics/log-crash-search',
      aText: 'Log & Crash Search 서비스 바로가기'
    },
    { href: 'https://www.toast.com/kr/service/compute/instance', aText: 'Instance 서비스 바로가기' }
  ]
};
