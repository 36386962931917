export default {
  titTop: 'Smart Downloader',
  emphTop: 'リソース管理とダウンロード管理を一度に！',
  descTop:
    'Smart Downloaderは、グローバルなネットワーク環境でコンテンツを迅速かつ安定的にインストールできるように、ゲームリソースの圧縮、マルチスレッドへの対応で、スピーディーなダウンロードと高い成功率を提供します',
  sectionDiagram: {
    imgAlt: 'SmartDownloader Diagram',
    txtService1: 'ファイル圧縮およびメタファイル作成',
    txtService2: 'ダウンロード指標収集',
    txtSubService: '(国別成功率、ダウンロード速度、失敗理由など)',
    txtService3: '高い成功率！<br />高速なダウンロード！'
  },
  sectionService: [
    {
      titSection: '大容量のファイル管理もラクラク！',
      descSection:
        'Smart Downloaderは、グローバルなネットワーク環境でコンテンツを迅速かつ安定的にインストールできるよう、 ゲームリソースの圧縮、マルチスレッドへの対応で、スピーディーなダウンロードと高い成功率を提供します。'
    },
    {
      titSection: 'Smart Downloader Unity Tool',
      descSection:
        'Smart Downloader Unity Tool(SUT)はUnityでリソースをアップロードして配布できるツールです。<br />Unityリソースの管理とアップロードをより簡単に管理できるようにツールを提供します。',
      titSubSection: 'ゲームリソースの配布を簡単かつ迅速に！'
    }
  ],
  // service1
  lstItem: [
    {
      pText: '低速ネットワーク環境でも、スピーディーなゲームのインストール·アップデートを行うことができます。'
    },
    {
      pText: '最初のインストール以降、更新されたリソースのみアップデート可能です。'
    },
    {
      pText: 'メタファイル修正でアップデートを制御することで、ゲームコード修正なしでリソースアップデートが可能です。'
    },
    {
      pText: 'ConsoleからOne-stepで配布ファイルをCDNにアップロードでき、便利で低コストのCDNを連動できます。'
    },
    {
      pText:
        'リアルタイムダウンロードステータス指標により国別サービスの安全性を直感的に把握し、設置&アップデートと関連した問題に直ちに対応することができます。'
    }
  ],
  linkEnd: 'SmartDownloader製品相談',
  icoArrowForwardW: 'リンク'
};
