export default {
  titTop: 'Log & Crash Search',
  emphTop: 'Search to manage and analyze game logs and crashes!',
  descTop: "Log & Crash Search collects the clients' and servers' logs to search and view the logs you want.",
  titSection: 'Characteristics',
  descSection:
    'It analyzes crashes on mobile apps and runs statistics on them to provide various information on the cause thereof.',
  skill: [
    {
      titSubSection: 'Key features',
      lstSkill: [
        {
          pText: 'Remove service influence scores due to massive amount of logs generated in game servers.'
        },
        {
          pText: 'View and analyze errors from all distributed clients in one place.'
        },
        {
          pText: 'Search logs from the mass of logs and views them by patterns.'
        },
        {
          pText: 'Monitor logs and crashes every 5 minutes'
        },
        {
          pText: 'Deletion of each service and viewing/analysis functions for different log types are provided.'
        },
        {
          pText: 'Supports errors, crash dumps, web application logs, user-defined message formats, etc.'
        },
        {
          pText:
            'Shows the number of logs for different projects, hosts, log types, log level and charts out log changes for different time periods on the log search page'
        },
        {
          pText: 'Makes it easier to check the logs by emphasizing the matching query words on the log'
        }
      ]
    },
    {
      titSubSection: 'Service target',
      lstSkill: [
        {
          spanClass: 'brand_target_log',
          pText: 'When too much web logs pile on Apache, Tomcat, etc.'
        },
        {
          spanClass: 'brand_no_server',
          pText: 'When you need to view the server log without accessing the server'
        },
        {
          spanClass: 'brand_target_collect',
          pText: 'When logs from apps distributed to the users should be viewed together'
        },
        {
          spanClass: 'brand_error_log',
          pText: 'When the changes in the error logs from the service must be tracked'
        }
      ]
    }
  ],
  aTag: [
    { href: 'https://docs.toast.com/en/Security/NHN%20AppGuard/en/Overview/', aText: 'Go to AppGuard Service' },
    {
      href: 'https://docs.toast.com/en/Analytics/Log%20&%20Crash%20Search/en/Overview/',
      aText: 'Go to Log & Crash Search Service'
    },
    { href: 'https://docs.toast.com/en/Compute/Instance/en/overview/', aText: 'Go to Instance Service' }
  ]
};
