export default {
  lstLegal: [
    { title: '会社紹介', href: 'http://www.nhn-japan.com/' },
    { title: '利用約款', href: 'https://www.toast.com/jp/terms/terms-service ' },
    { title: '個人情報の取扱い', href: 'https://www.toast.com/jp/terms/privacy-policy' },
    { title: '製品相談', href: '' }
  ],
  familySite: 'Family Site',
  more: 'more',
  familySiteSub: [
    { title: 'NHN Cloud', href: 'https://www.toast.com/kr' },
    { title: 'TOAST CAM', href: 'http://cam.toast.com' },
    { title: 'Dooray!', href: 'http://dooray.com' }
  ],
  footerInfo: [
    '会社名：NHN JAPAN株式会社 代表取締役社長：安賢植',
    '住所 : 東京都港区虎ノ門一丁目23番1号 虎ノ門ヒルズ森タワー22階 ' +
      'サポート : 03-6263-1961 eメール : support@nhn.com'
  ],
  txtCopyright: '© NHN JAPAN Corp. All rights reserved.'
};
