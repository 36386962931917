export default {
  countryCode(state) {
    return state.countryCode;
  }, 
  noticeAll(state) {
    return state.noticeAll;
  }, 
  noticeOne(state) {
    return state.noticeOne;
  }, 
  page(state) {
    return state.page;
  }
};
