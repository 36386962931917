export default {
  home: '主页',
  service: '服务',
  customerStories: '客户案例',
  partner: '合作伙伴',
  download: '下载',
  usage: {
    title: '用户指南',
    toastProduct: 'NHN Cloud'
  },
  noticeNews: {
    title: '公告/消息',
    notice: '公告事项',
    newsLetter: '新闻集锦',
    press: '新闻资料',
    promotion: '宣传'
  },
  console: '控制台'
};
