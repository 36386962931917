import { lazyLayoutLoad, lazyViewLoad } from '@/helpers/router';

export default {
  path: '/:countryCode/partner',
  component: lazyLayoutLoad('PartnerLayout'),
  redirect: '/:countryCode/partner/main',
  children: [
    {
      path: 'main',
      component: lazyViewLoad('partner/Main'),
      meta: { contentClass: 'cont_partner', seo: 'seo.partner.main' }
    }
  ]
};
