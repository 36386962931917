import api from '@/helpers/api';
import { createUrl } from '@/helpers/store';

// 목록 요청
export function fetchNoticeList(params) {
    return api({
        method: 'get',
        url: createUrl('toastnotice/'),
        params
    });
}

// 단건 요청
export function fetchNoticeOne(id) {
    return api({
        method: 'get',
        url: createUrl(`toastnotice/${id}/`)
    });
}