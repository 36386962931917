export default {
  home: 'Home',
  service: 'Services',
  customerStories: 'Customers',
  partner: 'Partners',
  download: 'Downloads',
  usage: {
    title: 'User Guide',
    toastProduct: 'NHN Cloud'
  },
  noticeNews: {
    title: 'Notice',
    notice: 'Notice',
    newsLetter: 'Newsletters',
    press: 'PR',
    promotion: 'Promotions'
  },
  console: 'Console'
};
