export default {
  titTop: 'App Guard',
  emphTop: '强大的Mobile安全服务!',
  descTop: 'AppGuard是安全地保护应用免受各种滥用的Mobile APP解决方案。',
  sectionService: [
    {
      titSection: '特征',
      descSection:
        '并非仅具有单纯的作弊工具探测功能，还具有防止反编译、完整性验证等强大功能，为用户提供更为便捷的应用方法。'
    }
  ],
  lstAppguard: [
    {
      emText: '便利的Web控制台',
      spanText: '提供日志分析控制台<br>可以进行实时策略设置'
    },
    {
      emText: '强大的安全性',
      spanText: '防止反编译、代码加密<br>验证文件完整性'
    },
    {
      emText: '简便的应用',
      spanText: '可通过与开发工具合并应用 <br>Web 控制台、CLI、Unity3D 编辑器的扩展'
    }
  ],
  appguardGuide: {
    titSubSection: 'Feature',
    lstStep: [
      {
        emSpanText: 'Grade 1',
        lstStep2: [
          {
            strongText: '黑客工具探测',
            lstStep3: ['探测/切断作弊和macro tool']
          }
        ]
      },
      {
        emSpanText: 'Grade 2',
        lstStep2: [
          {
            strongText: '防止伪造OS',
            lstStep3: ['OS路由环境探测/屏蔽', '模拟器环境探测/屏蔽']
          },
          {
            strongText: '防御伪造代码',
            spanText: '（完整性检查）',
            lstStep3: [
              '加密API（检测篡改应用程序）',
              '检测/阻止签名篡改',
              '检测/阻止清单篡改',
              '检测/阻止主要模块和 Java 代码 (DEX) 篡改'
            ]
          },
          {
            strongText: '静态分析防御',
            lstStep3: ['Java 代码( DEX) 加密', '加密游戏执行文件( cocos2d-x, Unitimono, Unreal Engine)']
          }
        ]
      },
      {
        emSpanText: 'Grade 3',
        lstStep2: [
          { strongText: '自我保护', lstStep3: ['保护 AppGuard 中的重要代码（代码混淆）'] },
          {
            strongText: '动态分析防御',
            lstStep3: ['Unity iL2CPP 代码分析防御', 'AppGuard 代码虚拟化', '.so 动态库保护']
          },
          {
            strongText: '基于行为的宏探测',
            lstStep3: ['通过游戏内触摸坐标分析进行基于机器学习的Macro用户检测']
          }
        ]
      }
    ]
  },
  appguardFlow: {
    titSubSection: 'AppGuard 使用流程',
    txtAppguardFlow1: '发送检测日志',
    txtAppguardFlow2: '发送检测日志',
    txtAppguardFlow3: '搜索检测日志',
    descSection: '基于模式检测和启发式或作弊器的行为基准联合检测，很难绕过检测。',
    lstStep: [
      {
        emSpanText: '防御',
        lstStep2: [
          {
            strongText: '防止重新打包 <br class="noneview_m"> 防止代码操作',
            lstStep3: [
              '反编译保护支持',
              '支持游戏代码加密（Unity 3D mono、Unity 3D il2cpp、Cocos2d-x）和Java代码加密（DEX）'
            ]
          }
        ]
      },
      {
        emSpanText: '检测',
        lstStep2: [
          {
            strongText: '模式',
            lstStep3: ['作弊器', '宏工具', '加速器等（文件、进程、内存模式）']
          },
          {
            strongText: '启发式',
            lstStep3: ['检测模拟器', '调试等']
          },
          {
            strongText: '行为基础',
            lstStep3: ['加速器', '模拟器', '反编译器检测']
          },
          {
            strongText: '文件完整性',
            lstStep3: ['构建二进制文件的完整性验证<br>（*.dex、*.so、*.dll、资源文件的伪造检测）']
          }
        ]
      },
      {
        emSpanText: '应对',
        lstStep2: [
          {
            strongText: '制裁',
            lstStep3: [
              '探测abusing时，用日志传送探测日志的内容和用户账号进行制裁。',
              '探测abusing时自动切断应用程序的运行（客户端）'
            ]
          }
        ]
      }
    ]
  },
  aTag: [
    { href: 'https://www.toast.com/kr/service/security/nhn-appguard', aText: '转到AppGuard服务' },
    {
      href: 'https://www.toast.com/kr/service/analytics/log-crash-search',
      aText: '转到Log & Crash Search服务'
    },
    { href: 'https://www.toast.com/kr/service/compute/instance', aText: '转到Instance服务' }
  ]
};
