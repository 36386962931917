import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import example from './example';
import notice from './notice';
import press from './press';
import newsLetter from './newsLetter';
import contact from './contact';
import gamebaseDemo from './gamebaseDemo';

Vue.use(Vuex);

const createStore = () => {
  return new Vuex.Store({
    strict: true,
    modules: {
      common,
      example,
      notice,
      press,
      newsLetter,
      contact, 
      gamebaseDemo
    }
  });
};

const store = createStore();

export default store;
