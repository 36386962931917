export default {
  titTop: 'App Guard',
  emphTop: '強力なチート対策ツール!',
  descTop: 'AppGuardは、様々な不正行為から安全にアプリを保護できる、モバイルアプリ・セキュリティソリューションです。',
  sectionService: [
    {
      titSection: '特徴',
      descSection:
        '単純なチートツール検知機能だけでなく、デコンパイル防止や改ざん防止などの強力な機能が含まれており、簡単な適用方法で利用することができます。'
    }
  ],
  lstAppguard: [
    {
      emText: '便利なウェブコンソール',
      spanText: 'ログ分析コンソールを提供<br>リアルタイムポリシー設定可能'
    },
    {
      emText: '強力な保安',
      spanText: 'デコンパイル防止、コード暗号化<br>ファイル完全性検証'
    },
    {
      emText: '手軽な適用',
      spanText: '開発ツールに統合して適用可能<br>ウェブコンソール、CLI、Unity3Dエディタ拡張'
    }
  ],
  appguardGuide: {
    titSubSection: 'Features',
    lstStep: [
      {
        emSpanText: 'Grade 1',
        lstStep2: [
          {
            strongText: 'ハッキングツールの検知',
            lstStep3: ['チートおよびマクロツール検知/遮断']
          }
        ]
      },
      {
        emSpanText: 'Grade 2',
        lstStep2: [
          {
            strongText: 'OS改ざん防止',
            lstStep3: ['ルート化/脱獄環境検知/遮断', 'エミュレータ環境検知/遮断']
          },
          {
            strongText: 'コード改ざん防御',
            spanText: '(整合性チェック)',
            lstStep3: [
              '暗号化API(改ざんアプリ検知)',
              '署名改ざん検知/遮断',
              'マニフェスト改ざん検知/遮断',
              '主要モジュールおよびジャワコード(DEX)改ざん検知/遮断'
            ]
          },
          {
            strongText: '静的分析防御',
            lstStep3: ['Javaコード(DEX)暗号化', 'ゲーム実行ファイル暗号化(cocos2d-x,Unitimono,UnrealEngine)']
          }
        ]
      },
      {
        emSpanText: 'Grade 3',
        lstStep2: [
          { strongText: '自己保護', lstStep3: ['AppGuard内の重要コード保護(コードの難読化)'] },
          {
            strongText: '動的分析防御',
            lstStep3: ['Unitiy iL2CPP コード分析防御', 'AppGuard コード仮想化', '.so 動的ライブラリ保護']
          },
          {
            strongText: '行為ベースマクロ検知',
            lstStep3: ['ゲーム内タッチ座標分析による機械学習基盤マクロユーザー検出']
          }
        ]
      }
    ]
  },
  appguardFlow: {
    titSubSection: 'AppGuard 利用 Flow',
    txtAppguardFlow1: '検知ログ転送',
    txtAppguardFlow2: '検知ログ転送',
    txtAppguardFlow3: '検知ログ検索',
    descSection:
      'パターンによる検知、ヒューリスティックまたはチートツールの動作方式による行動ベースの検知を組み合わせて作動するため、検知の迂回は困難です。',
    lstStep: [
      {
        emSpanText: '防止',
        lstStep2: [
          {
            strongText: 'リパッケージング防御<br class="noneview_m">コード操作防御',
            lstStep3: [
              'デコンパイル防止対応',
              'ゲームコード暗号化(Unity 3D mono, Unity 3D il2cpp, Cocos 2d-x)およびJavaコード暗号化(DEX)対応'
            ]
          }
        ]
      },
      {
        emSpanText: '検知',
        lstStep2: [
          {
            strongText: 'パターン',
            lstStep3: ['チートツール、マクロツール、スピード操作ツールなど検知（ファイル、プロセス、メモリパターン）']
          },
          {
            strongText: 'ヒューリスティック',
            lstStep3: ['エミュレータ、デバッグなどを検知']
          },
          {
            strongText: '行動ベース',
            lstStep3: ['スピードハック、エミュレータ、デバッグなどの検知']
          },
          {
            strongText: 'ファイル完全性',
            lstStep3: [
              'ビルドされたバイナリファイルの完全性検証<br>(*.dex, *.so, *.dll,リソースファイルに対する改ざん検知)'
            ]
          }
        ]
      },
      {
        emSpanText: '対応',
        lstStep2: [
          {
            strongText: '題材',
            lstStep3: [
              'Abuse検知時ログ転送検知ログの内容とユーザーIDによる制裁',
              'Abuse検知時アプリ実行自動遮断(クライアント)'
            ]
          }
        ]
      }
    ]
  },
  aTag: [
    { href: 'https://www.toast.com/jp/service/security/nhn-appguard', aText: 'AppGuardサービス リンク' },
    { href: 'https://www.toast.com/jp/service/analytics/log-crash-search', aText: 'Log & Crash Searchサービス リンク' },
    { href: 'https://www.toast.com/jp/service/compute/instance', aText: 'Instanceサービス リンク' }
  ]
};
