export default {
  titTop: 'Leaderboard',
  emphTop: 'Easy implementation by linking the game!',
  descTop:
    'This is a game ranking-dedicated service provided by NHN only, which lets customers to easily implement ranking service through console settings and game linking without separate SDK integration.',
  txtService1: 'Ranking (Score) Registration',
  txtService2: 'View Rankings',
  txtInfoRealtime: 'Fast, reliable real-time/massive ranking system support',
  txtInfoRankingSort: 'Create ranking systems based on different criteria',
  txtInfoSortManage:
    'Manage ranking period/ranking update criteria/ranking sorting criteria through the console with ease',
  txtInfoTemplate: 'Create ranking systems quickly with templates',
  linkEnd: 'Leaderboard Product Consultation',
  icoArrowForwardW: 'Shortcut',
  sectionService1: {
    imgAlt: 'Leaderboard Ranking Server'
  }
};
