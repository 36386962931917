export default {
  coverTitSection: {
    titSection: 'GamePlatformダウンロード',
    descSection:
      '<b><a href="https://docs.toast.com/ko/Download/#game-gamebase" target="_blank">NHN Cloud > ドキュメント > Downloads</a></b> でもダウンロードすることができます。',
    lstDotList: [
      '• 各プラットフォームに合わせたドキュメントと最新SDK、Sample Appを提供します',
      '• UnityはSetting ToolでUnitySDKとNativeSDKの両方をダウンロードしてインストールできます',
      '• AndroidはGradle設定でSDKをプロジェクトに追加できます(最新バージョンで自動適用)',
      '• iOSはCocoaPods設定でSDKをプロジェクトに追加できます(最新バージョンで自動適用)',
      '• また、プラットフォーム環境に合わせて手動ダウンロードとSample Appを提供し、より簡単にテストすることができます'
    ]
  },
  top: {
    service: [
      {
        title: 'Unity',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unity',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unity Setting Tool',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSettingTool.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.unity.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ]
      },
      {
        title: 'Unreal',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unreal',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unreal SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-Unreal.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      },
      {
        title: 'Android',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_android',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Gradle Setting Guide',
            href: 'https://docs.toast.com/ja/Game/Gamebase/ja/aos-started/#setting',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.android.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      },
      {
        title: 'iOS',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_apple',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'CocoaPods',
            href: 'https://docs.toast.com/ja/Game/Gamebase/ja/ios-started/',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'iOS SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-iOS.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.ios.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(In preparation)'
      }
      // {
      //   title: 'Old versions of SDK',
      //   titleStrongClass: 'tit_download',
      //   titleSpanClass: '',
      //   ulClass: 'lst_download',
      //   downloadList: [
      //     {
      //       title: 'Old versions of SDK',
      //       href: 'https://docs.toast.com/ko/Download/#game-gamebase',
      //       hrefClass: 'link_download link_launch',
      //       titleSpanClass: 'ico_game ico_launch'
      //     }
      //   ]
      // }
    ]
  },
  bottom: {
    service: [
      {
        title: 'Smart Downloader',
        downloadList: [
          {
            title: 'Unity SDK',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/Smart-downloader-1.6.8.unitypackage'
          },
          {
            title: 'Jenkins Plugin',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/smartdl-uploader.hpi'
          },
          {
            title: 'Smart Downloader Unity Tool(SUT)',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/SUT/SmartDownloaderUnityTool.zip'
          }
        ]
      },
      {
        title: 'NHN Cloud',
        downloadList: [
          { title: 'PUSH', href: 'https://docs.toast.com/ja/Notification/Push/ja/Overview/' },
          {
            title: 'Log & Crash Search',
            href: 'https://docs.toast.com/ja/Analytics/Log%20&%20Crash%20Search/ja/Overview/'
          },
          { title: 'more NHN Cloud Services', href: 'https://docs.toast.com/ja/Download/#toast-sdk' }
        ]
      }
    ]
  }
};
