<template>
  <header class="ne_header" :class="{ on: activeHeaderOn }">
    <div class="inner_header">
      <h1 class="tit_logo">
        <router-link :to="mixinGenerateCountryLink('')" class="link_logo">
          <!-- 2020-12-14 수정 -->
          <span class="ico_game ico_logo"></span>
        </router-link>
      </h1>
      <strong class="blind">{{ $t('header.blind') }}</strong>
      <!-- [D] 모바일에서 네비게이션 오픈시 on -->
      <button class="btn_gnb view_m" :class="{ on: visibleMobileMenu }" @click="visibleMobileMenu = true">
        <span class="ico_game ico_menu">{{ $t('header.icoMenu') }}</span>
      </button>
      <div class="wrap_gnb">
        <ul id="nav" class="lst_gnb" role="navigation">
          <li v-for="menu in menus" :key="menu.path">
            <a href="#none" class="link_gnb" :class="{ on: isSelectMenu(menu) }" @click.prevent="handleClickMenu(menu)">
              {{ menu.title }}
              <!-- 2020-12-08 noneview_m 클래스 제거 -->
              <span v-if="menu.children" class="ico_game ico_arrow_dropdown">more</span>
            </a>
            <div v-if="menu.children" class="cover_gnb_sub">
              <ul class="lst_gnb_sub">
                <li v-for="submenu in menu.children" :key="submenu.path">
                  <a href="#" class="link_gnb_sub" @click.prevent="handleMoveLink(submenu)">
                    {{ submenu.title }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <!-- 2021-03-09 수정 시작 -->
        <a href="https://console.toast.com/" target="_blank" class="link_console">{{ $t('header.linkConsole') }}</a>
        <button class="btn_close view_m" @click="visibleMobileMenu = false">
          <span class="ico_game ico_close">{{ $t('header.icoClose') }}</span>
        </button>
        <div class="wrap_gnb_sub view_pc">
          <a href="https://www.toast.com/" target="_blank" class="link_cloud">{{ $t('header.linkCloud') }}</a>
          <div class="gnb_item_lang">
            <a
              href="#none"
              class="link_gnb link_lang"
              :class="{ on: isSelectMenu(lang) }"
              @click.prevent="handleClickMenu(lang)"
              >{{ lang.langTitle }}<span class="ico_game ico_arrow_dropdown">more</span></a
            >
            <div class="cover_gnb_sub">
              <ul class="lst_gnb_sub">
                <li v-for="submenu in lang.children" :key="submenu.title">
                  <a href="#" class="link_gnb_sub" @click="handleChangeLocale(submenu)">
                    {{ submenu.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CountryCodeLinkMixin from '@/mixins/CountryCodeLink.mixin.vue';

export default {
  name: 'CommonHeader',
  mixins: [CountryCodeLinkMixin],
  data() {
    return {
      visibleMobileMenu: false,
      selectedMenu: null,
      activeHeaderOn: false
    };
  },
  computed: {
    menus() {
      const { mixinGenerateCountryLink, mixinGenerateCountryGuideLink,mixinGenerateCountryGuideLink_lowerCase } = this;

      return [
        {
          title: this.$t('menu.service'),
          path: mixinGenerateCountryLink('service'),
          children: [
            { title: 'Gamebase', path: mixinGenerateCountryLink('service/gamebase') },
            { title: 'GameAnvil', path: mixinGenerateCountryLink('service/gameAnvil') },
            { title: 'Smart Downloader', path: mixinGenerateCountryLink('service/smartDownloader') },
            { title: 'Leaderboard', path: mixinGenerateCountryLink('service/leaderboard') },
            // { title: 'NHN Cloud', path: mixinGenerateCountryLink('service/nhnCloud') },
            { title: 'AppGuard', path: mixinGenerateCountryLink('service/appGuard') },
            { title: 'Log & Crash Search', path: mixinGenerateCountryLink('service/logNCrash') },
            { title: 'Instance', path: mixinGenerateCountryLink('service/instance') },
            { title: 'Pricing', path: mixinGenerateCountryLink('service/pricing') }
          ]
        },
        { title: this.$t('menu.customerStories'), path: mixinGenerateCountryLink('customerStories/main') },
        { title: this.$t('menu.partner'), path: mixinGenerateCountryLink('partner/main') },
        { title: this.$t('menu.download'), path: mixinGenerateCountryLink('download/main') },
        {
          title: this.$t('menu.noticeNews.title'),
          path: mixinGenerateCountryLink('noticeNews/notice'),
          children: [
            { title: this.$t('menu.noticeNews.notice'), path: mixinGenerateCountryLink('noticeNews/notice') },
            { title: this.$t('menu.noticeNews.newsLetter'), path: mixinGenerateCountryLink('noticeNews/newsLetter') },
            { title: this.$t('menu.noticeNews.press'), path: mixinGenerateCountryLink('noticeNews/press') }
            // { title: this.$t('menu.noticeNews.promotion'), path: mixinGenerateCountryLink('noticeNews/promotion') }
          ]
        },
        {
          title: this.$t('menu.usage.title'),
          path: mixinGenerateCountryLink('guide'),
          children: [
            { title: 'Gamebase', isHref: true, path: mixinGenerateCountryGuideLink('Game/Gamebase') },
            { title: 'GameAnvil', isHref: true, path: mixinGenerateCountryGuideLink_lowerCase('Game/GameAnvil') },
            // { title: 'Gamebase Demo', path: mixinGenerateCountryLink('service/gamebaseDemo/main') },
            { title: 'Smart Downloader', isHref: true, path: mixinGenerateCountryGuideLink('Game/Smart%20Downloader') },
            { title: 'Leaderboard', isHref: true, path: mixinGenerateCountryGuideLink('Game/Leaderboard') },
            {
              title: this.$t('menu.usage.toastProduct'),
              isHref: true,
              path: mixinGenerateCountryGuideLink('TOAST')
            }
            // { title: 'DEMO', isHref: true }
          ]
        }
      ];
    },
    lang() {
      const { mixinGenerateCountryLink } = this;

      return {
        path: mixinGenerateCountryLink('lang'),
        langTitle: this.mixinCountryTitle(),
        children: [
          { title: 'KOR', resource: 'kr' },
          { title: 'JPN', resource: 'jp' },
          { title: 'ENG', resource: 'en' }
        ]
      };
    }
  },
  watch: {
    $router() {
      this.selectedMenu = null;
    }
  },
  created() {
    document.querySelector('body').setAttribute('class', this.$root.$i18n.locale);
  },
  mounted() {
    document.addEventListener('click', this.menuOuterClick);
    document.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.menuOuterClick);
    document.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    handleChangeLocale(langChildren) {
      document.querySelector('body').setAttribute('class', langChildren.resource);
      this.$store.commit('common/setCountryCode', langChildren.resource);
    },
    menuOuterClick(e) {
      if (!this.selectedMenu) {
        return;
      }

      if (e.target.tagName.toLowerCase() !== 'a' && e.target.tagName.toLowerCase() !== 'span') {
        this.selectedMenu = null;
      }
    },
    isSelectMenu(menu) {
      return menu.path === this.selectedMenu;
    },
    handleClickMenu(menu) {
      if (menu.children) {
        this.handleToggleMenu(menu);
      } else {
        this.handleMoveLink(menu);
      }
    },
    handleMoveLink(menu) {
      this.selectedMenu = null;
      if (typeof menu.path !== 'undefined' && menu.isHref) {
        window.open(menu.path, '_blank');
      } else if (menu.path !== this.$route.path) {
        this.$router.push(menu.path);
      }
    },
    handleToggleMenu(menu) {
      if (this.selectedMenu === menu.path) {
        this.selectedMenu = null;
      } else {
        this.selectedMenu = menu.path;
      }
    },
    updateScroll() {
      this.activeHeaderOn = window.scrollY !== 0;
    }
  }
};
</script>
