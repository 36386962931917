export default {
  titSection: 'NewsLetter',
  descSection: 'See more newsletters about NHN game platform.',
  list: [
    {
      id: 229,
      titNewsLetter: 'GamePlatform 뉴스레터 28호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter28.png)',
      txtNewsLetter:
        '#Google Play 결제 라이브러리 v6 지원, #iOS > Facebook 제한적 로그인 이슈 대응, #iOS > Facebook Framework 변경 안내, #FCM 인증 방식 변경 안내, #Gamebase 롤링 이미지 공지 팝업 기능 업데이트, #Google 세분화된 권한 처리 방법 안내'
    },
    {
      id: 226,
      titNewsLetter: 'GamePlatform 뉴스레터 27호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter27.png)',
      txtNewsLetter:
        '#Gamebase 데이터베이스 변경 작업 안내 #Gamebase Android SDK 2.62.1 배포 안내 #Gamebase iOS 최소 지원 버전 상향 안내 #App Store 개인정보 보호 정책 변경 대응'
    },
    {
      id: 225,
      titNewsLetter: 'GamePlatform 뉴스레터 26호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter26.png)',
      txtNewsLetter:
        '#NHN Cloud 게임플랫폼 3월 기술 세미나 #Gamebase, App Store Notifications V2와 신규 영수증 검증 방식 지원'
    },
    {
      id: 224,
      titNewsLetter: 'GamePlatform 뉴스레터 25호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter25.png)',
      txtNewsLetter:
        '#Gamebase macOS 지원 사전 안내 #NHN Cloud 게임플랫폼 기술 세미나 3월 개최 #GameAnvil 무상 이용 특별 이벤트! #GameAnvil 콘솔의 서버 메뉴 고도화'
    },
    {
      id: 223,
      titNewsLetter: 'GamePlatform 뉴스레터 24호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter24.png)',
      txtNewsLetter: '#GameAnvil 1.4.1 업데이트'
    },
    {
      id: 222,
      titNewsLetter: 'GamePlatform 뉴스레터 23호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter23.png)',
      txtNewsLetter: '#Gamebase, Epic Games Store 결제 시스템 지원'
    },
    {
      id: 218,
      titNewsLetter: 'GamePlatform 뉴스레터 22호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter22.png)',
      txtNewsLetter:
        '#GameAnvil을 무상으로 이용 가능한 단 한번의 특별한 기회! #NHN GamePlatform, G-Star 2023 참여 #G-STAR 2023, 특별 프로모션! - 게임 개발을 위한 100만 크레딧 제공\n'
    },
    {
      id: 217,
      titNewsLetter: 'GamePlatform 뉴스레터 21호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter21.png)',
      txtNewsLetter: '#Gamebase 구글 플레이 결제 라이브러리 버전 5 지원 안내\n'
    },
    {
      id: 215,
      titNewsLetter: 'GamePlatform 뉴스레터 20호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter20.png)',
      txtNewsLetter: '#NHN 게임플랫폼 App 신규 출시 #채팅으로 함께하는 즐거움, GameTalk 기능 업데이트\n'
    },
    {
      id: 214,
      titNewsLetter: 'Gamebase 뉴스레터 19호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter19.jpg)',
      txtNewsLetter:
        '#Gamebase iOS SDK에서 제공하는 Carrier 관련 API 지원 종료 안내 #GameTalk 기능 업데이트 사전 안내 #게임베이스 적용 게임 <페이탈 코드> 출시 #게임베이스 적용 게임 <무한 영웅> 출시\n'
    },
    {
      id: 209,
      titNewsLetter: 'Gamebase 뉴스레터 18호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter18.png)',
      txtNewsLetter:
        '#쉽고 빠른 게임 서버 개발을 위한 GameAnvil 정식 오픈 #Gamebase iOS SDK 최소 버전 상향 안내 #신규 기능 사전 안내 : 콘솔 내 회원 검색 시 조건 추가 #신규 기능 안내 : Retention D+180일 지표 확대 제공 #신규 기능 안내 : Push 발송 실패 응답 결과 조회 #NHN, Gamebase를 연동한 <한게임 더블에이 포커> 정식 출시 #Gamebase 연동 게임 <카운터사이드> 자체 서비스로 전환\n'
    },
    {
      id: 208,
      titNewsLetter: 'Gamebase 뉴스레터 17호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter17.jpg)',
      txtNewsLetter:
        '#NHN GamePlatform, G-Star 2022에서 신규 게임개발 솔루션 선 공개 #게임 서버 개발 기간 단축! NHN의 GameAnvil #NHN, 경기콘텐츠진흥원과 함께 무료 게임 컨설팅 진행 #신규 기능 안내 : 누적 결제 금액(M) #신규 기능 안내 : 누적 DAU(M) #신규 기능 안내 : 가입일로부터의 첫 구매'
    },
    {
      id: 201,
      titNewsLetter: 'Gamebase 뉴스레터 16호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter16.jpg)',
      txtNewsLetter:
        '#NHN GamePlatform 신규 상품 공개 #온라인 게임 서버 개발을 쉽고 빠르게, GameAnvil을 만나보세요! #Gamebase, 글로벌 진출에도 최적화된 플랫폼 #게임베이스 적용 MMORPG <천문> 출시 #신규 기능 안내 : 매출 지표 내 조회 마켓 추가 및 합산 방법 개선 #신규 기능 사전 안내 : 지표 관련 개선 기능 #신규 기능 사전 안내 : LINE 로그인 멀티 ID 지원 #Gamebase API 호출을 위한 서버 주소 변경 안내 #애플 앱스토어 심사 변경에 대한 추가 대응 안내 #Gamebase 문의/상담 안내'
    },
    {
      id: 196,
      titNewsLetter: 'Gamebase 뉴스레터 15호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter15.jpg)',
      txtNewsLetter:
        '#원스토어 외부 결제 지원 기능 추가 (예정) #애플 심사 규정 업데이트 - 계정 삭제 요구 사항 대응 가이드 #NHN 빅풋, 게임베이스로 경기콘텐츠진흥원 상생 캠페인 ‘GGC GREEN FRIENDSHIP’ 동참 #Gamebase 적용 게임 NHN 빅풋 <건즈업! 모바일> 출시 #Gamebase 적용 게임 <카운터사이드> 글로벌 출시 #Gamebase 적용 블록체인 게임 <에브리팜> 글로벌 출시 #Google Play 스토어에 대해 서비스 계정 연동 방식 추가 제공'
    },
    {
      id: 192,
      titNewsLetter: 'Gamebase 뉴스레터 14호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter14.jpeg)',
      txtNewsLetter:
        '#게임베이스 적용 블록체인 게임 < Rise of Stars> 출시 #게임베이스 적용 게임 <고양이 섬의 비밀> 출시 #게임베이스 적용 게임 <태고 M> 출시 #업데이트 필수 팝업의 버튼 및 링크 연결 기능 추가 #Push 광고 수신 동의 사실 안내 메시지 예약 기능 추가 #Gamebase 고객센터 지원 언어 추가 #Gamebase 모바일 고객센터 언어 선택 기능 추가 #Gamebase 문의/상담 안내'
    },
    {
      id: 187,
      titNewsLetter: 'Gamebase 뉴스레터 13호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter13.jpg)',
      txtNewsLetter:
        '#기술 컨퍼런스 NHN FORWARD 성료#NHN GamePlatform, G-STAR 2021 참여#Apache Log4j 취약점 대응 현황 안내#Gamebase 고객센터 외부주소 제공 기능 추가#점검 페이지 기능 개선#Gamebase 문의/상담 안내'
    },
    {
      id: 181,
      titNewsLetter: 'Gamebase 뉴스 레터 12호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter12.png)',
      txtNewsLetter:
        "#NHN-경기콘텐츠진흥원, 신생 게임사들 지원군 역할 '톡톡' #Google Play의 타겟 API 정책 변경에 따른 대응 가이드 #결제 어뷰징 자동 해제 기능 추가 #Gamebase 적용 게임 <루티에 사가> 오픈 #kakao IdP 지원 추가 #Gamebase 문의/상담 안내"
    },
    {
      id: 180,
      titNewsLetter: 'Gamebase 뉴스 레터 11호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter11.png)',
      txtNewsLetter:
        '#게임베이스 적용 게임 <대머리 키우기> 출시 #게임베이스 적용 게임 <BackalleyStage> 출시 #넷텐션과 ‘AWS 게임 부문 원격 세미나’ 개최 #콘솔앱에 리텐션 조회 기능 추가 #Gamebase 문의/상담 안내'
    },
    {
      id: 168,
      titNewsLetter: 'Gamebase 뉴스 레터 10호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter10.png)',
      txtNewsLetter:
        '#게임베이스 적용 게임 출시 소식 #넷텐션과 MOU 체결 #신규 기능 : 문의 추가 답변 기능 #NHN, 게임관련 공공기관들과 지속적인 협업 관계 구축 #Gamebase 콘솔 앱 설정 기능 개선 #Gamebase 문의/상담 안내'
    },
    {
      id: 167,
      titNewsLetter: 'Gamebase 뉴스 레터 9호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter09.png)',
      txtNewsLetter:
        '#SDK 지원 버전 변경 안내 #Android SDK 저장소 변경 안내 #결제 어뷰징 자동 제재 설정 기능 개선 #부산정보산업진흥원과 스타트업 인큐베이팅 지원 #한국모바일게임협회와 MOU 체결 #Gamebase 문의/상담 안내'
    },
    {
      id: 162,
      titNewsLetter: 'Gamebase 뉴스 레터 8호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter08.png)',
      txtNewsLetter:
        '#NHN 게임플랫폼 전용 브랜드 사이트 오픈! #게임 패키지 매니저 for Unity’ 무료 출시 #[신규 기능] Gamebase 약관 관리 기능 #[신규 기능] 앱가드에서 조건 차단 기능 #결제 어뷰징 모니터링에 App Store 추가 #Gamebase 문의/상담 안내'
    },
    {
      id: 143,
      titNewsLetter: 'Gamebase 뉴스 레터 7호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter07.png)',
      txtNewsLetter:
        '#신규 기능: 고객센터 관리 #로드 오브 히어로즈, 2020 게임 대상 최우수상 수상! #신규 기능: 콘솔 권한 세분화 #지표에서 상승/ 하락 색상 변경 안내 #게임베이스 적용 게임 출시 소식 #Gamebase 문의/상담 안내'
    },
    {
      id: 142,
      titNewsLetter: 'Gamebase 뉴스 레터 6호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter06.png)',
      txtNewsLetter:
        '#TOAST앱에서 지표를 빠르게 확인하기! #게임 제작을 위한 TOAST Kit #Gamebase 위클리 리포트 제공 #테스트 결제 포함 여부 설정 #구글 스토어 인앱결제 사용 설정 안내 #Gamebase 문의/상담 안내'
    },
    {
      id: 141,
      titNewsLetter: 'Gamebase 뉴스 레터 5호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter05.png)',
      txtNewsLetter:
        '#더 강력한 Gamebase 무료 프로모션 시즌2 #Unreal SDK 신규 지원 #[신규 기능] Frequency 7 지표 #회원 탈퇴 유예 기능 추가'
    },
    {
      id: 140,
      titNewsLetter: 'Gamebase 뉴스 레터 4호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter04.png)',
      txtNewsLetter:
        '#TOAST FORWARD 초대 (2월20일) #TOAST AppGuard를 배워봅시다! #라이프 사이클 (Life Cycle) 지표 오픈 #Gamebase 문의/상담 안내'
    },
    {
      id: 139,
      titNewsLetter: 'Gamebase 뉴스 레터 3호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter03.png)',
      txtNewsLetter:
        '#결제 어뷰징 모니터링 기능 오픈 #쿠폰 발급/조회 기능 오픈! #Apple ID 지원 #Gamebase IdP 인증 알아보기 3탄! #NHN FORWARD 경남/창원 초대 #Gamebase 문의/상담 안내'
    },
    {
      id: 138,
      titNewsLetter: 'Gamebase 뉴스 레터 2호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter02.png)',
      txtNewsLetter:
        '#Gamebase 샘플 앱 제공 #Gamebase IDP 인증 알아보기 2탄!, #PUSH 리치 메세지 기능 오픈 #Gamebase 지스타 참가 안내 #11월 오픈 예정 기능 소개 #쿠폰 기능 및 결제(환불) 어뷰징 모니터링 기능 #Gamebase 전용 문의 채널'
    },
    {
      id: 136,
      titNewsLetter: 'Gamebase 뉴스 레터 1호',
      thumbNewsLetterStyle: 'background-image:url(/resources/images/newsLetter/newsletter01.png)',
      txtNewsLetter:
        '#Gamebase 최대 1년 무료 이용 안내 #고객센터 연동 기능 오픈 #Gamebase Analytics 기능 강화 (레벨, 서버, 월드별 지표 오픈) #TOAST Gamebase에서 제공하는 IDP인증 알아보기 #Gamebase 전용 문의 채널'
    }
  ],
  boardMore: 'More newsletters',
  buttonList: 'List'
};
