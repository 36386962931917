export default {
  titTop: 'Instance',
  emphTop: '고객 환경에 맞는 <br class="view_pc">클라우드 인프라 구축!',
  descTop: '대규모 서비스 경험과 오픈 소스 기술이 융합된 통합 클라우드 서비스입니다.',
  titSection: '특징',
  descSection: '고객 환경에 맞는 클라우드 환경을 제공해 드리며, 안정적인 글로벌 서비스를 지원합니다.',
  feature: {
    lstFeature: [
      {
        strongText: '유연한 인프라',
        pText: 'OpenStack 기반의 유연한 클라우드 인프라 제공'
      },
      {
        strongText: '다양한 플랫폼',
        pText: 'Notifications, Analytics, Security 등 유용한 기능들로 구성된 플랫폼 제공'
      },
      {
        strongText: '글로벌 지원',
        pText: '게임 플랫폼 및 글로벌 네트워크를 통한 지원'
      },
      {
        strongText: '하이브리드 클라우드',
        pText: '물리 인프라와 가상 인프라를 동시에 활용할 수 있는 환경 제공'
      },
      {
        strongText: '전문 인력',
        pText: '자사 서비스 운영 경험을 기반으로 안정적 기술 지원'
      },
      {
        strongText: '합리적 비용',
        pText: '사용한 만큼 비용을 지불, 초기 비용 부담 최소화, 타사 대비 10-30% 저렴'
      },
      {
        strongText: '최신형 클라우드 센터',
        pText: '고집적 도심형 클라우드 센터'
      }
    ]
  },
  platform: {
    titPlatform: 'Platform',
    lstPlatform: [
      { liText: 'Contents' },
      { liText: 'Analytics' },
      { liText: 'Game' },
      { liText: 'Security' },
      { liText: 'Notification' },
      { liText: 'Common' }
    ]
  },
  infra: {
    titInfra: 'Infrastructure',
    lstInfra: [
      { liText: 'instance' },
      { liText: 'Server Image (Glance)' },
      { liText: 'Load Balance' },
      { liText: 'Public IP' },
      { liText: 'Object Storage (Swift)' },
      { liText: 'Identity (Keystone)' },
      { liText: 'Technology & Monitoring (Cellometers)' },
      { liText: 'Dashboard (Horizon)' }
    ]
  },
  service2: {
    titSection: '서비스 안정성 및 보안',
    descSection:
      'Gamebase를 2016년 정식 오픈한 이후로 단 한차례도 서비스가 중단된 적이 없으며, 무중단 서비스로 운영됩니다. <br>' +
      '(Facebook 인증 장애 등의 외부 IDP장애 등으로 인한 서비스 중단은 제외) <br>' +
      '또한 NHN Cloud 전체적으로 System 보안 인증 및 개인정보 관리체계가 관리되므로, 해킹 위험 없이 게임 운영이 가능합니다.'
  },
  ul1LstSecurityCertify: [
    { emphG: 'ISO/IEC 27001 인증', infoDsc: '물리 인프라와 가상 인프라를 동시에 활용할 수 있는 환경 제공' },
    { emphG: 'ISO/IEC 29100 인증', infoDsc: '개인정보 프레임워크에 대한 국제 표준 검증' },
    { emphG: 'ISO/IEC 27017/27018 인증', infoDsc: '클라우드 서비스 정보보호/개인정보 관리체계에 대한 국제 표준 검증' }
  ],
  ul2LstSecurityCertify: [
    { emphG: 'PIMS 인증', infoDsc: '국내 최고 수준의 개인정보보호 관리체계의 공인' },
    { emphG: 'ISMS 인증', infoDsc: '지속적 정보보호 관리체계의 유지에 대한 검증' },
    { emphG: 'CSAP 인증', infoDsc: '공공기관에 안전한 클라우드 서비스 제공을 위한 정보보호 수준 평가·인증' }
  ],
  aTag: [
    { href: 'https://www.toast.com/kr/service/security/nhn-appguard', aText: 'AppGuard 서비스 바로가기' },
    {
      href: 'https://www.toast.com/kr/service/analytics/log-crash-search',
      aText: 'Log & Crash Search 서비스 바로가기'
    },
    { href: 'https://www.toast.com/kr/service/compute/instance', aText: 'Instance 서비스 바로가기' }
  ]
};
