<template>
  <fragment>
    <skip-navigation />
    <div id="app" class="wrap">
      <router-view />
    </div>
  </fragment>
</template>

<script>
import SkipNavigation from '@/components/Skip.vue';
export default {
  components: { SkipNavigation }
};
</script>
