export default {
  titTop: 'Instance',
  emphTop: '顧客環境に合わせたクラウドインフラの構築!',
  descTop: '大規模サービス経験とオープンソース技術が融合した統合クラウドサービスです。',
  titSection: '特徴',
  descSection: 'お客様の環境に合わせたクラウド環境を提供し、安定したグローバルサービスの運営を支援しています。',
  feature: {
    lstFeature: [
      {
        strongText: '柔軟なインフラ',
        pText: 'OpenStack基盤の柔軟なクラウドインフラを提供'
      },
      {
        strongText: '多様なプラットフォーム',
        pText: 'Notifications、Analytics、Securityなどの有用な機能で構成されたプラットフォームを提供'
      },
      {
        strongText: 'グローバルサポート',
        pText: 'ゲームプラットフォームおよびグローバルネットワークを通じたサポート'
      },
      {
        strongText: 'ハイブリッド クラウド',
        pText: '物理インフラと仮想インフラを同時に活用できる環境を提供'
      },
      {
        strongText: '専門スタッフ',
        pText: '自社サービス運営の経験を基に、技術支援'
      },
      {
        strongText: '合理的な費用',
        pText: '使った分だけの費用、初期費用負担の最小化、他社と比べ10~30%割安'
      },
      {
        strongText: '最新のクラウドセンター',
        pText: '高集積都心型クラウドセンター'
      }
    ]
  },
  platform: {
    titPlatform: 'Platform',
    lstPlatform: [
      { liText: 'Contents' },
      { liText: 'Analytics' },
      { liText: 'Game' },
      { liText: 'Security' },
      { liText: 'Notification' },
      { liText: 'Common' }
    ]
  },
  infra: {
    titInfra: 'Infrastructure',
    lstInfra: [
      { liText: 'instance' },
      { liText: 'Server Image (Glance)' },
      { liText: 'Load Balance' },
      { liText: 'Public IP' },
      { liText: 'Object Storage (Swift)' },
      { liText: 'Identity (Keystone)' },
      { liText: 'Technology & Monitoring (Cellometers)' },
      { liText: 'Dashboard (Horizon)' }
    ]
  },
  service2: {
    titSection: 'サービスの信頼性とセキュリティ',
    descSection:
      'Gamebaseを2016年に正式にオープンして以来、たった1回もサービスが中断されたことがなく、無中断サービスとして運営されます。<br>' +
      '(Facebook認証障害などの外部IDP障害などによるサービス中断は除く) <br>' +
      'また、NHN Cloud全体でシステムセキュリティ認証とプライバシー管理体系が管理されるため、ハッキングの危険なく、ゲーム運営が可能です。'
  },
  ul1LstSecurityCertify: [
    { emphG: 'ISO/IEC 27001認証', infoDsc: '物理インフラと仮想インフラを同時に活用できる環境を提供' },
    { emphG: 'ISO/IEC 29100認証', infoDsc: '個人情報フレームワークの国際標準検証' },
    { emphG: 'ISO/IEC 27017/27018認証', infoDsc: 'クラウドサービス情報保護/個人情報管理体系の国際標準検証' }
  ],
  ul2LstSecurityCertify: [
    { emphG: 'PIMS認証', infoDsc: '国内最高水準の個人情報保護管理体系の公認' },
    { emphG: 'ISMS認証', infoDsc: '継続的な情報保護管理システムの維持の検証' },
    { emphG: 'CSAP認証', infoDsc: '公共機関に安全なクラウドサービスを提供するための情報保護水準の評価・認証' }
  ],
  aTag: [
    { href: 'https://www.toast.com/jp/service/security/nhn-appguard', aText: 'AppGuardサービス リンク' },
    { href: 'https://www.toast.com/jp/service/analytics/log-crash-search', aText: 'Log & Crash Searchサービス リンク' },
    { href: 'https://www.toast.com/jp/service/compute/instance', aText: 'Instanceサービス リンク' }
  ]
};
