export default {
  titSection: 'Notice',
  descSection: 'ゲームプラットフォームの障害、メンテナンス、ご案内などをお伝えします。',
  term: {
    open: '進行中',
    close: '完了'
  },
  boardMore: 'お知らせをもっと見る',
  buttonList: 'リスト'
};
