export default {
  home: '홈',
  service: '서비스',
  customerStories: '고객사례',
  partner: '파트너',
  download: '다운로드',
  usage: {
    title: '사용가이드',
    toastProduct: 'NHN Cloud'
  },
  noticeNews: {
    title: '공지/소식',
    notice: '공지사항',
    newsLetter: '뉴스레터 모음',
    press: '보도자료',
    promotion: '프로모션'
  },
  console: 'Console'
};
