// src/helpers/i18n.js
import i18n from '@/i18n/index';
import { getCookie, setCookie } from '@/helpers/cookie';
import { FALLBACK_COUNTRY_CODE } from '@/constants';

const localeMap = {
  en: 'en_US',
  en_US: 'en',
  kr: 'ko_KR',
  ko_KR: 'kr',
  jp: 'ja_JP',
  ja_JP: 'jp'
};

export function changeLocale(langCode) {
  setCookie('userLocale', localeMap[langCode], { domain: 'nhncloud.com' });
  i18n.locale = langCode;
}

export function getCookieCountryCode() {
  let countryCode = localeMap[getCookie('userLocale')];
  if (!countryCode) {
    const urlCountryCode = location.pathname.split('/')[1];
    if (['kr', 'en', 'jp', 'ch'].indexOf(urlCountryCode) > -1) {
      countryCode = localeMap[urlCountryCode];
    }
    if (!countryCode) {
      countryCode = FALLBACK_COUNTRY_CODE;
    }
    setCookie('userLocale', countryCode, { domain: 'nhncloud.com' });
  }

  return countryCode;
}
