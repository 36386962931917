export default {
  titTop: 'Gamebase',
  emphTop: '用集成SDK轻松快速地应用！',
  descTop: '集成SDK提供游戏通用的功能，帮助实现<br>轻松高效的开发/运营的服务。',
  sectionService: [
    {
      titSection: '主要功能',
      descSection:
        'Gamebase 是游戏上线运营的游戏专用平台。<br>只要使用Gamebase SDK，很容易实现登录/支付/道具/指标等游戏核心功能的开发。'
    },
    {
      titSection: '提供强大的分析指标',
      descSection:
        '为了事业可行性的分析，您是否支付额外费用，并使用分析平台呢？<br /> Gamebase不仅提供实时的DAU, PU, ARPU等基本指标，也提供LTV, Retention, 等级指标, 服务器现状等重要的分析指标。'
    },
    {
      titSection: '事业/开发方面的优势',
      descSection: ''
    },
    {
      titSection: '最适合进军全球的游戏解决方案',
      descSection: 'Gamebase 基本支持游戏的全球开放，并提供了以下功能，用于全球环境下的游戏运营。',
      desc_map:
        '向游戏用户显示的所有推送和维护都可以用多种语言处理。<br />提供国家过滤。<br />您可以通过选择运营商的当地时区轻松输入时间。'
    },
    {
      titSection: '缩短开发周期，节约资源',
      descSection:
        '再也不需要为了游戏发布，分析和开发诸多SDK! <br />只要使用Gamebase SDK 就可轻松实现多种功能。<br />通用的功能请交给 Gamebase ，将您的开发资源集中在提高游戏质量上！',
      desc_map:
        '向游戏用户显示的所有推送和维护都可以用多种语言处理。<br />提供国家过滤。<br />您可以通过选择运营商的当地时区轻松输入时间。'
    },
    {
      titSection: '强大的服务稳定性及安全性',
      descSection:
        'Gamebase自2016年正式开通以来，没有大的障碍，持续提供稳定的服务。 <br />另外，由于NHN的整体系统安全认证和个人信息管理系统都在最高级别管理，可以在没有安全和黑客风险的情况下安全地运行游戏。'
    },
    {
      titSection: '游戏技术PM的专业咨询及咨询对应',
      descSection:
        'NHN是一家开发Gamebase并将其用于实际服务的游戏公司。 不仅是外部客户，我们的游戏也使用 Gamebase，所以我们对质量充满信心。 <br />我们凭借丰富的现场经验，快速应对开发者的咨询和请求。'
    },
    {
      titSection: 'Gamebase Setting Tool',
      descSection:
        '使用Gamebase的重要原因是与时间赛跑，对吧？ 下载SDK、复制、设置...<br />Gamebase 在Unity Menu中提供Integrated的配置工具来节省这个时间，只需点击几下就可以设置游戏信息。'
    }
  ],
  // service1
  lstItem: [
    {
      emText: '登录/认证',
      pText:
        'Google, Apple ID, Apple Game Center, Facebook, Twitter, kakao, NAVER, LINE, PAYCO, Hangame, Guest, 会员登录'
    },
    {
      emText: '支付/道具',
      pText:
        '为各类应用商店提供应用内支付和道具管理功能。Google Play, App Store, ONE Store, Galaxy Store, Amazon, HUAWEI, MyCard'
    },
    {
      emText: '指标/统计',
      pText: '提供游戏事业或运营决策中必不可缺的各种指标，实现用户，销售，游戏平衡的多角度分析。'
    },
    {
      emText: '维护/PUSH',
      pText:
        '提供不同客户端版本、不同国家的维护更新功能，还可简便地使用游戏用户对象的Push服务（重复发送、定时发送、定向发送）'
    },
    {
      emText: '游戏运营工具',
      pText:
        '提供蕴含了NHN10年经验的各种游戏运营工具。 请体验用户管理，APP版本管理，不良用户制裁等游戏运营中必不可缺的核心功能。'
    },
    {
      emText: '游戏安全',
      pText:
        '从防止各种滥用开始，按照可以保护应用程序安全的移动应用程序的安全解决方案，使用检测黑客工具、防御伪造代码服务等功能，实现在危险的情况下安全运营。'
    },
    {
      emText: '排行榜',
      pText: '可使用每日/每周/每月等各种排行服务，为游戏用户提供各种趣味元素。'
    },
    {
      emText: '下载加速',
      pText: '可以更快、更安全地下载安装及运行游戏所需的资源。请试试通过快速下载来提升用户满意度吧！'
    },
    {
      emText: '优惠券服务',
      pText: '您可以创建和管理要支付给用户的优惠券。 可以进行serial 优惠券、Keyword优惠券等各种营销活动。'
    },
    {
      emText: '客服中心',
      pText: '您可以处理客户查询并管理,可以通过客服中心页面提供的通知和常见问题解答等设置。'
    }
  ],
  // service2
  lstBigItem: [
    {
      emText: '实时指标',
      imgSrc: '/resources/images/pc/img_indicator1.png',
      pText: '提供实时的在线用户状态图表 (以分钟为单位，实时统计)，提供销售量, PU, ARPU, ARPPU等主要指标的实时显示板'
    },
    {
      emText: '销售指标',
      imgSrc: '/resources/images/pc/img_indicator2.png',
      pText: '对支付金额，支付用户提供多种分析功能，提供道具的销售指标，首次购买道具的指标等销售分析指标'
    },
    {
      emText: '用户指标',
      imgSrc: '/resources/images/pc/img_indicator3.png',
      pText: '按国家/OS/商店，准确和专业地分析主要的KPI指标，提供用户流入，脱离，留存, LTV等用户指标的分析'
    },
    {
      emText: '平衡指标',
      imgSrc: '/resources/images/pc/img_indicator4.png',
      pText: '提供按游戏等级的用户，以及销售分析指标，提供各等级的达成时间，首次购买道具等游戏平衡所需的指标'
    }
  ],
  // service3
  groupAdvantage: [
    {
      titAdvantageMirror: 'BUSINESS <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-03.svg',
      lstAdvantage: [
        { spanText: '业内最低收费制' },
        { spanText: '免费提供游戏专用<br />分析指标！' },
        { spanText: 'NHN实战<br />游戏平台' },
        { spanText: '手游在PC上运行！<br />提供混合平台！' }
      ]
    },
    {
      titAdvantageMirror: 'DEVELOPMENT <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-04.svg',
      lstAdvantage: [
        { spanText: '开发周期缩短（共同功能开发所需的人力和工时最小化）' },
        { spanText: '游戏技术PM技术支持' },
        { spanText: '365天稳定<br />不间断服务' },
        { spanText: '服务器可扩展性、<br />快速性' }
      ]
    }
  ],
  // service4
  map: {
    imgAlt: '世界地图',
    lstGlobal: [
      { spanText: ['USA', 'Hello!', 'UTC-06:00'] },
      { spanText: ['Korea', '안녕!', 'UTC-09:00'] },
      { spanText: ['Japan', 'こんにちは！', 'UTC-09:00'] },
      { spanText: ['China', '你好!', 'UTC-08:00'] }
    ]
  },
  // service5
  lstSpItem: [
    {
      emText: '开发人员',
      spanText: '根据熟练程度需要5MM~10MM的开发人员',
      pText: '（内部调查标准）<认证开发/结算开发/排名开发/通知/定期精检/不良用户/运营工具开发/指标开发/监控'
    },
    {
      emText: '开发期间',
      spanText: '开发期增长因素',
      pText: '登录认证公司越多 / 更新的操作系统越多，例如 AOS 和 iOS / 需要更复杂的操作工具 / 需要更深入的指标'
    },
    {
      emText: '费用增加',
      spanText: '开发人力投入=费用<br />开发周期/管理问题=费用<br />服务器/存储=费用',
      pText: ''
    }
  ],
  boxSp: '通过引入Gamebase SDK，可以大大降低开发成本！',
  // service6
  lstLicence: [
    { spanText: '信息安全管理体系ISMS认证' },
    { spanText: '云计算服务安全认证 CSAP认证' },
    { spanText: '信息安全管理体系ISMS认证' },
    { spanText: '云服务信息安全管理体系ISO/IEC 27017认证' },
    { spanText: '云服务个人信息管理体系ISO/IEC 27018认证' },
    { spanText: '国际信息安全管理体系ISO/IEC 27001认证' },
    { spanText: '信息安全管理体系ISMS认证' }
  ],
  // service7
  tech: {
    titSubSection: '强大的技术支持',
    descSection: '我们通过Hangame、Bugs、PAYCO等服务积累了10多年的IT运营经验，提供稳定的服务。',
    lstTech: [
      {
        emText: '咨询服务',
        spanText: '构建云导入战略/支持数据迁移'
      },
      {
        emText: '专业工程师',
        spanText: '安排客户专属服务团队/24小时, 365日提供服务'
      },
      {
        emText: '安全服务',
        spanText: 'Web安全检查 安全控制服务 CERT服务'
      },
      {
        emText: '监控服务',
        spanText: '提供监控解决方案/临界值设定和通知设定'
      },
      {
        emText: 'DDos支持服务',
        spanText: '实时监控和DDos设备支持'
      },
      {
        emText: 'IT管​​理服务',
        spanText: '由专业工程师提供的IT管理服务'
      }
    ]
  },
  // service8
  itemConsole: {
    strongText: '使用Gamebase Setting Tool快速轻松地安装SDK！',
    imgAlt: 'Gamebase Setting Tool 画面',
    aText: 'Gamebase 产品咨询',
    spanText: '快捷方式',
    linkEnd: 'Gamebase 产品咨询'
  }
};
