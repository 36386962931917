export default {
  titSection: 'Notice',
  descSection: '我们提供有关游戏平台故障、检查和指导的信息。', 
  term: {
    open: '进行中', 
    close: '完成'
  }, 
  boardMore: '查看更多公告事项', 
  buttonList: '目录'
};
