export default {
  titTop: 'Log & Crash Search',
  emphTop: '게임 로그와 크래시를 관리하고 분석!',
  descTop: 'Log & Crash Search는 클라이언트와 서버의 로그를 수집하여 원하는 로그를 검색하고 조회하는 시스템입니다. ',
  titSection: '특징',
  descSection:
    '모바일 앱에서 발생하는 크래시를 분석하고 통계 작업을 수행하여 크래시 발생 원인에 대한 다양한 정보도 제공합니다.',
  skill: [
    {
      titSubSection: '주요기능',
      lstSkill: [
        {
          pText: '게임 서버에서 발생하는 대량의 로그로 인한 서비스 영향도를 제거할 수 있습니다.'
        },
        {
          pText: '배포된 모든 클라이언트에서 발생하는 오류를 한곳에 모아서 조회하고 분석할 수 있습니다.'
        },
        {
          pText: '대량의 로그에서 원하는 로그를 빠르게 검색할 수 있고, 패턴별로 로그를 조회할 수 있습니다.'
        },
        {
          pText: '로그와 크래시를 5분 단위로 모니터링할 수 있습니다.'
        },
        {
          pText: '서비스별 삭제, 다양한 로그 유형별로 조회/분석 기능을 제공합니다.'
        },
        {
          pText: '오류, 크래시 덤프, 웹 애플리케이션 로그, 사용자 정의 메시지 형식 등을 지원합니다.'
        },
        {
          pText:
            '프로젝트, 호스트, 로그 유형, 로그 수준별로 로그 개수를 볼 수 있으며, 로그 검색 페이지에서 시간대별 로그 추이를 차트로 확인할 수 있습니다.'
        },
        {
          pText: '로그 본문에서 쿼리에 일치하는 단어가 강조 표시되므로 쉽게 확인할 수 있습니다.'
        }
      ]
    },
    {
      titSubSection: '서비스 대상',
      lstSkill: [
        {
          spanClass: 'brand_target_log',
          pText: 'Apache, Tomcat 등의 웹 로그가 많이 쌓이는 경우'
        },
        {
          spanClass: 'brand_no_server',
          pText: '서버 로그를 서버에 접속하지 않고 봐야 하는 경우'
        },
        {
          spanClass: 'brand_target_collect',
          pText: '사용자에게 배포한 앱에서 발생한 로그를 한곳에서 취합하여 봐야 하는 경우'
        },
        {
          spanClass: 'brand_error_log',
          pText: '서비스에서 발생한 오류 로그의 추이를 확인해야 하는 경우'
        }
      ]
    }
  ],
  aTag: [
    { href: 'https://www.toast.com/kr/service/security/nhn-appguard', aText: 'AppGuard 서비스 바로가기' },
    {
      href: 'https://www.toast.com/kr/service/analytics/log-crash-search',
      aText: 'Log & Crash Search 서비스 바로가기'
    },
    { href: 'https://www.toast.com/kr/service/compute/instance', aText: 'Instance 서비스 바로가기' }
  ]
};
