export default {
  titSection: '제품 상담',
  descSection:
    '게임플랫폼 전반 이용 문의, 제품 도입 상담, 각종 제휴 문의가 있다면 연락처를 남겨주세요. 담당자가 확인 후 연락드리겠습니다.',
  contactName: '이름',
  contactPhone: '연락처',
  nameTxtAttention: '이름은 필수 입력 항목입니다.',
  nameRegexError: '이름은 한글이나 영어만 가능합니다.',
  phoneTxtAttention: '전화번호는 필수 입력 항목입니다.',
  phoneRegexError: '전화번호는 11자의 숫자와 하이픈(-) 만 입력 가능합니다.',
  mailTxtAttention: '이메일은 필수 입력 항목입니다.',
  contactMail: '이메일',
  contactMailDomainSelf: '직접입력',
  contactCompany: '회사명',
  contactLike: '문의 내용',
  // descContact:
  //   '수집하는 개인 정보[(필수) 이름, 연락처는 고객상담을 위해 사용되며, 관련 법령에 따라 보관 후 삭제됩니다. 동의를 하지 않으시면, 상담 이용이 제한 될 수 있습니다.',
  tfCheckbox:
    '<span class="ico_game ico_check"></span>고객상담을 위해 (필수) 이름, 휴대폰번호, 이메일, (선택) 회사명, 문의 내용을 수집하며, <em class="emph_g">관련 법령에 따라 3년간 보관됩니다.</em><br class="view_pc"> 동의를 거부하실 수 있으나, 선택하신 서비스를 제공받기 위해서는 동의가 필요합니다.',
  txtAttention: '개인정보 수집 및 이용에 대한 안내에 동의해주세요.',
  titContactDone: '제품 상담 문의가<br />정상적으로 접수 되었습니다.',
  descContactDone: '보내주신 문의에 최대한 빠르고 정확한 답변을 드리기 위해 노력하겠습니다.<br />감사합니다.',
  mailSandApiError: '메일 발송에 실패했습니다.'
};
