export default {
  titSection: 'Notice',
  descSection: '게임플랫폼의 장애, 점검, 안내 등을 전해드립니다.', 
  term: {
    open: '진행중', 
    close: '완료'
  }, 
  boardMore: '공지사항 더보기', 
  buttonList: '목록'
};
