export default {
  titTop: 'Gamebase',
  emphTop: 'Quick and easy application through integrated SDK!',
  descTop:
    'Features commonly needed in games can be provided with the integrated SDK<br>to make development and management easy and efficient.',
  sectionService: [
    {
      titSection: 'Key features',
      descSection:
        'Gamebase is a platform specialized in launching and managing games.<br>Just apply Gamebase SDK, and development of core features for game (e.g. Login/payment/item/index) will be easier than ever.'
    },
    {
      titSection: 'Robust analysis index',
      descSection:
        'Are you paying extra for an analysis platform with financial analysis?<br /> Gamebase provides from basic indexes such as real-time DAU, PU, and ARPU to crucial analysis indexes such as LTV, Retention, level, index and server state.'
    },
    {
      titSection: 'Business/Development Advantage',
      descSection: ''
    },
    {
      titSection: 'The optimal game solution for global business',
      descSection:
        'Gamebase supports global launch of games, providing the below functions for game operations in global environments:',
      desc_map:
        'All pushes and notifications to the game users can be processed multilingually.<br />Country filtering is available.<br />Choose the managers’ local time zone for simple and easy time setup.'
    },
    {
      titSection: 'Reduction on development cycle and resources',
      descSection:
        'No more countless SDK analyses and developments before launching games! <br />Gamebase SDK application alone easily implements many features.<br />Let Gamebase handle the common features, and concentrate your development resources on improving the quality of your games!',
      desc_map:
        'All pushes and notifications to the game users can be processed multilingually.<br />Country filtering is available.<br />Choose the managers’ local time zone for simple and easy time setup.'
    },
    {
      titSection: 'Robust service reliability and security',
      descSection:
        'Ever since its official launch in 2016, Gamebase has been serving reliably without any major obstacles. <br />Moreover, NHN has top-notch management overall for the System security certification and personal information to make game management possible without risks of security threats and hacking.'
    },
    {
      titSection: 'Professional consultation and inquiry response from game tech PM’s',
      descSection:
        'As a game company, NHN developed Gamebase and actually puts it to service. Not only the external clients’ games <br />but also our own games are using Gamebase, so we are confident with its quality. <br />Response to developers’ inquiries and demands will be swift thanks to extensive field experience.'
    },
    {
      titSection: 'Gamebase Setting Tool',
      descSection:
        "Time is always an issue, and that's why Gamebase is right for you! Downloading, copying, and setting SDK can take up a lot of time.<br />To save up this time, Gamebase provides integrated Setting Tool on Unity Menu, so setting game information is only a few clicks away."
    }
  ],
  // service1
  lstItem: [
    {
      emText: 'Login/Verification',
      pText:
        'Google, Apple ID, Apple Game Center, Facebook , Twitter, kakao, NAVER, LINE, PAYCO, Hangame, Guest, Gamebase member login'
    },
    {
      emText: 'Payment/Items',
      pText:
        'Provides in-app payments and item management features for various app stores (e.g. Google Play, App Store, ONE Store, Galaxy Store, Amazon, HUAWEI, MyCard).'
    },
    {
      emText: 'Indexes/Statistics',
      pText:
        'Provides various indexes necessary on making business/management decisions for your games to make possible multifaceted analysis on users, revenues, balance, etc.'
    },
    {
      emText: 'Maintenance/PUSH',
      pText:
        'Provides maintenance features for each client version and country. Push service for users (including repeated, scheduled, and targeted sending) does make sending much easier.'
    },
    {
      emText: 'Game Management Tools',
      pText:
        'Provides a variety of game management tools, the results of NHN’s ten years of experience. Experience the core features necessary for the game management including user management, app version management, abuser restriction, etc.'
    },
    {
      emText: 'Game Security',
      pText:
        'The mobile app security solutions keep your apps safe from various abuses and help you manage your games safely with hacking tool detection and code tampering protection.'
    },
    {
      emText: 'Leaderboards',
      pText:
        'Run a variety of daily, weekly, monthly ranking services to keep the games fun for users and manage your games reliably.'
    },
    {
      emText: 'Accelerated Downloads',
      pText:
        'Resources used for game setup and execution can be downloaded more rapidly and reliably. Faster downloads lead to higher user satisfaction!'
    },
    {
      emText: 'Coupon Service',
      pText:
        'Generate and manage coupons for your users. Market your games with various promotions such as serial coupons and keyword coupons.'
    },
    {
      emText: 'Customer Services',
      pText:
        'Respond to customer inquiries and manage settings for notices, FAQ, etc. provided through the Customer Center page.'
    }
  ],
  // service2
  lstBigItem: [
    {
      emText: 'Real-time Indexes',
      imgSrc: '/resources/images/pc/img_eng_indicator1.png',
      pText:
        'Real-time concurrent user status graph provided (Real-time, renewed every minute) Real-time dashboard provided for main indexes including revenues, PU, ARPU, ARPPU, etc.'
    },
    {
      emText: 'Revenue Indexes',
      imgSrc: '/resources/images/pc/img_eng_indicator2.png',
      pText:
        'Various analytics features provided for payment amount and premium users Provides revenue analysis indexes such as sales index for different items, first item purchase index, etc.'
    },
    {
      emText: 'User Indexes',
      imgSrc: '/resources/images/pc/img_eng_indicator3.png',
      pText:
        'Main KPI indexes analyzed accurately and professionally for different countries, OSs, app stores, etc. Expanded indexes for analyses on user influx and churn, retention, and LTV.'
    },
    {
      emText: 'Balancing Indexes',
      imgSrc: '/resources/images/pc/img_eng_indicator4.png',
      pText:
        'Analysis indexes provided for users of different game levels and revenue Index for level achievement time, first item purchased, etc. provided for use in game balancing'
    }
  ],
  // service3
  groupAdvantage: [
    {
      titAdvantageMirror: 'BUSINESS <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-03.svg',
      lstAdvantage: [
        { spanText: 'The lowest price in the market' },
        { spanText: 'Free analytic indexes<br />dedicated to games!' },
        { spanText: 'NHN-proven<br />game platform' },
        { spanText: 'Mobile games on PC!<br />Hybrid platform provided!' }
      ]
    },
    {
      titAdvantageMirror: 'DEVELOPMENT <br />ADVANTAGES',
      imgSrc: '/resources/images/pc/chara-male-04.svg',
      lstAdvantage: [
        { spanText: 'Shorten development period (Free human resources and expenses from developing common features) ' },
        { spanText: "Tech support from game tech PM's" },
        { spanText: 'Reliable service <br />provided 24/7 uninterrupted' },
        { spanText: 'Server expansion<br />made easy and fast' }
      ]
    }
  ],
  // service4
  map: {
    imgAlt: 'World map',
    lstGlobal: [
      { spanText: ['USA', 'Hello!', 'UTC-06:00'] },
      { spanText: ['Korea', '안녕!', 'UTC-09:00'] },
      { spanText: ['Japan', 'こんにちは！', 'UTC-09:00'] },
      { spanText: ['China', '你好!', 'UTC-08:00'] }
    ]
  },
  // service5
  lstSpItem: [
    {
      emText: 'Development Human Resources',
      spanText: '5MM-10MM of human resources required for development depending on expertise level',
      pText:
        '(According to internal research)<br />Verification development / Payment development / Ranking development / Notice / Scheduled maintenance / Abusers / Management tool development / Index development / Monitoring'
    },
    {
      emText: 'Development Period',
      spanText: 'Causes for longer development period',
      pText:
        'More login verification businesses / More OS updates for AOS, iOS, etc. / Necessity for delicate management tool / Necessity for in-depth indexes'
    },
    {
      emText: 'Cost Increase',
      spanText: 'Assigning developers = cost<br />Longer development/management = cost<br />Server/storage = cost',
      pText: ''
    }
  ],
  boxSp: 'Gamebase SDK can cut development cost significantly!',
  // service6
  lstLicence: [
    { spanText: 'Information Protection Management System ISMS Certified' },
    { spanText: 'Cloud Computing Service Security Certified CSAP Certified' },
    { spanText: 'Information Protection Management System ISMS Certified' },
    { spanText: 'Cloud Service Information Protection Management System ISO/IEC 27017 Certified' },
    { spanText: 'Cloud Service Personal Information Management System ISO/IEC 27018 Certified' },
    { spanText: 'International Information Protection Management System ISO/IEC 27001 Certified' },
    { spanText: 'Information Protection Management System ISMS Certified' }
  ],
  // service7
  tech: {
    titSubSection: 'Robust Tech Support',
    descSection:
      'More than ten years of IT management experience accumulated through servicing Hangame, Bugs, and PAYCO made way for reliable services.',
    lstTech: [
      {
        emText: 'Consulting Services',
        spanText: 'Cloud implementation strategy / Migration support'
      },
      {
        emText: 'Professional Engineers',
        spanText: 'Dedicated human resources / Support service available 24/7'
      },
      {
        emText: 'Security Services',
        spanText: 'Web security review Security control service CERT service'
      },
      {
        emText: 'Monitoring Services',
        spanText: 'Monitoring solution / Threshold setting and alarm setting'
      },
      {
        emText: 'DDoS support service',
        spanText: 'Real-time surveillance and DDoS equipment support'
      },
      {
        emText: 'IT Management Service',
        spanText: 'IT management service from professional engineers'
      }
    ]
  },
  // service8
  itemConsole: {
    strongText: 'Installing SDK is quick and easy with our Gamebase Setting Tool!',
    imgAlt: 'Gamebase Setting Tool Screen',
    aText: 'Gamebase Product Consultation',
    spanText: 'Shortcut',
    linkEnd: 'Gamebase Product Consultation'
  }
};
