export default {
  lstLegal: [
    { title: '公司介绍', href: 'https://www.nhn.com/ko/index.nhn' },
    { title: '使用条款', href: 'https://www.toast.com/kr/terms/terms-service' },
    { title: '个人信息处理方针', href: 'https://www.toast.com/kr/terms/privacy-policy' },
    { title: '产品咨询', href: '' }
  ],
  familySite: 'Family Site',
  more: 'more',
  familySiteSub: [
    { title: 'NHN Cloud', href: 'https://www.toast.com/kr' },
    { title: 'TOAST G', href: 'https://gov.toast.com/' },
    { title: 'TOAST CAM', href: 'http://cam.toast.com' },
    { title: 'TOAST CAM Biz', href: 'http://bizcam.toast.com' },
    { title: 'TOAST bill', href: 'https://bill.toast.com' },
    { title: 'Dooray!', href: 'http://dooray.com' },
    { title: 'PAYCO', href: 'http://www.payco.com' },
    { title: 'PAYCO 开发者中心', href: 'https://developers.payco.com' },
    { title: 'Hangame', href: 'http://www.hangame.com' },
    { title: 'NHN Corp.', href: 'https://www.nhn.com' }
  ],
  footerInfo: [
    '公司名:NHN Cloud CEO:Paik Domin, Kim DongHoon 地址:NHN Play Museum, 16 Daewangpangyo-ro 645beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, 13487, Korea',
    '客服中心:1588-7967 邮箱:support@nhn.com 营业执照号:424-88-02352 通信销售业申报编号:2013-京畿城南-1067号' +
      '<a href="http://www.ftc.go.kr/www/bizCommView.do?key=232&apv_perm_no=2013378021930201084&pageUnit=10&searchCnd=wrkr_no&searchKrwd=1448115549&pageIndex=1" target="_blank" class=" link_info">确认商家信息</a>'
  ],
  txtCopyright: 'ⓒ NHN Cloud Corp. All rights reserved.'
};
