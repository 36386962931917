export default {
  emphTop:
    'Enjoy the core features of Gamebase particularly designed for game development and multi-faceted indexes for administration and content analysis.',
  linkShortcut: 'Go to demo project <span class="ico_game ico_shortcut"></span>',
  sectionServicetit: 'Sign up for demo project account',
  sectionServiceDesc:
    'Provide us with your name, email address, company name, and ID. Then, we will provide you with a project account for the Gamebase demo version.',
  contactName: 'Name',
  contactMail: 'Email',
  mailTxtAttention: 'Used to deliver email regarding password change.',
  contactCompany: 'Company',
  agree1: '(Required) I am 14 years of age or older.',
  agree2:
    '<span class="ico_game ico_check"></span>(Required) NHN collects information such as your name, email address, company name, and ID to submit your application for the Gamebase demo account and <em class="emph_g">your information is retained for 30 days from the date of collection.</em><br class="view_pc" /> You may decline the collection of your information, but doing so will limit your use of our services.',
  // done
  titDemoDone: 'Gamebase demo application<br />successfully submitted.',
  descDemoDone:
    'From your registered email address (hong_gildong@nhn.com)<br />' +
    'Change password first to be able to log in to <em class="emph_primary">Console</em>.',
  mailSandApiError: 'Mail delivery failed.',
  alreadyJoinError: 'ID already in use.'
};
