export default {
  titSection: 'Notice',
  descSection: "We provide the game platform's failure, maintenance, announcements, etc.",
  term: {
    open: 'In progress',
    close: 'Done'
  },
  boardMore: 'More notices',
  buttonList: 'List'
};
