export default {
  sectionTop: {
    titTop: 'Pricing',
    emphTop: '提供实用的服务构成和合理的收费制度。'
  },
  sectionService1: {
    titSection: 'Gamebase费用',
    coverPrice: {
      descSection:
        'Gamebase提供最高水平的游戏平台，不设收费标准。<br>' +
        '尽情享用游戏指标、push、客服中心、优惠券、退款滥用、排名等游戏运营所必需的功能吧！<br>' +
        'Games with a cumulative DAU of 30,000 or less are provided free for 6 months, so try them at an affordable price!'
    },
    tblPrice: {
      caption: '基于 DAU 累积的 Gamebase 费用'
    },
    thead: {
      tr: {
        th: ['计费分类', '计费部分', '计费标准', '价格']
      }
    },
    tbody: {
      tr1: {
        th: ['Cumulative DAU<br>(daily active user)','200,000 or less', 'Monthly paid subscription', '1,000,000 KRW/month']
      },
      tr2: {
        th: ['More than 200,000', 'Per DAU', '5 KRW']
      }
    },
    lstDot: {
      li: [
        '• Customers who create a new project after September 1, 2023, and use Gamebase can use the service only with the new pricing plan.',
        '• Only customers who create a new project after September 1, 2023, and use Gamebase can use it for free for 6 months with monthly cumulative DAU of 30,000 or less.'
      ]
    },
    lstDot2: {
      li: [
        '- As soon as the monthly cumulative DAU of a newly created project exceeds 30,000, you will be excluded from the free use and will be charged from that month.<br>(even if the DAU falls below 30,000 again, you are still excluded from the free use)',
        '- The free use period applies for 6 months from the month including the new project creation date.',
        '- Example) Newly activated projects on September 12, 2023, are available for free until February 2024. If the monthly cumulative DAU exceeds 30,000 in November 2023, the free use will end, and you will be charged from November 2023.'
      ]
    },
    lstDot3: {
      li: [
        '• Billing is the sum of the DAU of the month of use (Note: DAU average = cumulative DAU / 30 days).',
        '• The Gamebase service fee includes the service fees for Push, IAP, and Leaderboard except for the NHN AppGuard service.',
        '• Technical support and technical training are provided free of charge until the game is released, and  if you contact with gameplatform@nhn.com.'
      ]
    },
    tblPrice2: {
      caption: '按功能划分的范围和费用',
      thead: {
        tr: {
          th: ['支援分类', '功能', '提供范围', '价格']
        }
      },
      tbody: {
        tr1: {
          td: ['Gamebase提供功能表', '(无差别提供)', 'Push(包括全球)', '无限制', '免费']
        },
        tr2: {
          td: ['IAP/结算', '无限制', '免费']
        },
        tr3: {
          td: ['Leaderboard', '无限制', '免费']
        },
        tr4: {
          td: ['会员/验证', '无限制', '免费']
        },
        tr5: {
          td: ['优惠券', '无限制', '免费']
        },
        tr6: {
          td: ['客户中心（常见问题、条款、电子邮件）', '无限制', '免费']
        },
        tr7: {
          td: ['指标/统计', '无限制', '免费']
        },
        tr8: {
          td: ['移动应用程序', '无限制', '免费']
        },
        tr9: {
          td: ['技术支持', 'Gamebase 联动的技术支持和咨询', '无限制', '免费']
        }
      }
    }
  },
  coverSimulator: {
    titSubSection: '费用计算器：您可以提前模拟预估费用。',
    groupSimulator1: {
      titSimulator: '预计 DAU（月累计）',
      txtTf: 'DAU',
      btnSearch: '查询',
      btnRefresh: '初始化'
    },
    groupSimulator2: {
      titSimulator: '预计费用(月)',
      txtTf: '韩元/月',
      txtVat: '不含增值税'
    },
    lstDot: {
      li: [
        '• Billing is the sum of the DAU of the month of use (Note: DAU average = cumulative DAU / 30 days).',
        '• A monthly cumulative DAU of 30,000 or less is free for 6 months.',
        '• If the free zone is exceeded, the entire DAU will be charged<br>Example) When using DAU accumulated 31,000, the charge is KRW 1,000,000',
        '• The Gamebase service fee includes the service fees for Push, IAP, and Leaderboard except for the NHN AppGuard service.',
        '• Technical support and technical training are provided free of charge until the game is released, and  if you contact with gameplatform@nhn.com.'
      ]
    }
  },
  coverSla: {
    titSubSection: 'SLA : Service Level Agreement',
    descSection: '就Gamebase服务而言,因公司责任原因无法使用Gamebase认证功能时，损害赔偿标准为' + 
    '如下表所示。',
    tblSla: {
      caption: 'Gamebase 服务的补偿标准',
      thead: {
        tr: {
          th: ['月可用性', '损失赔偿金']
        }
      },
      tbody: {
        tr1: {
          th: ['99%以上 99.9%以下', '相当于3个月平均使用金额的10%']
        },
        tr2: {
          th: ['95%以上 不到99%', '相当于3个月平均使用金额的25%']
        },
        tr3: {
          th: ['不到95.0%', '相当于三个月平均使用金额的50%']
        }
      }
    },
    lstDot: {
      li: [
        '※以下情况不适用于服务水平的测量时间。',
        '• 服务用户和服务提供商事先商定的Gamebase停机时间',
        '• Gamebase 维护所需的时间，例如更新系统软件的时间、Gamebase 变更作业时间等',
        '• 服务提供商无法控制的情况下的停机时间',
        '• 因服务用户未提前通知服务提供商而进行信息系统变更而导致的故障时间',
        '• 发生在服务提供商职责和角色范围之外的故障时间，如提供 OAuth 身份验证的外部服务故障',
        '• 障碍领域是服务提供者还是服务使用者尚不清楚的障碍时间',
        '• 由于自然灾害和国家紧急情况等造成的障碍时间'
      ]
    }
  },
  sectionService2: {
    titSection: 'Leaderboard费用',
    thead: {
      tr: {
        th: ['计费分类', '计费区间', '计费标准', '价格']
      }
    },
    tbody: {
      tr1: {
        td: ['API 调用', '少于 100,000 次调用', '免费', '0 韩元']
      },
      tr2: {
        td: ['低于10,000,000 次调用', '每次调用', '0.1 韩元']
      },
      tr3: {
        td: ['超过 10,000,000 次调用', '每次调用', '0.01 韩元']
      }
    },
    lstDot: {
      li: [
        '• 已启用Gamebase时，免收使用费。',
        '• 仅单独使用Leaderdboard时，另行收取上述费用。',
        '• API调用数以每月累计使用量为基准收取使用费。'
      ]
    }
  }
};
