import store from '@/store/modules/index';
import Vue from 'vue';
import VueRouter from 'vue-router';

import googleAnalytics from '@/helpers/ga';
import { lazyViewLoad } from '@/helpers/router';

import TodoList from '@/views/example/Todo.vue';

import homeRouter from './homeRouter';
import customerStoriesRouter from './customerStoriesRouter';
import serviceRouter from './serviceRouter';
import noticeNewsRouter from './noticeNewsRouter';
import downloadRouter from './downloadRouter';
import contactRouter from './contactRouter';
import partnerRouter from './partnerRouter';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: `/${store.getters['common/countryCode']}`
  },
  {
    path: '/crud',
    component: TodoList
  },
  // 홈
  homeRouter,
  // 서비스
  serviceRouter,
  // 고객 사례
  customerStoriesRouter,
  {
    path: '/:countryCode/services/gameanvil/license',
    component: lazyViewLoad('service/License'),
    meta: { contentClass: 'cont_license', seo: 'seo.service.gameAnvil' }
  },
  // 다운 로드
  downloadRouter,
  // 공지 소식
  noticeNewsRouter,
  // 제품 상담
  contactRouter,
  // 파트너
  partnerRouter,
  // 404
  {
    path: '*',
    component: lazyViewLoad('error/404'),
    meta: { contentClass: 'cont_error', seo: 'seo.noticeNews.press' }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

// ga
googleAnalytics(router);

export default router;
