import { initModelData, exportData } from '@/helpers/store';

export default class NoticeRecord {
  constructor(data) {
    initModelData(this._data, data);
  }

  _data = this.getDefaultData();

  getDefaultData() {
    return {
      noticeId: -1, //
      serviceId: '',
      status: '',
      categoryId: '',
      isTop: false,
      term: '',
      startDt: '',
      endDt: '',
      displayDt: '',
      attachmentYn: '',
      userId: -1,
      createdDt: -1,
      updatedDt: -1,
      contents: {
        ko: {},
        ja: {},
        en: {},
        zh: {}
      },
      tags: [{}],
      categoryName: '' // 카테고리
    };
  }

  get noticeId() {
    return this._data.noticeId;
  }

  get serviceId() {
    return this._data.serviceId;
  }

  get status() {
    return this._data.status;
  }

  get categoryId() {
    return this._data.categoryId;
  }

  get isTop() {
    return this._data.isTop;
  }

  get term() {
    return this._data.term;
  }

  get startDt() {
    return this._data.startDt;
  }

  get endDt() {
    return this._data.endDt;
  }

  get displayDt() {
    return this._data.displayDt;
  }

  get createdDt() {
    return this._data.createdDt;
  }

  get updatedDt() {
    return this._data.updatedDt;
  }

  get contents() {
    return this._data.contents;
  }

  get tags() {
    return this._data.tags;
  }

  get categoryName() {
    return this._data.categoryName;
  }

  exportData(omitKeys = []) {
    return exportData(this._data, omitKeys);
  }
}
