/**
 * 쓰로틀링
 * @param {function} callback
 * @param {number} delay
 */
export const throttle = (callback, delay = 500) => {
  let isThrottle = null;

  return function() {
    if (!isThrottle) {
      callback.apply(this, arguments);
      isThrottle = true;
      setTimeout(() => {
        isThrottle = false;
      }, delay);
    }
  };
};

/**
 * 디바운싱
 * @param {function} callback
 * @param {number} delay
 */
export const debounce = (callback, delay = 500) => {
  let isDebounce = null;

  return function() {
    clearTimeout(isDebounce);
    isDebounce = setTimeout(() => callback.apply(this, arguments), delay);
  };
};
