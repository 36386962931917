/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-params */
const isReal = DOMAIN === 'https://real.com';

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

export default vueRouter => {
  vueRouter.onReady(() => {
    (function(i, s, o, g, r, a, m) {
      i.GoogleAnalyticsObject = r;
      (i[r] =
        i[r] ||
        function() {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = Number(new Date()));
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', `https://www.google-analytics.com/analytics.js`, 'ga');

    // ga('create', 'GA 아이디', 'auto');

    if (isReal) {
      window.gtag = gtag;
      gtag('js', new Date());
      // gtag('config', 'GTAG 아이디');
    } else {
      ga('set', 'sendHitTask', null);
    }

    vueRouter.afterEach(to => {
      ga('set', 'page', to.fullPath);
      ga('send', 'pageview');
      // console.log('router change ga send page', to.fullPath);
      if (isReal) {
        // gtag('config', 'GTAG 아이디', { page: to.fullPath });
      }
    });
  });
};
