import { lazyLayoutLoad, lazyViewLoad } from '@/helpers/router';

export default {
  path: '/:countryCode',
  component: lazyLayoutLoad('HomeLayout'),
  redirect: '/:countryCode',
  children: [
    {
      path: '/:countryCode',
      component: lazyViewLoad('Home'),
      meta: { seo: 'seo.home' }
    }
  ]
};
