export default {
  tit_top: 'GameAnvil',
  emph_top: 'Game server development made easy!',
  desc_top1:
    'Use the game server template, web operation tool, and performance test tool provided by GameAnvil to handle game server development, service, and server operation at once.',
  desc_top2: '',
  section_service1: {
    tit_section: 'Key features',
    lst_gameanvil_char: [
      {
        emph_gameanvil_char: 'Easy server development',
        dsc_gameanvil_char1:
          'The game server development environment is ready immediately only by applying the provided template.',
        dsc_gameanvil_char2: ''
      },
      {
        emph_gameanvil_char: 'Support performance test tool',
        dsc_gameanvil_char1: 'Provided tools allows you to server performance test.',
        dsc_gameanvil_char2: ''
      },
      {
        emph_gameanvil_char: 'Support various operations',
        dsc_gameanvil_char1:
          'Web operation tools allow you to conveniently establish, distribute, and operate game servers.'
      }
    ]
  },
  section_service2: {
    tit_section: 'Real-time multiplayer game server development made easy!',
    desc_section:
      'GameAnvil is super simple for even novice developers to easily learn and adopt it right away, saving the game server development period significantly.' +
      'Use the game server template, web operation tool, and performance test tool provided by GameAnvil to handle game server development, service, and server operation at once.',
    lst_item: [
      {
        txt_info: 'The game server development environment is ready immediately only by applying the provided template.'
      },
      {
        txt_info: 'Concise and sequential code makes it easy for you to implement any desired functionality.'
      },
      {
        txt_info: 'Web operation tools allow you to conveniently establish, distribute, and operate game servers.'
      },
      {
        txt_info:
          'With GameAnvil, you may quickly identify and respond to issues through web console operation and monitoring.'
      },
      {
        txt_info: 'Design reliable services using performance testing tools.'
      },
      {
        txt_info: "The available servers are automatically added according to the game users' connection status."
      },
      {
        txt_info: 'It can easily be integrated with the client under development.'
      },
      {
        txt_info: 'APIs for easy DB and Redis use.'
      }
    ]
  },
  section_service3: {
    tit_section: 'Game development in various fields',
    lst_item: [
      {
        emph_info: 'Multi-based N:N connection game',
        txt_info1:
          'Develop contents that allows concurrent users to collaborate or compete against in a real time. Use GameAnvil to create thrilling games with a variety of users!',
        txt_info2: ''
      },
      {
        emph_info: 'Turn-based and board games',
        txt_info1:
          'You can develop a turn-based and board game utilizing lobby and channels provided by GameAnvil. Enjoy the convenience of GameAnvil, where you can freely set channels and match users with similar skills!',
        txt_info2: ''
      },
      {
        emph_info: 'Single player game',
        txt_info1:
          'GameAnvil enables you to produce and operate single-user games easily and conveniently. Not only is it easy to create single-user games, but it also solves management problems all at once.',
        txt_info2: ''
      }
    ]
  },
  section_service4: {
    tit_section: 'Provided features',
    table: {
      caption: 'GameAnvil의 제공 기능 설명',
      thead_tr_th1: 'Features',
      thead_tr_th2: 'Description',
      tbody: {
        tr: [
          {
            td1: 'Manage server',
            td2:
              'You can conveniently configure the server and deploy, run, and suspend the server from the web console.'
          },
          { td1: 'Audit', td2: 'You can inquire about events that occurred on the server.' },
          { td1: 'Manage channel', td2: 'Users are free to configure server channels.' },
          {
            td1: 'Match-making',
            td2:
              'According to the matching rules set in the game, users of similar level are matched with one another. '
          },
          {
            td1: 'Manage client session',
            td2:
              'If you get disconnected while playing the game, everything will be fully and automatically restored to your original session.'
          },
          { td1: 'Support non-sync DB query', td2: 'You can easily configure asynchronous DB queries.' },
          { td1: 'Safe Pause', td2: 'You may perform inspection without suspending the service. ' },
          { td1: 'Monitoring', td2: 'You can check the server status and user distribution at a glance in real time.' }
        ]
      }
    }
  },
  section_service5: {
    tit_section: 'Game server engine comparison',
    table: {
      caption: '게임서버 엔진 타사와의 기능 비교',
      thead_tr_th1: 'Features',
      thead_tr_th2: 'GameAnvil',
      thead_tr_th3: 'Company A',
      thead_tr_th4: 'Company B',
      tbody: {
        tr: [
          { td1: 'Supported OS', td2: 'Windows, Linux, Mac', td3: 'Windows, Linux', td4: 'Windows' },
          { td1: 'Manage connection', td2: '지원', td3: '지원불가능', td4: '일부지원' },
          { td1: 'Support various server types', td2: '지원', td3: '일부지원', td4: '지원' },
          { td1: 'Async support', td2: '지원', td3: '지원불가능', td4: '지원' },
          { td1: 'Lobby', td2: '지원', td3: '지원불가능', td4: '지원' },
          { td1: 'Channel', td2: '지원', td3: '지원불가능', td4: '지원불가능' },
          { td1: 'Performance test tool', td2: '지원', td3: '지원불가능', td4: '지원' }
        ]
      }
    }
  },
  section_end: {
    link_end: 'GameAnvil Product Consultation',
    spanText: 'Shortcut'
  }
};
