import Vue from 'vue';
import { sync } from 'vuex-router-sync';

// CSS
import '@/assets/scss/app.scss';
// 플러그인
import '@/addon/index';
// 폴리필
import '@/polyfill/index';
// PWA
import '@/registerServiceWorker';
// 다국어
import i18n from '@/i18n';
// 모달 
import modal from '@/modal';
// 스토어
import store from '@/store/modules';
// 뷰 모드 감지 (mobile, tablet, desktop)
import '@/helpers/detectViewMode';
// 라우터
import router from '@/router';
// 루트 컴포넌트
import App from '@/App.vue';

Vue.config.productionTip = false;

// 전역 노출
window.__app = window.__app || {};
window.__app.$store = store;
window.__app.$router = router;

sync(store, router);

new Vue({
  i18n, 
  modal, 
  router,
  store,
  render: h => h(App)
}).$mount('#app');
