/* eslint-disable complexity */
import axios from 'axios';

const cancelSources = {};

const createAxiosOptions = ({
  method,
  url,
  data,
  params,
  header = {
    'content-type': 'application/json'
  },
  extendHeader,
  // withCredentials = true,
  takeLatest = true,
  cancelToken
}) => {
  const options = {
    method,
    url,
    headers: {}
    // withCredentials
  };
  if (header) {
    Object.assign(options.headers, header);
  }
  if (extendHeader) {
    Object.assign(options.headers, extendHeader);
  }
  if (params) {
    options.params = params;
  }
  if (data) {
    options.data = data;
  }
  // 중복 요청 리소스 옵션 추가
  if (takeLatest && cancelToken) {
    options.cancelToken = cancelToken;
  }

  return options;
};

export default async (params, takeLatest = true) => {
  try {
    const { url } = params;
    const axiosOptionsParams = createAxiosOptions({ ...params, takeLatest });

    // 중복 요청 리소스 옵션 추가
    if (takeLatest) {
      if (cancelSources[url]) {
        cancelSources[url].cancel('Duplicate request :: Start new request, stop active request');
      }
      cancelSources[url] = axios.CancelToken.source();
      axiosOptionsParams.cancelToken = cancelSources[url].token;
    }

    // axios 요청
    const response = await axios(axiosOptionsParams);
    const resData = response.data;
    const { resultCode, isSuccessful } = resData.header;

    // 중복 요청 리소스 제거
    if (cancelSources[url]) {
      delete cancelSources[url];
    }

    // 성공
    if (isSuccessful) {
      return await Promise.resolve(resData);
    }

    // 실패 결과 반환
    return Promise.reject({
      header: {
        resultCode,
        isSuccessful
      }
    });
  } catch (error) {
    if (takeLatest && !axios.isCancel(error)) {
      let title = error.request.status;
      let description = '알 수 없는 오류';

      const parseErrorResponse = JSON.parse(error.request.responseText);
      if (parseErrorResponse?.header) {
        const { resultCode, resultMessage } = parseErrorResponse.header;
        title = resultCode;
        description = resultMessage;
      }

      // 알수 없는 오류
      console.error(
        `${JSON.stringfy({
          type: 'error',
          title,
          description
        })}`
      );
    }

    // 알 수 없는 실패 결과 반환
    return Promise.reject({
      header: {
        resultCode: 99999,
        isSuccessful: false
      },
      error
    });
  }
};
