export default {
  titTop: 'Smart Downloader',
  emphTop: '리소스 관리와 다운로드 관리를 한번에!',
  descTop:
    'Smart Downloader는 글로벌 네트워크 환경에서 컨텐츠를 빠르고 안정적으로 설치할 수 있도록 게임 리소스를 압축, 멀티스레드 형태로 제공하여 빠른 다운로드와 높은 성공률을 제공해드립니다',
  sectionDiagram: {
    imgAlt: 'SmartDownloader Diagram',
    txtService1: '파일 압축 및 메타파일 생성',
    txtService2: '다운로드 지표 수집',
    txtSubService: '(국가별 성공률, 다운로드 속도, 실패 사유 등)',
    txtService3: '높은 성공률! <br />빠른 다운로드!'
  },
  sectionService: [
    {
      titSection: '대용량 파일 관리도 거뜬히!',
      descSection:
        'Smart Downloader는 글로벌 네트워크 환경에서 컨텐츠를 빠르고 안정적으로 설치할 수 있도록 <br />게임 리소스를 압축, 멀티스레드 형태로 제공하여 빠른 다운로드와 높은 성공률을 제공해드립니다.'
    },
    {
      titSection: 'Smart Downloader Unity Tool',
      descSection:
        'Smart Downloader Unity Tool(SUT)은 Unity에서 리소스를 업로드하고 배포할 수 있는 툴입니다.<br />Unity 리소스 관리 및 업로드를 보다 쉽게 관리할 수 있도록 Tool 을 제공합니다.',
      titSubSection: '게임 리소스 배포를 쉽고 빠르게!'
    }
  ],
  // service1
  lstItem: [
    {
      pText: '느린 네트워크 환경에서도 빠른 게임 설치 및 업데이트가 가능합니다.'
    },
    {
      pText: '최초 설치 이후, 갱신된 리소스만 업데이트 가능합니다.'
    },
    {
      pText: '메타 파일 수정으로 업데이트를 제어 함으로써, 게임 코드 수정 없이 리소스 업데이트가 가능합니다.'
    },
    {
      pText:
        'Console에서 One-step으로 배포 파일을 CDN에 업로드 할 수 있어 편리하게 저렴한 비용의 CDN을 연동 할 수 있습니다.'
    },
    {
      pText:
        '실시간 다운로드 현황 지표를 통해 국가별 서비스 안정성을 직관적으로 파악하고, 설치&업데이트와 관련된 문제에 대해 즉시 대응할 수 있습니다.'
    }
  ], 
  linkEnd: 'SmartDownloader 제품 상담',
  icoArrowForwardW: '바로가기'
};
