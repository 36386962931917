import * as NEWSLETTER_REPOSITORIES from '@/store/repositories/newsLetter';

export default {
  // 단건 가져오기
  // eslint-disable-next-line no-unused-vars
  fetchNewsLetterOne: async ({ commit }, payload) => {
    const res = await NEWSLETTER_REPOSITORIES.fetchNewsLetterOne(payload);

    const { header } = res;
    if (header.isSuccessful) {
      return res.result.content;
    }

    return null;
  }
};
