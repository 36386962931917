import ExampleRecordModel from './models/ExampleRecordModel';

export default {
  // 대상을 덮어 씌움
  setExampleList(state, data) {
    state.todoList = data.map(item => {
      return new ExampleRecordModel({
        ...item
      });
    });
  },
  // 추가
  addExampleItem(state, data) {
    state.todoList.push(new ExampleRecordModel({ ...data }));
  },
  // 추가
  updateExampleItem(state, data) {
    const found = state.todoList.find(todo => todo.id === data.id);
    if (found) {
      Object.keys(data).forEach(key => {
        if (key !== 'id') {
          found[key] = data[key];
        }
      });
    }
  },
  // 삭제
  deleteExampleItem(state, id) {
    const index = state.todoList.findIndex(todo => todo.id === id);
    if (index > -1) {
      state.todoList.splice(index, 1);
    }
  },
  // 삭제
  deleteExampleList(state, ids) {
    state.todoList = state.todoList.filter(todo => {
      return ids.indexOf(todo.id) === -1;
    });
  }
};
