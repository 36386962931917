<template>
  <ul class="skip">
    <li><a href="#content" @click.prevent="handleSkip('#nav')">메뉴 바로가기</a></li>
    <li><a href="#content" @click.prevent="handleSkip('#content')">본문 바로가기</a></li>
  </ul>
</template>

<script>
export default {
  name: 'SkipNavigation',
  methods: {
    handleSkip(id) {
      const el = document.querySelector(id);
      if (el) {
        el.tabIndex = -1;
        el.focus();
      }
    }
  }
};
</script>
