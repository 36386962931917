export default {
  emphTop:
    '게임개발에 특화된 Gamebase의 <br class="view_pc" />주요기능, 운영과 컨텐츠 분석을 <br class="view_pc" />위한 다각적인 지표등을 체험 할 수 있습니다.',
  linkShortcut: '데모 프로젝트 바로가기 <span class="ico_game ico_shortcut"></span>',
  sectionServicetit: '데모 프로젝트 계정 신청',
  sectionServiceDesc:
    '이름, 이메일 주소, 회사명, ID를 남겨주시면 확인 후 Gamebase 데모 버전의 프로젝트 계정을 제공해 드립니다.',
  contactName: '이름',
  contactMail: '이메일',
  mailTxtAttention: '비밀번호 변경을 위한 메일을 전송합니다.',
  contactCompany: '회사명',
  agree1: '(필수) 만 14세 이상입니다.',
  agree2:
    '<span class="ico_game ico_check"></span>(필수) Gamebase 데모 계정 신청을 위해 이름, 이메일주소, 회사명, ID를 수집하며, <em class="emph_g">수집일로부터 30일간 보관됩니다.</em><br class="view_pc" /> 동의를 거부하실 수 있으나, 동의하지 않으실 경우 서비스 이용이 제한될 수 있습니다.',
  // done
  titDemoDone: 'Gamebase 데모 신청이<br />정상적으로 접수 되었습니다.',
  descDemoDone:
    '입력하신 이메일 (hong_gildong@nhn.com)에서<br />' +
    '비밀번호 변경 후 <em class="emph_primary">Console</em> 에 로그인이 가능합니다.',
  mailSandApiError: '메일 발송에 실패했습니다.',
  alreadyJoinError: '이미 가입된 ID입니다.'
};
