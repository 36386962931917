export default {
  home: 'ホーム',
  service: 'サービス',
  customerStories: '導入事例',
  partner: 'パートナー',
  download: 'ダウンロード',
  usage: {
    title: 'ドキュメント',
    toastProduct: 'NHN Cloud'
  },
  noticeNews: {
    title: 'お知らせ',
    notice: 'お知らせ',
    newsLetter: 'ニュースレター',
    press: 'プレスリリース',
    promotion: 'プロモーション'
  },
  console: 'Console'
};
