export default {
  tit_top: 'GameAnvil',
  emph_top: '쉬운 게임 서버 개발!',
  desc_top1: '게임 서버의 템플릿과 웹 운영 도구, 성능 테스트 툴을 제공하여',
  desc_top2: '게임 서버 개발과 서비스, 서버 운영이 가능합니다.',
  section_service1: {
    tit_section: 'GameAnvil의 특징',
    lst_gameanvil_char: [
      {
        emph_gameanvil_char: '쉬운 서버 개발',
        dsc_gameanvil_char1: '템플릿 제공 등을 통해',
        dsc_gameanvil_char2: '누구나 쉬운 서버 개발이 가능합니다.'
      },
      {
        emph_gameanvil_char: '성능 테스트 도구 지원',
        dsc_gameanvil_char1: '서버 기능 및 서버 성능 테스트가',
        dsc_gameanvil_char2: '가능한 도구를 지원합니다.'
      },
      {
        emph_gameanvil_char: '풍부한 운영 지원',
        dsc_gameanvil_char1: '웹 콘솔에서 배포, 운영, 모니터링을 지원합니다.'
      }
    ]
  },
  section_service2: {
    tit_section: '실시간 멀티 대전 게임 서버 개발을 쉽게, GameAnvil',
    desc_section:
      'GameAnvil은 초보 개발자도 쉽게 학습하여 바로 사용할 수 있을 정도로 간단하여 게임 서버 개발 기간을 대폭 단축할 수' +
      '있습니다. GameAnvil에서 제공되는 게임 서버의 템플릿과 웹 운영 도구, 성능 테스트 툴을 이용하여 어렵게 생각했던' +
      '게임 서버 개발과 서비스, 서버 운영까지 한 번에 해결하세요.',
    lst_item: [
      {
        txt_info: '제공되는 템플릿 적용만으로 게임 서버 개발 환경이 바로 준비됩니다.'
      },
      {
        txt_info: '간결한 순차적 코드로 원하는 기능을 쉽게 구현할 수 있습니다.'
      },
      {
        txt_info: '웹 운영 도구로 편리하게 게임 서버 구축, 배포, 운영까지 가능합니다.'
      },
      {
        txt_info: '웹 콘솔을 통한 운영 및 모니터링으로 신속한 이슈 파악과 대응이 가능합니다.'
      },
      {
        txt_info: '성능 테스트 툴을 이용하여 안정적인 서비스를 설계할 수 있습니다.'
      },
      {
        txt_info: '게임 유저 접속 상황에 따라 가용 서버를 자동으로 증설합니다.'
      },
      {
        txt_info: '개발중인 클라이언트에 쉽게 연동할 수 있습니다.'
      },
      {
        txt_info: 'DB, Redis 등의 저장소를 쉽게 사용할 수 있도록 API를 제공합니다.'
      }
    ]
  },
  section_service3: {
    tit_section: '다양한 장르의 게임 개발',
    lst_item: [
      {
        emph_info: '멀티 기반의 N:N 접속 게임',
        txt_info1: '다수의 유저가 동시 접속하여, 실시간으로 협력하거나 대결하는 컨텐츠를 쉽게 만들 수 있습니다.',
        txt_info2: 'GameAnvil을 이용하여 다양한 유저가 함께 하는 스릴 넘치는 게임을 제작해보세요!'
      },
      {
        emph_info: '턴제 및 보드 게임',
        txt_info1: 'GameAnvil에서 제공하는 로비 및 채널 개념을 활용하여 턴제 및 보드 게임 제작이 가능합니다.',
        txt_info2: '자유롭게 채널을 구성하고, 유사한 실력의 유저들끼리 매칭 할 수 있는 GameAnvil의 편리함을 누리세요!'
      },
      {
        emph_info: '싱글 유저 게임',
        txt_info1: '퍼즐을 비롯한 싱글 유저 게임도 GameAnvil과 함께하세요.',
        txt_info2: '싱글 유저 게임의 간편한 제작 뿐 아니라 운영 고민까지 한 번에 해결해드립니다.'
      }
    ]
  },
  section_service4: {
    tit_section: ' GameAnvil의 제공 기능 ',
    table: {
      caption: 'GameAnvil의 제공 기능 설명',
      thead_tr_th1: '기능',
      thead_tr_th2: '설명',
      tbody: {
        tr: [
          { td1: '서버 관리', td2: '웹 콘솔에서 서버 설정 및 서버 배포, 실행, 중지 등을 편리하게 할 수 있습니다.' },
          { td1: 'Audit', td2: '서버에서 발생한 이벤트 조회가 가능합니다.' },
          { td1: '채널 관리', td2: '사용자가 자유롭게 서버 채널을 구성할 수 있습니다.' },
          { td1: '매치메이킹', td2: '게임에서 정한 매칭 규칙에 따라, 유사한 실력의 유저들끼리 매칭시켜 줍니다.' },
          {
            td1: '클라이언트 세션 관리',
            td2: '게임 플레이 중 접속이 끊겨도, 원래의 세션으로 모두 완벽하게 자동 복구됩니다.'
          },
          { td1: '비동기 DB 쿼리 지원', td2: '쉽고 간단하게 비동기 DB 쿼리를 할 수 있습니다.' },
          { td1: 'Safe Pause', td2: '무정지 점검 운영이 가능합니다.' },
          { td1: '모니터링', td2: '서버의 상태, 유저 분포도 등을 실시간으로 한 눈에 확인할 수 있습니다.' }
        ]
      }
    }
  },
  section_service5: {
    tit_section: ' 게임서버 엔진 비교 ',
    table: {
      caption: '게임서버 엔진 타사와의 기능 비교',
      thead_tr_th1: '기능',
      thead_tr_th2: 'GameAnvil',
      thead_tr_th3: 'A사',
      thead_tr_th4: 'B사',
      tbody: {
        tr: [
          { td1: '지원 OS', td2: 'Windows, Linux, Mac', td3: 'Windows, Linux', td4: 'Windows' },
          { td1: '커넥션 관리', td2: '지원', td3: '지원불가능', td4: '일부지원' },
          { td1: '다양한 서버 타입 지원', td2: '지원', td3: '일부지원', td4: '지원' },
          { td1: '비동기화 지원', td2: '지원', td3: '지원불가능', td4: '지원' },
          { td1: '로비', td2: '지원', td3: '지원불가능', td4: '지원' },
          { td1: '채널', td2: '지원', td3: '지원불가능', td4: '지원불가능' },
          { td1: '성능 테스트 툴', td2: '지원', td3: '지원불가능', td4: '지원' }
        ]
      }
    }
  },
  section_end: {
    link_end: 'GameAnvil 제품 상담',
    spanText: '바로가기'
  }
};
