export default {
  titSection: 'Partners',
  descSection: 'We work with the partners with expertise to provide you with the best game platform service.',
  partnerInfo: {
    tit: 'Nettention - ProudNet',
    dsc:
      'Developed by Nettention, ProudNet has been implemented into 191 different game projects as of today.' +
      "\nMoreover, ProudNet's live servers are currently running in 13 different countries over the world, and it cooperates with NHN Gamebase for platform marketing and public relations."
  },
  groupContact: {
    ico_call: 'Contact',
    ico_mail: 'Email',
    ico_home: 'homepage'
  },
  groupLink: {
    link_contact: 'Home',
    white: 'AWS Proudnet'
  }
};
