export default {
  coverTitSection: {
    titSection: '下载游戏平台',
    descSection:
      '<b><a href="https://docs.toast.com/ko/Download/#game-gamebase" target="_blank">NHN Cloud > 用户指南 > Downloads</a></b> 也提供相同下载。',
    lstDotList: [
      '• 提供符合各平台的下载设置指南和最新的SDK、Sample App。',
      '• Unity可在Setting Tool下载并安装UnitySDK和NativeSDK。',
      '• Android 可通过 Gradle 设置将 SDK 添加到项目中（自动应用到最新版本）',
      '• iOS 可通过 CocoaPods 配置将 SDK 添加到项目中( 自动应用到最新版本)',
      '• 同时根据平台环境提供手动下载和 Sample App，可更方便地应用并提供Test。'
    ]
  },
  top: {
    service: [
      {
        title: 'Unity',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unity',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unity Setting Tool',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSettingTool.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.unity.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ]
      },
      {
        title: 'Unreal',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_unreal',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Unreal SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-Unreal.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(准备中)'
      },
      {
        title: 'Android',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_android',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'Gradle Setting Guide',
            href: 'https://docs.toast.com/ko/Game/Gamebase/ko/aos-started/#setting',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.android.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(准备中)'
      },
      {
        title: 'iOS',
        titleStrongClass: 'tit_download',
        titleSpanClass: 'ico_game ico_apple',
        ulClass: 'lst_download',
        downloadList: [
          {
            title: 'CocoaPods',
            href: 'https://docs.toast.com/ko/Game/Gamebase/ko/ios-started/#cocoapods-settings',
            hrefClass: 'link_download link_launch',
            titleSpanClass: 'ico_game ico_launch'
          },
          {
            title: 'iOS SDK',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/gamebase/GamebaseSDK-iOS.zip',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          },
          {
            title: 'Sample App',
            href: 'https://github.com/nhn/toast.gamebase.ios.sample/releases',
            hrefClass: 'link_download',
            titleSpanClass: 'ico_game ico_download'
          }
        ],
        spanText: '(准备中)'
      }
      // {
      //   title: 'Old versions of SDK',
      //   titleStrongClass: 'tit_download',
      //   titleSpanClass: '',
      //   ulClass: 'lst_download',
      //   downloadList: [
      //     {
      //       title: 'Old versions of SDK',
      //       href: 'https://docs.toast.com/ko/Download/#game-gamebase',
      //       hrefClass: 'link_download link_launch',
      //       titleSpanClass: 'ico_game ico_launch'
      //     }
      //   ]
      // }
    ]
  },
  bottom: {
    service: [
      {
        title: 'Smart Downloader',
        downloadList: [
          {
            title: 'Unity SDK',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/Smart-downloader-1.6.8.unitypackage'
          },
          {
            title: 'Jenkins Plugin',
            href: 'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/smartdl-uploader.hpi'
          },
          {
            title: 'Smart Downloader Unity Tool(SUT)',
            href:
              'https://static.toastoven.net/toastcloud/sdk_download/Smart%20Downloader/SUT/SmartDownloaderUnityTool.zip'
          }
        ]
      },
      {
        title: 'NHN Cloud',
        downloadList: [
          { title: 'PUSH', href: 'https://docs.toast.com/ko/Notification/Push/ko/Overview/' },
          {
            title: 'Log & Crash Search',
            href: 'https://docs.toast.com/ko/Analytics/Log%20&%20Crash%20Search/ko/Overview/'
          },
          { title: 'more NHN Cloud Services', href: 'https://docs.toast.com/ko/Download/#toast-sdk' }
        ]
      }
    ]
  }
};
