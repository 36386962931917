export default {
  titTop: 'NHN Cloud Service',
  emphTop: '함께 이용하면 좋을 NHN Cloud 서비스를 소개합니다.',
  sectionService: [
    {
      titSection: '강력한 모바일 보안, AppGuard',
      descSection:
        'AppGuard는 각종 어뷰징으로부터 안전하게 앱을 보호할 수 있는 모바일 앱 보안 솔루션입니다. <br> 단순한 치팅 툴 탐지 기능 뿐 아니라, 디컴파일 방지와 무결성 검증 등의 강력한 기능을 포함하고 있으며, 사용자 편의를 위한 간편한 적용 방법을 제공합니다.'
    },
    {
      titSection: '게임 로그와 크래시를 관리하고 분석하는, Log & Crash Search',
      descSection:
        'Log & Crash Search는 클라이언트와 서버의 로그를 수집하여 원하는 로그를 검색하고 조회하는 시스템입니다. <br> 모바일 앱에서 발생하는 크래시를 분석하고 통계 작업을 수행하여 크래시 발생 원인에 대한 다양한 정보도 제공합니다.'
    },
    {
      titSection: '고객 환경에 맞는 클라우드 인프라 구축, Instance',
      descSection:
        '대규모 서비스 경험과 오픈 소스 기술이 융합된 통합 클라우드 서비스입니다. <br> 고객 환경에 맞는 클라우드 환경을 제공해 드리며, 안정적인 글로벌 서비스를 지원합니다.'
    }
  ],
  lstAppguard: [
    {
      emText: '편리한 웹콘솔',
      spanText: '로그 분석 콘솔 제공<br>실시간 정책 설정 가능'
    },
    {
      emText: '강력한 보안',
      spanText: '디컴파일 방지, 코드 암호화<br>파일 무결성 검증'
    },
    {
      emText: '간편한 적용',
      spanText: '개발 도구에 통합하여 적용 가능<br>웹콘솔, CLI, Unity3D 에디터 확장'
    }
  ],
  appguardGuide: {
    titSubSection: 'AppGuard 사용 Flow',
    lstStep: [
      {
        emSpanText: 'Grade 1',
        lstStep2: [
          {
            strongText: '해킹 툴 탐지',
            lstStep3: ['치팅 및 매크로 툴 탐지/차단']
          }
        ]
      },
      {
        emSpanText: 'Grade 2',
        lstStep2: [
          {
            strongText: 'OS 위변조 방지',
            lstStep3: ['OS루팅 환경 탐지/차단', '에뮬레이터 환경 탐지/차단']
          },
          {
            strongText: '코드 위변조 방어',
            spanText: '(무결성 체크)',
            lstStep3: [
              '암호화 API (변조앱 탐지)',
              '서명 변조 탐지/차단',
              '매니페스트 변조 탐지/차단',
              '주요 모듈 및 자바 코드 (DEX) 변조 탐지/차단'
            ]
          },
          {
            strongText: '정적 분석 방어',
            lstStep3: ['자바 코드 (DEX) 암호화', '게임 실행 파일 암호화(cocos2d-x, Uniti mono, Unreal Engine)']
          }
        ]
      },
      {
        emSpanText: 'Grade 3',
        lstStep2: [
          { strongText: '자체 보호', lstStep3: ['AppGuard 내 중요 코드 보호(코드 난독화)'] },
          {
            strongText: '동적 분석 방어',
            lstStep3: ['Unitiy iL2CPP 코드 분석 방어', 'AppGuard 코드 가상화', '.so 동적 라이브러리 보호']
          },
          {
            strongText: '행위 기반 매크로 탐지',
            lstStep3: ['게임 내 터치 좌표 분석을 통한 기계 학습 기반 매크로 사용자 검출']
          }
        ]
      }
    ]
  },
  appguardFlow: {
    titSubSection: 'AppGuard 사용 Flow',
    txtAppguardFlow1: '탐지 로그 전송',
    txtAppguardFlow2: '탐지 로그 전송',
    txtAppguardFlow3: '탐지 로그 검색',
    descSection:
      '패턴에 의한 탐지와 휴리스틱 혹은 치팅 툴의 동작 방식에 의한 행위 기반 탐지가 복합적으로 동작하여 탐지 우회가 어렵습니다.',
    lstStep: [
      {
        emSpanText: '방 어',
        lstStep2: [
          {
            strongText: '리패키징 방어 <br class="noneview_m"> 코드 조작 방어',
            lstStep3: [
              '디컴파일 방지 지원',
              '게임 코드 암호화 (Unity 3D mono, Unity 3D il2cpp, Cocos2d-x) 및 Java 코드 암호화 (DEX) 지원 '
            ]
          }
        ]
      },
      {
        emSpanText: '탐 지',
        lstStep2: [
          {
            strongText: '패턴',
            lstStep3: ['치팅 툴, 매크로 툴, 스피드 조작 툴 등 탐지 (파일, 프로세스, 메모리 패턴)']
          },
          {
            strongText: '휴리스틱',
            lstStep3: ['에뮬레이터, 디버깅 등 탐지']
          },
          {
            strongText: '행위 기반',
            lstStep3: ['스피드 조작, 에뮬레이터, 디버깅 등 탐지']
          },
          {
            strongText: '파일 무결성',
            lstStep3: [
              '빌드 된 바이너리 파일의 무결성 검증 <br> (*.dex , *.so , *.dll , 리소스 파일에 대한 위변조 감지)'
            ]
          }
        ]
      },
      {
        emSpanText: '대 응',
        lstStep2: [
          {
            strongText: '제재',
            lstStep3: [
              '어뷰징 탐지 시 로그 전송 탐지 로그의 내용과 유저 아이디로 제재',
              '어뷰징 탐지 시 앱 실행 자동 차단 (클라이언트)'
            ]
          }
        ]
      }
    ]
  },
  skill: [
    {
      titSubSection: '주요기능',
      lstSkill: [
        {
          pText: '게임 서버에서 발생하는 대량의 로그로 인한 서비스 영향도를 제거할 수 있습니다.'
        },
        {
          pText: '배포된 모든 클라이언트에서 발생하는 오류를 한곳에 모아서 조회하고 분석할 수 있습니다.'
        },
        {
          pText: '대량의 로그에서 원하는 로그를 빠르게 검색할 수 있고, 패턴별로 로그를 조회할 수 있습니다.'
        },
        {
          pText: '로그와 크래시를 5분 단위로 모니터링할 수 있습니다.'
        },
        {
          pText: '서비스별 삭제, 다양한 로그 유형별로 조회/분석 기능을 제공합니다.'
        },
        {
          pText: '오류, 크래시 덤프, 웹 애플리케이션 로그, 사용자 정의 메시지 형식 등을 지원합니다.'
        },
        {
          pText:
            '프로젝트, 호스트, 로그 유형, 로그 수준별로 로그 개수를 볼 수 있으며, 로그 검색 페이지에서 시간대별 로그 추이를 차트로 확인할 수 있습니다.'
        },
        {
          pText: '로그 본문에서 쿼리에 일치하는 단어가 강조 표시되므로 쉽게 확인할 수 있습니다.'
        }
      ]
    },
    {
      titSubSection: '서비스 대상',
      lstSkill: [
        {
          spanClass: 'brand_target_log',
          pText: 'Apache, Tomcat 등의 웹 로그가 많이 쌓이는 경우'
        },
        {
          spanClass: 'brand_no_server',
          pText: '서버 로그를 서버에 접속하지 않고 봐야 하는 경우'
        },
        {
          spanClass: 'brand_target_collect',
          pText: '사용자에게 배포한 앱에서 발생한 로그를 한곳에서 취합하여 봐야 하는 경우'
        },
        {
          spanClass: 'brand_error_log',
          pText: '서비스에서 발생한 오류 로그의 추이를 확인해야 하는 경우'
        }
      ]
    }
  ],
  feature: {
    titSubSection: '특징',
    lstFeature: [
      {
        strongText: '유연한 인프라',
        pText: 'OpenStack 기반의 유연한 클라우드 인프라 제공'
      },
      {
        strongText: '다양한 플랫폼',
        pText: 'Notifications, Analytics, Security 등 유용한 기능들로 구성된 플랫폼 제공'
      },
      {
        strongText: '글로벌 지원',
        pText: '게임 플랫폼 및 글로벌 네트워크를 통한 지원'
      },
      {
        strongText: '하이브리드 클라우드',
        pText: '물리 인프라와 가상 인프라를 동시에 활용할 수 있는 환경 제공'
      },
      {
        strongText: '전문 인력',
        pText: '자사 서비스 운영 경험을 기반으로 안정적 기술 지원'
      },
      {
        strongText: '합리적 비용',
        pText: '사용한 만큼 비용을 지불, 초기 비용 부담 최소화, 타사 대비 10-30% 저렴'
      },
      {
        strongText: '최신형 클라우드 센터',
        pText: '고집적 도심형 클라우드 센터'
      }
    ]
  },
  platform: {
    titPlatform: 'Platform',
    lstPlatform: [
      { liText: 'Contents' },
      { liText: 'Analytics' },
      { liText: 'Game' },
      { liText: 'Security' },
      { liText: 'Notification' },
      { liText: 'Common' }
    ]
  },
  infra: {
    titInfra: 'Infrastructure',
    lstInfra: [
      { liText: 'instance' },
      { liText: 'Server Image (Glance)' },
      { liText: 'Load Balance' },
      { liText: 'Public IP' },
      { liText: 'Object Storage (Swift)' },
      { liText: 'Identity (Keystone)' },
      { liText: 'Technology & Monitoring (Cellometers)' },
      { liText: 'Dashboard (Horizon)' }
    ]
  },
  aTag: [
    { href: 'https://www.toast.com/kr/service/security/nhn-appguard', aText: 'AppGuard 서비스 바로가기' },
    {
      href: 'https://www.toast.com/kr/service/analytics/log-crash-search',
      aText: 'Log & Crash Search 서비스 바로가기'
    },
    { href: 'https://www.toast.com/kr/service/compute/instance', aText: 'Instance 서비스 바로가기' }
  ]
};
