export default {
  // 공통 메타 데이터
  baseMetaData: {
    keywords: 'NHN GamePlatform'
  },
  // 홈
  // <!-- 2020-12-29 타이틀 문구 수정 -->
  home: {
    title: 'NHN GamePlatform',
    description: 'NHNが提供するゲームプラットフォーム Gamebase, Leaderboard, Smart Downloader, Launching',
    keywords:
      'ゲーム,トーストゲーム,ゲームベース,トーストゲームベース,ゲームプラットフォーム,ゲームプラットフォームのログイン認証,ゲーム指標,ゲーム分析,ゲーム運営ツール,ゲームプッシュ,外部認証,アンドロイド認証,iOS認証,iOSのインアプリ決済,Androidのアップ決済,モバイルプラットフォーム,モバイルゲームプラットフォーム,モバイルゲームの開発。,NHNゲーム,NHNゲームベース,AWS ゲームベース'
  },
  // 서비스 카테고리
  service: {
    // Gamebase
    gamebase: {
      title: 'ゲームベース',
      description: 'NHNが提供するゲームプラットフォーム Gamebase, Leaderboard, Smart Downloader, Launching',
      keywords:
        'ゲーム,トーストゲーム,ゲームベース,トーストゲームベース,ゲームプラットフォーム,ゲームプラットフォームのログイン認証,ゲーム指標,ゲーム分析,ゲーム運営ツール,ゲームプッシュ,外部認証,アンドロイド認証,iOS認証,iOSのインアプリ決済,Androidのアップ決済,モバイルプラットフォーム,モバイルゲームプラットフォーム,モバイルゲームの開発。,NHNゲーム,NHNゲームベース,AWS ゲームベース'
    },
    gamebaseDemo: {
      main: {
        title: 'NHN GamePlatform',
        description: 'gamebaseDemo main',
        keywords: 'gamebaseDemo main'
      },
      done: {
        title: 'NHN GamePlatform',
        description: 'gamebaseDemo done',
        keywords: 'gamebaseDemo done'
      }
    },
    // Smart downloader
    smartDownloader: {
      title: 'スマートダウンロード',
      description: 'NHNが提供するゲームプラットフォーム Gamebase, Leaderboard, Smart Downloader, Launching',
      keywords:
        'スマートダウンロード,トーストスマートダウンロード,ゲームダウンロード,リソースダウンロード,マルチスレッドダウンロード,CDN,スマートダウンロードCDN,ダウンロード加速化,パッチ加速化,模倣リグローバル配布'
    },
    // Leader board
    leaderboard: {
      title: 'リーダーボード',
      description: 'NHNが提供するゲームプラットフォーム Gamebase, Leaderboard, Smart Downloader, Launching',
      keywords:
        'リーダーボード,トーストリーダーボード,ゲームランキング,ランキングサービス,ファクター,日刊ランキング,週間ランキング,全体ランキング,Redisランキング,Redisクラスター'
    },
    // GameAnvil
    gameAnvil: {
      title: 'NHN GamePlatform',
      description: 'gameAnvil 한국어 descritpion',
      keywords: 'gameAnvil 한국어 keywords'
    },
    // NHN Cloud
    nhnCloud: {
      title: 'NHN GamePlatform',
      description: 'nhnCloud 한국어 descritpion',
      keywords: 'nhnCloud 한국어 keywords'
    },
    // appGuard
    appGuard: {
      title: 'NHN GamePlatform',
      description: 'appGuard 한국어 descritpion',
      keywords: 'appGuard 한국어 keywords'
    },
    // logNCrash
    logNCrash: {
      title: 'NHN GamePlatform',
      description: 'logNCrash 한국어 descritpion',
      keywords: 'logNCrash 한국어 keywords'
    },
    // instance
    instance: {
      title: 'NHN GamePlatform',
      description: 'instance 한국어 descritpion',
      keywords: 'instance 한국어 keywords'
    },
    // Pricing
    pricing: {
      title: 'NHN GamePlatform',
      description: 'pricing 한국어 descritpion',
      keywords: 'pricing 한국어 keywords'
    }
  },
  // 파트너
  partner: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'partner 한국어 descritpion',
      keywords: 'partner 한국어 keywords'
    }
  },
  // 고객 사례 카테고리
  customerStories: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'customerStories 한국어 descritpion',
      keywords: 'customerStories 한국어 keywords'
    }
  },
  // 다운로드 카테고리
  download: {
    // 홈
    main: {
      title: 'NHN GamePlatform',
      description: 'download 한국어 descritpion',
      keywords: 'download 한국어 keywords'
    }
  },
  // 공지소식 카테고리
  noticeNews: {
    // 공지사항
    notice: {
      title: 'NHN GamePlatform',
      description: 'noticeNews 한국어 descritpion',
      keywords: 'noticeNews 한국어 keywords'
    },
    // 뉴스레터 모음
    newsLetter: {
      title: 'NHN GamePlatform',
      description: 'newsLetter 한국어 descritpion',
      keywords: 'newsLetter 한국어 keywords'
    },
    // 보도자료
    press: {
      title: 'NHN GamePlatform',
      description: 'press 한국어 descritpion',
      keywords: 'press 한국어 keywords'
    },
    // 프로모션
    promotion: {
      title: 'NHN GamePlatform',
      description: 'promotion 한국어 descritpion',
      keywords: 'promotion 한국어 keywords'
    }
  },
  contact: {
    main: {
      title: 'NHN GamePlatform',
      description: 'contect 한국어 descritpion',
      keywords: 'contact 한국어 keywords'
    },
    done: {
      title: 'NHN GamePlatform',
      description: 'contact done',
      keywords: 'contact done'
    }
  }
};
