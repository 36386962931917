import { changeLocale } from '@/helpers/i18n';
import NoticeRecordModel from './models/NoticeRecordModel';

export default {
  setCountryCode(state, langCode) {
    state.countryCode = langCode;
    changeLocale(langCode);
  },
  setViewMode(state, viewMode) {
    state.viewMode = viewMode;
  }, 
  setNoticeList(state, data) {
    state.noticeAll.push(...data.map(item => {
      return new NoticeRecordModel({
        ...item
      });
    }));
  }, 
  setNoticeOne(state, data) {
    state.noticeOne = new NoticeRecordModel(data);
  }
 };
