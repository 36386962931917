export default {
  titTop: 'Instance',
  emphTop: 'Build cloud infrastructure to suit the customers’ environment!',
  descTop: 'The integrated cloud service combines massive service experience and open source technology.',
  titSection: 'Characteristics',
  descSection:
    'Cloud environment is provided to suit the customer environment, supporting them with reliable global service.',
  feature: {
    lstFeature: [
      {
        strongText: 'Flexible infrastructure',
        pText: 'Flexible, OpenStack-based cloud infrastructure'
      },
      {
        strongText: 'Platform with variety',
        pText: 'Platform equipped with useful features including notification, analytics, and security'
      },
      {
        strongText: 'Global support',
        pText: 'Support through the game platform and global network'
      },
      {
        strongText: 'Hybrid cloud',
        pText: 'Environment to utilize both physical and virtual infrastructure simultaneously'
      },
      {
        strongText: 'Professional human resources',
        pText: 'Reliable tech support based on first-party service management'
      },
      {
        strongText: 'Reasonable prices',
        pText: 'Pay-per-use billing, minimized initial cost, 10-30% cheaper than other game platform services'
      },
      {
        strongText: 'Up-to-date cloud center',
        pText: 'High-density urban cloud center'
      }
    ]
  },
  platform: {
    titPlatform: 'Platform',
    lstPlatform: [
      { liText: 'Contents' },
      { liText: 'Analytics' },
      { liText: 'Game' },
      { liText: 'Security' },
      { liText: 'Notification' },
      { liText: 'Common' }
    ]
  },
  infra: {
    titInfra: 'Infrastructure',
    lstInfra: [
      { liText: 'instance' },
      { liText: 'Server Image (Glance)' },
      { liText: 'Load Balance' },
      { liText: 'Public IP' },
      { liText: 'Object Storage (Swift)' },
      { liText: 'Identity (Keystone)' },
      { liText: 'Technology & Monitoring (Cellometers)' },
      { liText: 'Dashboard (Horizon)' }
    ]
  },
  service2: {
    titSection: 'Credibility authenticated by international standards',
    descSection:
      'Since the official launch of Gamebase in 2016, the service has never been stopped, and it is a zero downtime service. <br>' +
      '(Excluding service failure caused by external IDP issues such as Facebook authentication failure, etc) <br>' +
      'The entire NHN Cloud service is certified by international security standards and strictly manages personal information, which enables game services to operate securely without risk of hacking.'
  },
  ul1LstSecurityCertify: [
    {
      emphG: 'ISO/IEC 27001 Certified',
      infoDsc: 'International standard certified for management system for protection of information'
    },
    {
      emphG: 'ISO/IEC 29100 Certified',
      infoDsc: 'International standard certified for personal information framework'
    },
    {
      emphG: 'ISO/IEC 27017/27018 Certified',
      infoDsc:
        'Protection of cloud service information/ personal information International standard certified for management system'
    }
  ],
  ul2LstSecurityCertify: [
    {
      emphG: 'PIMS Certified',
      infoDsc:
        'Protection of personal information to the highest level of Korea Publicly-acknowledged management system'
    },
    {
      emphG: 'ISMS Certified',
      infoDsc: 'Authentication for the maintenance of management system for continued protection of information'
    },
    {
      emphG: 'CSAP Certified / Cloud Service Security Certified',
      infoDsc:
        'Evaluation, Certification for the level of information protection to provide safe cloud service to public institutions '
    }
  ],
  aTag: [
    { href: 'https://docs.toast.com/en/Security/NHN%20AppGuard/en/Overview/', aText: 'Go to AppGuard Service' },
    {
      href: 'https://docs.toast.com/en/Analytics/Log%20&%20Crash%20Search/en/Overview/',
      aText: 'Go to Log & Crash Search Service'
    },
    { href: 'https://docs.toast.com/en/Compute/Instance/en/overview/', aText: 'Go to Instance Service' }
  ]
};
