import Cookies from 'js-cookie';

export const setCookie = (name, value, options) => {
  return Cookies.set(name, value, options);
};

export const removeCookie = (name, options) => {
  return Cookies.remove(name, options);
};

export const getCookie = name => {
  return Cookies.get(name);
};

export const getAllCookie = () => {
  return Cookies.get();
};

export const getJSON = name => {
  return Cookies.getJSON(name);
};
