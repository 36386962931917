export default {
  lstLegal: [
    { title: '회사 소개', href: 'https://www.nhn.com/ko/index.nhn' },
    { title: '이용 약관', href: 'https://www.toast.com/kr/terms/terms-service' },
    { title: '개인 정보 처리 방침', href: 'https://www.toast.com/kr/terms/privacy-policy' },
    { title: '제품 상담', href: '' }
  ],
  familySite: 'Family Site',
  more: 'more',
  familySiteSub: [
    { title: 'NHN Cloud', href: 'https://www.toast.com/kr' },
    { title: 'TOAST G', href: 'https://gov.toast.com/' },
    { title: 'TOAST CAM', href: 'http://cam.toast.com' },
    { title: 'TOAST CAM Biz', href: 'http://bizcam.toast.com' },
    { title: 'TOAST bill', href: 'https://bill.toast.com' },
    { title: 'Dooray!', href: 'http://dooray.com' },
    { title: 'PAYCO', href: 'http://www.payco.com' },
    { title: 'PAYCO 개발자센터', href: 'https://developers.payco.com' },
    { title: 'Hangame', href: 'http://www.hangame.com' },
    { title: 'NHN Corp.', href: 'https://www.nhn.com' }
  ],
  footerInfo: [
    '상호:엔에이치엔클라우드(주) 대표:백도민, 김동훈 주소:13487 경기도 성남시 분당구 대왕판교로645번길 16 NHN 플레이뮤지엄',
    '고객센터 : 1588-7967 이메일 : support@nhn.com 사업자등록번호 : 424-88-02352 통신판매업신고번호 : 2013-경기성남-1067호 ' +
      '<a href="https://www.ftc.go.kr/bizCommPop.do?key=232&apv_perm_no=2022378021930201507&pageUnit=10&searchCnd=wrkr_no&wrkr_no=4248802352&pageIndex=1" target="_blank" class=" link_info">사업자 정보확인</a>'
  ],
  txtCopyright: 'ⓒ NHN Cloud Corp. All rights reserved.'
};
