export default {
  titLicense: '소프트웨어 라이선스', 
  dscLicense: '본 라이선스는 엔에이치엔 주식회사(이하 “회사”)가 사용자에게 아래의 조건으로 소프트웨어의 사용을 허가하는 것입니다. 사용자가 본 소프트웨어를 사용한다는 것은 본 라이선스에 동의하는 것으로 간주됩니다. 만일 이 라이선스의 내용에 동의하지 않으면, 본 소프트웨어의 사용을 중지하고 삭제조치하여야 합니다.', 
  lstLicense: [
    { subtit : '정의', dsc : '본 소프트웨어는 컴퓨터 소프트웨어, 관련 매체, 온라인 또는 전자 문서를 포함합니다.<br /> 소프트웨어의 사용이란, 이 소프트웨어의 전부 또는 일부가 컴퓨터의 주기억장치 또는 그 외 기억장치에 저장되어 있는 것을 의미합니다.'}, 
    { subtit : '허가 및 사용조건', dsc : '회사는 사용자가 아래의 조건에 따라 본 소프트웨어를 사용할 것을 허가합니다.', 
      lstsubLicense: [
        { subLicense: '가. 저작권 표시', dscCircleLicense: '사용자는 본 소프트웨어 제품에 첨부된 저작권 표시를 없애거나 변경할 수 없습니다.'}, 
        { subLicense: '나. 본 소프트웨어 제품의 배포 금지', dscCircleLicense: '사용자는 본 소프트웨어 제품의 전부 또는 일부를 제3자에게 배포할 수 없습니다.'}, 
        { subLicense: '다. 본 소프트웨어 제품의 전부 또는 일부 변형 금지', dscCircleLicense: '사용자는 본 소프트웨어 제품의 전부 또는 일부를 변형할 수 없습니다.'}, 
        { subLicense: '라. 역공학, 역컴파일, 역어셈블 등의 금지', dscCircleLicense: '사용자는 본 소프트웨어 제품으로 역공학, 역컴파일, 또는 역어셈블 등 이와 유사한 행위를 할 수 없습니다.'}, 
      ]
    }, 
    { subtit : '저작권', dsc : '본 소프트웨어와 모든 부속물에 대한 소유권 및 일체의 지식재산권(저작권 및 2차적 저작물 작성권 포함)은 회사에 있습니다.'}, 
    { subtit : '책임 및 보증의 제한', dsc : '사용자가 본 소프트웨어를 선택, 도입, 사용하여 발생하였거나 사용한 결과로 발생한 모든 손해는 사용자가 부담하여야 하고, 회사에 손해가 발생하거나 발생할 우려가 있는 경우 사용자는 사용자의 비용으로 회사를 면책시켜야 합니다.<br/> 회사는 본 소프트웨어 제품 및 문서를 사용함으로써 또는 사용하지 못함으로써 발생하는 모든 손해(수입의 손실, 사업의 중단, 사업 정보의 상실 등 모든 유형의 손해를 포함한다)에 관하여 아무런 책임을 부담하지 않습니다. 이러한 손해의 가능성에 대해 미리 알렸다고 하더라도 회사는 책임을 부담하지 않습니다.'}, 
    { subtit : '인정', dsc : '사용자는 본 라이선스에 명시된 모든 내용을 읽고 이해하였으며, 라이선스에 동의하고, 나아가 이 모든 내용이 본 소프트웨어의 이용에 우선하는 것임을 인정하는데 동의합니다.'}, 
    { subtit : '법률 및 라이선스의 준수', dsc : '사용자는 본 소프트웨어 제품의 사용과 관련하여 적용되는 법률과 본 라이선스를 준수하여야 하며, 법률 또는 본 라이선스를 위반하였을 경우, 일체의 민•형사상 책임을 부담합니다. '}, 
    { subtit : '문의', dsc : '소프트웨어의 이용과 관련하여 문의가 있을 경우, 엔에이치엔 주식회사에 문의바랍니다.'}, 
  ], 
};
