export default {
  titMain: '게임 회사가 만든 진짜 게임 플랫폼',
  emphMain1: '엔에이치엔',
  emphMain2: '게임베이스',
  utilBtn: {
    lightmode: 'Light Mode',
    darkmode: 'Dark Mode'
  },
  lstCategory: [
    {
      strongText: 'Gamebase',
      pText: '로그인, 결제, 푸시, 공지, 점검 등 공통 기능을 통합SDK로 제공하는 NO.1게임플랫폼',
      spanText: '바로가기'
    },
    {
      strongText: 'Smart Downloader',
      pText: '실행파일, 이미지 등 게임 리소스의 빠른 다운로드와 압도적인 성공률 제공',
      spanText: '바로가기'
    },
    {
      strongText: 'Leaderboard',
      pText: '대규모 랭킹 트래픽에도 안정적으로 운영되는 실시간 랭킹 서비스',
      spanText: '바로가기'
    },
    {
      strongText: 'GameAnvil',
      pText: '멀티 대전 게임 서버의 개발 및 서버 운영까지 가능한 통합 솔루션',
      spanText: '바로가기'
    }
  ],
  titNotice: 'Notice',
  titNews: 'News'
};
