export default {
  requiredNamePhone: '名前、電話番号、メールは必須項目です。',
  requiredAgree: '個人情報の収集および利用に同意していただいてからお問い合わせください。',
  contactTitle: 'お問い合わせタイプ変更',
  contactContent: '作成したお問い合わせ内容を維持しますか？',
  cancle: 'お問い合わせをキャンセルしますか？',
  regexError: '必須項目の内容が正しくありません。 もう一度確認をお願いします。',
  demoRequiredInput: '名前、ID、メール会社名は必須項目です。',
  demoRequiredAgree:
    '個人情報収集および活用に同意するとデモプロジェクトアカウント申請入力が可能です。 同意後に入力してください。',
  demoCancleTitle: 'デモプロジェクトアカウント申請キャンセル',
  demoCancleContent: 'デモプロジェクトアカウント申請をキャンセルしますか？'
};
