export default {
  titMain: 'ゲーム開発に集中できる最高のプラットフォーム',
  emphMain1: 'NHN',
  emphMain2: 'Gamebase',
  utilBtn: {
    lightmode: 'Light Mode',
    darkmode: 'Dark Mode'
  },
  lstCategory: [
    {
      strongText: 'Gamebase',
      pText:
        'ログイン、アプリ内決済、プッシュ、お知らせ、メンテナンス処理など共通機能を統合SDKで提供するNO.1ゲームプラットフォーム',
      spanText: 'リンク'
    },
    {
      strongText: 'Smart Downloader',
      pText: '高い成功率を誇るゲームリソースの高速ダウンロードサービス',
      spanText: 'リンク'
    },
    {
      strongText: 'Leaderboard',
      pText: '大規模トラフィックでも安定稼働するリアルタイムランキングサービス',
      spanText: 'リンク'
    },
    {
      strongText: 'GameAnvil',
      pText: '멀티 대전 게임 서버의 개발 및 서버 운영까지 가능한 통합 솔루션',
      spanText: 'リンク'
    }
  ],
  titNotice: 'Notice',
  titNews: 'News'
};
