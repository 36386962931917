export default {
  titTop: 'Smart Downloader',
  emphTop: '资源管理和下载管理一次性完成！',
  descTop:
    'Smart Downloader为在全球网络环境下快速、稳定地安装内容，压缩游戏资源，以多线程形态提供快速下载和高成功率。',
  sectionDiagram: {
    imgAlt: 'SmartDownloader Diagram',
    txtService1: '压缩文件，并生成Meta文件',
    txtService2: '收集下载指标',
    txtSubService: '（不同国家的成功率、下载速度、失败原因等）',
    txtService3: '成功率高！ <br />快速下载！'
  },
  sectionService: [
    {
      titSection: '轻松管理大文件！',
      descSection:
        'Smart Downloader为在全球网络环境下快速、稳定地安装内容，<br />压缩游戏资源，以多线程形态提供快速下载和高成功率。'
    },
    {
      titSection: 'Smart Downloader Unity Tool',
      descSection:
        'Smart Downloader Unity Tool(SUT)是可从Unity上传并分发资源的工具。提供更加方便管理<br/>Unity资源及上传的工具。',
      titSubSection: '简单快速地分发游戏资源！'
    }
  ],
  // service1
  lstItem: [
    {
      pText: '在网速慢的环境下也能快速安装和更新游戏 。'
    },
    {
      pText: '首次安装后，仅升级更新的资源。'
    },
    {
      pText: '通过修改meta-file控制更新，无需修改游戏代码即可更新资源 。'
    },
    {
      pText:
        '在控制台可一步将文件上传至CDN，便于联动价格低廉的CDN。'
    },
    {
      pText:
        '通过实时下载现状指标，可直观地掌握各国服务稳定性，并及时应对安装&更新相关问题。'
    }
  ], 
  linkEnd: 'Smart Downloader产品咨询',
  icoArrowForwardW: '快捷方式'
};