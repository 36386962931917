export default {
  sectionTop: {
    titTop: 'Pricing',
    emphTop: '実用的なサービス構成と合理的なサービスを提供しています。'
  },
  sectionService1: {
    titSection: 'Gamebase料金',
    coverPrice: {
      descSection:
        'Gamebaseは最高レベルのゲームプラットフォームの全機能を、料金プランの区別なく提供しています。<br>' +
        '各種指標·ゲーム内通知·カスタマーセンター·クーポン·不正決済対策·ランキングなど、ゲーム運営に欠かせない機能を思う存分ご利用ください。<br>' +
        '累計DAU30,000以下のゲームでは6ヶ月間の無料体験ができます。お得に試してみてください！'
    },
    tblPrice: {
      caption: 'DAU累積によるGamebase料金'
    },
    thead: {
      tr: {
        th: ['課金区分', '課金区間', '課金基準', '料金']
      }
    },
    tbody: {
      tr1: {
        th: ['DAU<br>(daily active user) 累積','0～200,000以下', '月定額課金', '1,000,000KRW/月']
      },
      tr2: {
        th: ['200,000超過', 'DAU毎', '5 KRW']
      },
    },
    lstDot: {
      li: [
        '• 料金2023年9月1日以降にプロジェクトを新規作成してGamebaseを利用するお客様は新規料金プランでのみサービス利用が可能です。',
        '• 2023年9月1日以降にプロジェクトを新規作成してGamebaseを利用するお客様に限り、累積DAU 30,000以下は6か月間無料でご利用いただけます。'
      ]
    },
    lstDot2: {
      li: [
        '- 新規作成したプロジェクトの月間累積DAUが30,000を超えると、すぐに無料利用対象から除外され、その月から課金されます。',
        ' （DAUが再び30,000以下になった場合も無料利用対象から除外されます）',
        '- 無料利用期間は、新規プロジェクト作成日が含まれる月から6ヶ月間適用されます。',
        '- 例) 2023年9月12日に新規で有効にしたプロジェクトは、2024年2月まで無料利用が可能です。もし2023年11月に月累積DAUが30,000を超えた場合、無料利用は終了し、2023年11月から課金されます。'
      ]
    },
    lstDot3: {
      li: [
        '• 6か月無料利用期間中のサービス利用現況はコンソールGamebaseサービスページで確認できます。',
        '• 課金基準は利用月のDAUを合算した値です(参考：DAU平均=DAU累積/30日)。',
        '• 料金料金Gamebaseサービス料金にはNHN AppGuardサービスを除くPush、IAP、Leaderboardサービスの利用料金が含まれています。',
        '• ゲームリリースまでの技術サポートと技術教育が無料で提供されますので、相談をご希望の場合は、gamplatform@nhn.comまでお問い合わせください。'
      ]
    },
    tblPrice2: {
      caption: '機能に応じた提供範囲と料金',
      thead: {
        tr: {
          th: ['サポート区分', '機能', '提供範囲', '料金']
        }
      },
      tbody: {
        tr1: {
          td: ['Gamebase提供機能表', '(全て提供)', 'Push(グローバルを含む)', '無制限', '無料']
        },
        tr2: {
          td: ['アプリ内決済・レシート検証', '無制限', '無料']
        },
        tr3: {
          td: ['ランキング', '無制限', '無料']
        },
        tr4: {
          td: ['SNS認証', '無制限', '無料']
        },
        tr5: {
          td: ['クーポン発行', '無制限', '無料']
        },
        tr6: {
          td: ['顧客サービス(FAQ,告知,メール)', '無制限', '無料']
        },
        tr7: {
          td: ['KPI・分析', '無制限', '無料']
        },
        tr8: {
          td: ['ローンチ管理', '無制限', '無料']
        },
        tr9: {
          td: ['技術サポート', 'Gamebase連携のための技術サポートおよびコンサルティング', '無制限', '無料']
        }
      }
    }
  },
  coverSimulator: {
    titSubSection: '料金計算機:予想料金をあらかじめシミュレーションできます。',
    groupSimulator1: {
      titSimulator: '予想DAU（月累積）',
      txtTf: 'DAU',
      btnSearch: '検索',
      btnRefresh: '初期化'
    },
    groupSimulator2: {
      titSimulator: '予想料金 (月)',
      txtTf: 'KRW',
      txtVat: '税別'
    },
    lstDot: {
      li: [
        '• 料金2023年9月1日以降にプロジェクトを新規作成してGamebaseを利用するお客様は新規料金プランでのみサービス利用が可能です。',
        '• 2023年9月1日以降にプロジェクトを新規作成してGamebaseを利用するお客様に限り、累積DAU 30,000以下は6か月間無料でご利用いただけます。'
      ]
    },
    lstDot2: {
      li: [
        '- 新規作成したプロジェクトの月間累積DAUが30,000を超えると、すぐに無料利用対象から除外され、その月から課金されます。',
        ' （DAUが再び30,000以下になった場合も無料利用対象から除外されます）',
        '- 無料利用期間は、新規プロジェクト作成日が含まれる月から6ヶ月間適用されます。',
        '- 例) 2023年9月12日に新規で有効にしたプロジェクトは、2024年2月まで無料利用が可能です。もし2023年11月に月累積DAUが30,000を超えた場合、無料利用は終了し、2023年11月から課金されます。'
      ]
    },
    lstDot3: {
      li: [
        '• 6か月無料利用期間中のサービス利用現況はコンソールGamebaseサービスページで確認できます。',
        '• 課金基準は利用月のDAUを合算した値です(参考：DAU平均=DAU累積/30日)。',
        '• 料金料金Gamebaseサービス料金にはNHN AppGuardサービスを除くPush、IAP、Leaderboardサービスの利用料金が含まれています。',
        '• ゲームリリースまでの技術サポートと技術教育が無料で提供されますので、相談をご希望の場合は、gamplatform@nhn.comまでお問い合わせください。'
      ]
    },
  },
  coverSla: {
    titSubSection: 'SLA : Service Level Agreement',
    descSection:
      'Gamebaseサービスにおいて、当社に責ある事由によりGamebaseの認証機能を利用できない場合の、損害賠償基準は' +
      '下記の表のとおりです。',
    tblSla: {
      caption: 'Gamebaseサービスの損害賠償基準',
      thead: {
        tr: {
          th: ['月間可用性', '損害賠償金']
        }
      },
      tbody: {
        tr1: {
          th: ['99%以上99.9%未満', '3ヶ月月平均使用金額の10%に該当する金額']
        },
        tr2: {
          th: ['95%以上99%未満', '3ヶ月月平均使用金額の25%に該当する金額']
        },
        tr3: {
          th: ['95.0%未満', '3か月月平均使用額の50%に該当する額']
        }
      }
    },
    lstDot: {
      li: [
        '※ サービスレベルに対する測定時間に以下の場合は該当しません。',
        '• サービス利用者とサービス提供者が事前に協議したGamebaseの稼動中断時間',
        '• システムソフトウェアアップデート、Gamebase変更作業など、計画的に実施されるGamebaseメンテナンスのために必要な時間',
        '• サービス提供者がコントロールしえない状況下で発生した障害時間',
        '• サービス利用者が、サービス提供者に事前に情報システム変更等の計画を通知しなかったため発生した障害時間',
        '• OAuth認証を提供する外部サービス障害等、サービス提供者の所管業務および役割の範囲外で発生した障害時間',
        '• 障害の所管領域がサービス提供者なのかサービス利用者なのか不明な障害時間',
        '• 自然災害およびこれに準ずる国家非常事態による障害時間等'
      ]
    }
  },
  sectionService2: {
    titSection: 'Leaderboard料金',
    thead: {
      tr: {
        th: ['課金区分', '課金区間', '課金基準', '料金']
      }
    },
    tbody: {
      tr1: {
        td: ['API呼び出し', '100,000呼び出し以下', '無料提供', '0円']
      },
      tr2: {
        td: ['10,000,000呼出以下', '1呼出当', '0.01円']
      },
      tr3: {
        td: ['10,000,000呼出超過', '1呼出当たり', '0.001円']
      }
    },
    lstDot: {
      li: [
        '• Gamebaseを有効にした場合、利用料金は無料となります。',
        '• Leaderdboardのみを単独で利用する場合、上記の金額が別途かかります。',
        '• API呼び出し数は月別累積使用量を基準に利用料金を算定します。'
      ]
    }
  }
};
